import OPERATIONES_ROUTES from '../../componentConfiguration/componentRutas/tools/op_routes'
import OPERATIONS_WAREHOUSE from '../../componentConfiguration/componentWarehouse/tools/op_warehouse'
import SupportCaseModal from '../../componentsSupports/createCase/CreateCase'
import CREATE_UPDATE_DETAIL from '../../components_Expenses/expenses/detail_expense/tools/create_update_detail'
import CREATE_UPDATE_EXPENSE from '../../components_Expenses/expenses/tools/create_expense'
import CREATE_DETAIL_POLICY from '../../components_Expenses/policy/detail/create_detail_policy'

export const SWITCH_OP = (props) => {
    const renderComponent = (props) => {
            switch (props?.create_modul){
                case "routes":
                    return (
                    <OPERATIONES_ROUTES
                        operation={"CREATE"}
                        handleSubmit={props?.handleSubmit} 
                        setmodalT={props?.setmodalT} 
                        alertas={props?.alertas}
                    />);
                case "warehouse":
                    return (
                    <OPERATIONS_WAREHOUSE
                        operation={"CREATE"}
                        handleSubmit={props?.handleSubmit} 
                        setmodalT={props?.setmodalT} 
                        alertas={props?.alertas} 
                    />); 
                case "help_center":
                    return(
                        <SupportCaseModal
                            isOpen={props?.opencreate}
                            onClose={props?.onClose}
                            alertas={props?.alertas}
                        />
                    );
                case "create_expense":
                    return(
                      <CREATE_UPDATE_EXPENSE
                        codCocept={""}
                        codDescripcion={""}
                        codDate={""}
                        codImporte={0}
                        codAccount={"9090909090909"}
                        codIdPayment={""}
                        id_expense={""}
                        codidConcept={""}
                        method={"CREATE"}
                        foreing={props?.dataextra?.foreing}
                        origin={props?.dataextra?.origin}
                        handleSubmit={props?.handleSubmit}
                      />
                    );
                case "create_expense_details":
                    return(
                        <CREATE_UPDATE_DETAIL
                            method={"CREATE"}
                            row={props?.dataextra?.dataprincipal?.original}
                            foreign={props?.dataextra?.foreign}
                            codeffect={"CARGO"}
                            codamount={0}
                            handleSubmit={props?.handleSubmit}
                        />
                    );
                case "policy_details":
                    return(
                        <CREATE_DETAIL_POLICY
                            id_policy={props?.dataextra?.id}
                            handleSubmit={props?.handleSubmit}
                            setmodalT={props?.setmodalT}
                            alertas={props?.alertas}
                        ></CREATE_DETAIL_POLICY>
                    );
                
                default:
                    return (<></>);
            }
    }


  return (
    <div className='' style={{"textAlign": "center"}}>
         {renderComponent(props)}
    </div>
    )
}
