//PAQUETERIAS
import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useDialog } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
//COMPONENTES
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { create_debts } from "../../../services/banck/banck"; 
import method_payment from "../../../dataComponets/metodo_pago.json";

//IMAGENES

//ESTILOS
import "./debts.css";

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const CREATE_UPDATE = (props) => {
  const [ticket, setticket] = useState(props.ticket);
  const [totalsale, settotalsale] = useState(props.totalsale);
  const [pointofsale, setpointofsale] = useState(props.pointofsale);
  const [bp, setbp] = useState(props.bp);
  const [validate_max_payment, setvalidate_max_payment] = useState(true);
  const dialog = useDialog();
  const [modalT, setmodalT] = useState(false);
  const notificationSystemRef = useRef();
  const [value, setValue] = useState(0);
  const [detail_product, setdetail_product] = useState("");
  const [detailSale, setdetailSale] = useState("none");
  const [fiscal, setfiscal] = useState("none");
  const [product, setproduct] = useState("none");
  const [noSale, setnoSale] = useState("none");
  const [max_payment, setmax_payment] = useState(props.max_payment);
  const [payment, setpayment] = useState("");
  const [four_digits, setfour_digits] = useState("");
  const [note, setnote] = useState("");
  const [method_payment_data, setmethod_payment_data] = useState("01");

  const handle_detail_product = (props) => {
    setdetail_product("");
    setdetailSale("none");
    setfiscal("none");
    setproduct("none");
    setnoSale("none");
  };

  useEffect(() => {
    setdetail_product(detail_product);
    setdetailSale(detailSale);
    setfiscal(fiscal);
    setproduct(product);
    setnoSale(noSale);
  }, [setdetail_product, setdetailSale, setfiscal, setproduct, setnoSale]);

 
  var dta = {
    note: note,
    payment_method: method_payment_data,
    total: Number(payment),
    uuid: props.uuid,
    last_four_digits:four_digits===""?null:four_digits,
  };
  console.log(dta)

  const handle_create_product = async() => {
    var dta = {
      note: note,
      payment_method: method_payment_data,
      total:Number(payment),
      uuid: props.uuid,
      last_four_digits:four_digits===""?null:four_digits,
    };
    console.log(dta)
      if(payment<=props.max_payment){
        setmodalT(true);

        try {
          await create_debts(props.id,dta);
          setmodalT(false)        
          props.handleSubmit();
          dialog.close({validate:true,msj:"Pago creado correctamente"})
      } catch (err) { 
        dialog.close({validate:false,msj:JSON.stringify(err.response?.data)})
        setmodalT(false)
      }
      }else{
        setvalidate_max_payment(false)
      }
     
   
  };

  return (
    <div className="">
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      <BottomNavigation
        className=""
        showlabel
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <div>
          <BottomNavigationAction
            className="btonwith"
            showlabel
            label="Datos generales"
            icon={<DriveFileRenameOutlineIcon onClick={handle_detail_product} />}
            onClick={handle_detail_product}
          />
        </div>

       
      </BottomNavigation>

      <div style={{ display: detail_product }}>
        <div className="containerDebts">
          <div className="bp-1">
            <ValidationTextField
              label="Ticket"
              required
              placeholder="Ticket"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              id="validation-outlined-input"
              name="ticket"
              value={ticket}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="bp-2">
            <ValidationTextField
              label="Total de venta"
              required
              placeholder="Nombre del producto"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              value={totalsale}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="bp-3">
            <ValidationTextField
              label="Punto de venta"
              required
              placeholder="Descripción"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              value={pointofsale}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="bp-4">
            <ValidationTextField
              label="Cliente"
              placeholder="Cliente"
              variant="outlined"
              sx={{ m: 2, width: "100%" }}
              defaultValue="Success"
              name="capital_regime"
              value={bp}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>


          <div className="bp-5">
          <ValidationTextField
              label="Máximo a pagar"
              required
              placeholder="Máximo a pagar"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              value={max_payment}
              InputLabelProps={{
                shrink: true,
              }}
            />
           
          </div>

          <div className="bp-6">
            <TextField
              id="Metododepago"
              select
              label="Método de pago"
              value={method_payment_data}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              onChange={(e)=>setmethod_payment_data((e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {method_payment.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-12">
          <ValidationTextField
            label="Pago"
            required
            placeholder="Pago"
            sx={{ m: 2, width: "100%" }}
            variant="outlined"
            defaultValue="Success"
            value={payment}
            type="text"
            onChange={(e) => {
              const inputValue = e.target.value;
              const regex = /^\d+(\.\d{0,2})?$/;
              if (inputValue === '' || regex.test(inputValue)) {
                setpayment(inputValue);
              }
            }}
            onBlur={(e) =>
              Number(e.target.value) > Number(max_payment)
                ? setvalidate_max_payment(false)
                : setvalidate_max_payment(true)
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <p className="validate_data">
            {validate_max_payment === false ? "El monto ingresado es mayor a la deuda" : ""}
          </p>
          </div>

          <div className="bp-7">
          <ValidationTextField
              label="4 digitos"
              required
              placeholder="4 digitos"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              inputProps={{ maxLength: 4 }}
              defaultValue="Success"
              value={four_digits}
              type="number"
              onChange={(e) => {setfour_digits(e.target.value)
              }}
              onBlur={(e) =>
                e.target.value?.length === 4
                  ? setfour_digits(e.target.value)
                  : setfour_digits("")
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
           
          </div>

          <div className="bp-8">
          <ValidationTextField
              label="Nota"
              required
              placeholder="Nota"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              value={note}
              onChange={(e) => {
                const value = e.target.value;
                if (typeof value === "string") {
                  setnote(value.toUpperCase());
                } else {
                  setnote(value);
                }
              }}
              
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            
          </div>
          <div className="bp-9">
           
          </div>
          <div className="bp-10">
            <Button
              onClick={handle_create_product}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              Crear
            </Button>
          </div>

          <div className="bp-11">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>      
    </div>
  );
};
export default CREATE_UPDATE;
