import { useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {  useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { put_details_promotions } from "../../../services/businesspartners/businesspartners";

function EditDetails(props) {

const [cant, setcant] = useState(props.row.original.quantity);
const [prec, setprec] = useState(props.row.original.promo_price);
const dialog = useDialog();
const [bandera, setbandera]=useState(true);

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
} 

const uppromotion = async ()=>{

props.reconsultar(true);
setbandera(false);
  var data = {
    enable: props.row.original.enable,
    promoPrice: prec,		
    productName: props.row.original.product_name,
    productCode: props.row.original.product_code,
    quantity: cant 
  }
 
try {
  var rf_token = await refresh_token();
  if(rf_token === true){
    setTimeout(async() => {
      try{
        const dt = await put_details_promotions(props.row.original.id_promotios_fk, props.row.original.id, data);
        console.log(dt);
        props.alertas("Detalle de promoción actualizado con éxito", true);
        props.reconsultar(false);
        props.detallespromotions();
        dialog.close();  
      }catch(err){
        console.log("error post", err);
        catchErrorModul("Promociones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
        props.alertas("No se pudo actualizar los detalles de la promoción, vuelva a intentarlo", false);
        props.reconsultar(false);
        setbandera(true);
      }    
    }, 1000);
  }else{}
} catch (err) {}
}

  return (
    <div>
      <div className="subtablepromotions">
          <Box>
          <TextField
              label="Código"
              value={props.row.original.product_code}
              sx={{ m: 1.5, width: "25%"}}
              disabled
          ></TextField>
              <TextField
              label="Nombre"
              value={props.row.original.product_name}
              sx={{ m: 1.5, width: "60%"}}
              disabled
            ></TextField>
             <TextField
              label="Precio"
              type={"number"}
              value={prec}
              disabled={!bandera}
              sx={{ m: 1.5, width: "40%"}}
              onChange={(event) => setprec(event.target.value)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
             <TextField
              label="Cantidad"
              type={"number"}
              disabled={!bandera}
              value={cant}
              sx={{ m: 1.5, width: "45%"}}
              onChange={(event) => setcant(event.target.value)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          <br></br>
          <Box>
           {
            bandera===true?
            <Button
              style={{"backgroundColor":"green"}}
              variant="contained"
              sx={{ m: 1.5, width: "30ch" }}
              onClick={uppromotion}
            >
              Actualizar Detalles
            </Button>:
            <Button
            style={{"backgroundColor":"green", "height":"35px"}}
            variant="contained"
            disabled
            sx={{ m: 1.5, width: "30ch" }}
          ><Spinner/></Button>        
          } 
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
              cancelar
            </Button>
          </Box>
          <br/>
      </div>
    </div>
  );
}

export default EditDetails;
