import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import estados from "../../../dataComponets/state.json";
import { get_employess_address, put_employess } from "../../../services/humanResources/humanResources";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";

function EditEmployees(props) {
 

useEffect(()=>{
  handleaddress();
},[]);

const dialog = useDialog();

console.log(props.row.original);

const [bandera, setbandera]=useState(true);
const [igualesphone, setigualesphone]=useState(false);
const [igualesmail, setigualesmail]=useState(false);

const [name, setname]=useState(props.row.original.name);
const [first_last_name, setfirst_last_name]=useState(props.row.original.first_last_name);
const [second_last_name, setsecond_last_name]=useState(props.row.original.second_last_name);
const [birth_date, setbirth_date]=useState(props.row.original.birth_date);
const [company_cell_phone_1, setcompany_cell_phone_1]=useState(props.row.original.company_cell_phone_1);
const [rfc, setrfc]=useState(props.row.original.rfc);
const [nss, setnss]=useState(props.row.original.nss);
const [curp, setcurp]=useState(props.row.original.curp);
const [hire_date, sethire_date]=useState(props.row.original.hire_date);
const [job_title, setjob_title]=useState(props.row.original.job_title);
const [marital_status, setmarital_status]=useState(props.row.original.marital_status);
const [scholarship, setscholarship]=useState(props.row.original.scholarship);
const [profession, setprofession]=useState(props.row.original.profession);
const [company_cell_phone_2, setcompany_cell_phone_2]= useState(props.row.original.company_cell_phone_2);
const [personal_cell_phone_1, setpersonal_cell_phone_1]=useState(props.row.original.personal_cell_phone_1);
const [personal_home_phone, setpersonal_home_phone]=useState(props.row.original.personal_home_phone);
const [company_email, setcompany_email]=useState(props.row.original.company_email);
const [company_cell_phone_email, setcompany_cell_phone_email]=useState(props.row.original.company_cell_phone_email);
const [personal_email, setpersonal_email]=useState(props.row.original.personal_email);
const [bank_account, setbank_account]=useState(props.row.original.bank_account);


const [vname, setvname]=useState(false);
const [vfname, setvfname]=useState(false);
const [vsname, setvsname]=useState(false);
const [vbday, setvbday]=useState(false);
const [vcompanyphone1, setvcompanyphone1]=useState(false);
const [vrfc, setvrfc]=useState(false);
const [vnss, setvnss]=useState(false);
const [vcurp, setvcurp]=useState(false);
const [vhire_date, setvhiredate]=useState(false);
const [vjob, setvjob]=useState(false);
const [vmarital, setvmarital]=useState(false);
const [vshool, setvschool]=useState(false);
const [vprof, setvprof]=useState(false);
const [vpersonphone, setvpersonphone]=useState(false);
const [vcompanymail, setvcompanymail]=useState(false);
const [vpersonmail, setvpersonmail]=useState(false);


//datos de direccion
const [street, setstreet] = useState(null);
const [outdoor_number, setoutdoor_number]=useState(null);
const [inside_number, setinside_number]=useState(null);
const [neighborhood, setneighborhood]=useState(null);
const [zip_code, setzip_code]=useState(null);
const [city, setcity]=useState(null);
const [town, settown]=useState(null);
const [state, setstate]=useState(null);
const [country, setcountry]=useState(null);
const [reference, setreference]=useState(null);
const [subsidiary, setsubsidiary]=useState(null);
const [address_type, setaddress_type]=useState(null);


const [vstreet, setvstreet]=useState(false);
const [voutdoor, setvoutdoor]=useState(false);
const [vneigh, setvneigh]=useState(false);
const [vzip, setvzip]=useState(false);
const [vcity, setvcity]=useState(false);
const [vtown, setvtown]=useState(false);
const [vstate, setvstate]=useState(false);
const [vcountry, setvcountry]=useState(false);

const handleaddress = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async () => {
        try{
          var d = await get_employess_address(props.row.original.id);
          setstreet(d === null? [] : d.data.address.street);
          setoutdoor_number(d === null? [] : d.data.address.outdoor_number);
          setinside_number(d === null? [] : d.data.address.inside_number);
          setneighborhood(d === null? [] : d.data.address.neighborhood);
          setzip_code(d === null? [] : d.data.address.zip_code);
          setcity(d === null? [] : d.data.address.city);
          settown(d === null? [] : d.data.address.town);
          setstate(d === null? [] : d.data.address.state);
          setcountry(d === null? [] : d.data.address.country);
          setreference(d === null? [] : d.data.address.reference);
          setsubsidiary(d === null? [] : d.data.address.subsidiary);
          setaddress_type(d === null? [] : d.data.address.address_type);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log("error", error);
  }
}

//actualizar empleado
const newemployee = async () => {


street===null||street===''?setvstreet(true):setvstreet(false);
outdoor_number===null||outdoor_number===''?setvoutdoor(true):setvoutdoor(false);
neighborhood===null||neighborhood===''?setvneigh(true):setvneigh(false);
zip_code===null||zip_code===''?setvzip(true):setvzip(false);
city===null||city===''?setvcity(true):setvcity(false);
town===null||town===''?setvtown(true):setvtown(false);
state===null||state===''?setvstate(true):setvstate(false);
country===null||country===''?setvcountry(true):setvcountry(false);

if(street===null||street==='' ||outdoor_number===null||outdoor_number==='' ||
neighborhood===null||neighborhood==='' ||zip_code===null||zip_code==='' ||city===null||city==='' ||town===null||town==='' ||
state===null ||state===null ||country===null||country===''){

  props.alertas("Faltan Parámetros", false);

}else{

  props.reconsultar(true);
  setbandera(false);
  var data = {
      "employee":{
        "name": name,//"string (100)"
        "first_last_name": first_last_name, //"string (100)",
        "second_last_name": second_last_name, //"string (100)",
        "birth_date": birth_date,
        "company_cell_phone_1": company_cell_phone_1, //"string (20)",
        "rfc": rfc, //"string (15)",
        "nss": nss, //"string (20)",
        "curp": curp, //"string (30)",
        "hire_date": hire_date,
        "job_title": job_title, //"string (50)",
        "marital_status": marital_status, //"string (30)",
        "scholarship": scholarship, //"string (50)",
        "profession": profession, //"string (100)",
        "company_cell_phone_2": company_cell_phone_2, //"string (20)",
        "personal_cell_phone_1": personal_cell_phone_1, //"string (20)",
        "personal_home_phone": personal_home_phone, //"string (20)",
        "company_email": company_email, //"string (100)",
        "company_cell_phone_email": company_cell_phone_email, //"string (100)",
        "personal_email": personal_email,//"string (100)",
        "bank_account": bank_account,//"string (50)"
        },
      "address":{
        "street": street, //string(250),
        "outdoor_number": outdoor_number, //string(250),      
        "inside_number": inside_number, //string(250),
        "neighborhood": neighborhood, //string(250),
        "zip_code": zip_code, //string(250),
        "city": city, //string(250),
        "town": town, //string(250),
        "state": state, //string(250),
        "country": country, //string(250),
        "reference": reference, //string(250),
        "subsidiary": subsidiary,  //string(250),
        "address_type": address_type, //string(250)
        }
  }

  props.reconsultar(false);
  console.log(data);
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const dt = await put_employess(props.row.original.id, data);
          console.log(dt);
          props.reconsultar(false);
          props.alertas("Empleado Actualizado con Éxito", true);
          dialog.close();
          props.handleSubmit();
        }catch(err){
          console.log(err);
          catchErrorModul("Empleados", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          if(err.response.status===500){
            props.alertas("No se pudo actualizar el empleado, vuelva a intertarlo", false);
            setbandera(true);
          }else{
            props.alertas("No se pudo actualizar el empleado, vuelva a intertarlo", false);
          }
          props.reconsultar(false);
        }
      }, 1000);
    }else{}  
  } catch (err) {  
   console.log(err);
  };
}
} 

const [formemp, setformemp]=useState(true);
const [formdir, setformdir]=useState(false);
const [value, setValue]=useState(0);

const handlemployee = () => {
  setformemp(true);
  setformdir(false);
}

const handledireccion = ()=>{

  marital_status==="VACIO"?setvmarital(true):setvmarital(false);
  name===null||name===''?setvname(true):setvname(false);
  first_last_name===null||first_last_name===''?setvfname(true):setvfname(false);
  second_last_name===null||second_last_name===''?setvsname(true):setvsname(false);
  birth_date===null||birth_date===''?setvbday(true):setvbday(false);
  company_cell_phone_1===null||company_cell_phone_1===''||company_cell_phone_1.length<10?setvcompanyphone1(true):setvcompanyphone1(false);
  rfc===null||rfc===''||rfc.length<13?setvrfc(true):setvrfc(false);
  nss===null||nss===''||nss.length<11?setvnss(true):setvnss(false);
  curp===null||curp===''||curp.length<18||vcurp===true?setvcurp(true):setvcurp(false);
  hire_date===null||hire_date===''?setvhiredate(true):setvhiredate(false);
  job_title===null||job_title===''?setvjob(true):setvjob(false);
  scholarship===null||scholarship===''?setvschool(true):setvschool(false);
  profession===null||profession===''?setvprof(true):setvprof(false);
  marital_status===null||marital_status===''?setvmarital(true):setvmarital(false);
  personal_cell_phone_1===null||personal_cell_phone_1===''||personal_cell_phone_1.length<10?setvpersonphone(true):setvpersonphone(false);
  company_email===null||company_email===''||vcompanymail===true?setvcompanymail(true):setvcompanymail(false);
  personal_email===null||personal_email===''||vpersonmail===true?setvpersonmail(true):setvpersonmail(false);
  
  if(name===null||name==='' ||first_last_name===null||first_last_name==='' ||second_last_name===null||second_last_name==='' ||birth_date===null||birth_date===''||
  company_cell_phone_1===null||company_cell_phone_1===''||company_cell_phone_1.length<10|| 
  rfc===null||rfc===''||rfc.length<13||nss===null||nss===''||nss.length<11||curp===null||curp===''||curp.length<18||vcurp===true||
  hire_date===null||hire_date===''||marital_status===null||marital_status===''|| marital_status==="VACIO"||
  job_title===null||job_title==='' ||scholarship===null||scholarship==='' ||profession===null||profession==='' ||
  personal_cell_phone_1===null||personal_cell_phone_1===''||personal_cell_phone_1.length<10||
  company_email===null||company_email===''||vcompanymail===true||personal_email===null||personal_email===''||vpersonmail===true )
  {
    props.alertas("Faltan Parámetros", false);
  }else{

    if(personal_cell_phone_1===company_cell_phone_1){
      setigualesphone(true);
      props.alertas("Parámetros incompletos", false);
      return null;
    }else{
      setigualesphone(false);
    }
    if(personal_email===company_email){
      setigualesmail(true);
      props.alertas("Parámetros incompletos", false);
      return null;
    }else{
      setigualesmail(false);
    }
    
      setformemp(false);
      setformdir(true);    
    }
  }

  const handleCorreo = (event) => {

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]+)+$/;
    const newValue = (event.target.value).split(",");
   
    var isValid = true;
    for(var i=0;i<newValue.length;i++){
      if(!emailRegex.test(newValue[i]?.trim())){
        isValid=false;
        break;
      }
    }
    if(isValid){
      setvcompanymail(false);
    }else{
      setvcompanymail(true);
    }
  }
  
const handleCorreo2 = (event) => {

  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]+)+$/;
  const newValue = (event.target.value).split(",");
 
  var isValid = true;
  for(var i=0;i<newValue.length;i++){
    if(!emailRegex.test(newValue[i]?.trim())){
      isValid=false;
      break;
    }
  }
  if(isValid){
    setvpersonmail(false);
  }else{
    setvpersonmail(true);
  }
}

function verificarCURP(event){

  if(event.target.value===null||event.target.value===''){
    setvcurp(true);
  }else{

  const curpRegex = /^[A-Z][AEIOUX][A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z][0-9]$/;
  const curp = event.target.value;
  if(curpRegex.test(curp))  {
    setvcurp(false);
  }else{
    setvcurp(true);
    props.alertas("Formato de CURP Incorrecto", false)
  }
 }
}
function verificarRFC(){
  if(rfc===null || rfc===""){
    setvrfc(true);
  }else{
    setvrfc(false);
    var rfcverif = rfc?.trim();
    
      if(rfcverif.length===13){
        setvrfc(false);
      }else{
        props.alertas("Verifica la longitud del RFC",false);
        setvrfc(true);
      }
    }
}




  return (
    <div>
      <div className="formformat">
      <Box>
  <br/>
  <div className="pestañas">

  <div className="navegacioncompany">
      <Box sx={{ margin: "0 auto" ,width: 400}}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Información General" icon={<PersonIcon onClick={handlemployee} />}  onClick={handlemployee}/>
        <BottomNavigationAction label="Dirección" icon={<RoomIcon onClick={handledireccion}/>} onClick={handledireccion}/>
        
      </BottomNavigation>
    </Box>
    </div>
    </div>
    <br/>
      </Box> 

      
  <div style={formemp===true?{"display":"block"}:{"display":"none"}}>
        <div className="gridemployees1">
          <div className="employee1">
          <TextField
              label="Nombre"
              value={name}
              required
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event)=>setname(event.target.value.slice(0,100))}
              InputLabelProps={{ shrink: true }}
              style={vname===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvname(false)}
              onBlur={
                name===null||name===''?()=>setvname(true):()=>setvname(false)
              }
          
            ></TextField>
             <p className="p_alert" style={vname===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
          </div>
          <div className="employee2">
          <TextField
              label="Apellido Paterno"
              required
              value={first_last_name}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setfirst_last_name(event.target.value.slice(0,100))}  
              InputLabelProps={{ shrink: true }}
              style={vfname===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvfname(false)}
              onBlur={
                first_last_name===null||first_last_name===''?()=>setvfname(true):()=>setvfname(false)
              }
            ></TextField>
          <p className="p_alert" style={vfname===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
          </div>
          <div className="employee3">
          <TextField
              label="Apellido Materno"
              required
              value={second_last_name}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setsecond_last_name(event.target.value.slice(0,100))}  
              InputLabelProps={{ shrink: true }}
              style={vsname===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvsname(false)}
              onBlur={
                second_last_name===null||second_last_name===''?()=>setvsname(true):()=>setvsname(false)
              }
            ></TextField>
              <p className="p_alert" style={vsname===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
        </div>
        <div className="gridemployees2">
         <div className="employee4">

         <label>Fecha Cumpleaños *</label>
          
          <input
            type="text"
            className="fcont" 
            required
            value={birth_date}
            disabled={!bandera}
            style={{"width":"100%"}}
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
              setvbday(false);
            }}
            onChange={(event) => setbirth_date(event.target.value)}
          ></input>
          <p className="p_alert" style={vbday===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee5">
          <TextField
              label="RFC"
              required
              value={rfc}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setrfc(event.target.value.slice(0,13))}  
              InputLabelProps={{ shrink: true }}
              style={vrfc===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvrfc(false)}
              onBlur={verificarRFC}
            ></TextField>
              <p className="p_alert" style={vrfc===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee6">
          <TextField
              label="NSS"
              required
              value={nss}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setnss(event.target.value.slice(0,11))}  
              InputLabelProps={{ shrink: true }}
              style={vnss===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvnss(false)}
              onBlur={
                nss===null||nss===''||nss.length<11?()=>setvnss(true):()=>setvnss(false)
              }
            ></TextField>
              <p className="p_alert" style={vnss===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee7">
          <TextField
              label="CURP"
              required
              value={curp}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcurp(event.target.value.slice(0,18))}  
              InputLabelProps={{ shrink: true }}
              style={vcurp===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={(event)=>setvcurp(false)}
              onBlur={verificarCURP}
            ></TextField>
              <p className="p_alert" style={vcurp===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
        </div>
        <div className="gridemployees3">
        <div className="employee8">
          <label>Fecha Contratación *</label>  
          <input
            type="text"
            className="fcont" 
            required
            value={hire_date}
            disabled={!bandera}
            style={{"width":"100%"}}
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
              setvhiredate(false);
            }}
            onChange={(event) => sethire_date(event.target.value)}
          ></input>
          <p className="p_alert" style={vhire_date===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee9">
          <TextField
              label="Puesto"
              required
              value={job_title}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setjob_title(event.target.value.slice(0,50))}  
              InputLabelProps={{ shrink: true }}
              style={vjob===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvjob(false)}
              onBlur={
                job_title===null||job_title===''?()=>setvjob(true):()=>setvjob(false)
              }
            ></TextField>
              <p className="p_alert" style={vjob===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee10">
          <TextField
            select
            label="Estado Civil"
            defaultValue={""}
            value={marital_status}
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setmarital_status(event.target.value)}  
            style={vmarital===true?{
              "border":"2px solid red", "border-radius":"5px"}:{}
            }
            onSelect={()=>setvmarital(false)}
            onBlur={
              marital_status===null||marital_status===''?()=>setvmarital(true):()=>setvmarital(false)
            }
          >
            <option key={1} value={"VACIO"} selected >{"Seleccione"}</option>
            <option key={2} value={"SOLTERO"}>{"SOLTERO"}</option>
            <option key={3} value={"CASADO"}>{"CASADO"}</option>
            <option key={4} value={"DIVORCIADO"}>{"DIVORCIADO"}</option>
            <option key={5} value={"VIUDO"}>{"VIUDO"}</option>
          </TextField>
              <p className="p_alert" style={vmarital===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          
        </div>
        <div className="gridemployees4">
          <div className="employee11">
          <TextField
              label="Escolaridad"
              required
              value={scholarship}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setscholarship(event.target.value.slice(0,50))}  
              InputLabelProps={{ shrink: true }}
              style={vshool===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvschool(false)}
              onBlur={
                scholarship===null||scholarship===''?()=>setvschool(true):()=>setvschool(false)
              }
            ></TextField>
              <p className="p_alert" style={vshool===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee12">
            <TextField
              label="Profesión"
              required
              value={profession}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setprofession(event.target.value.slice(0,50))}  
              InputLabelProps={{ shrink: true }}
              style={vprof===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvprof(false)}
              onBlur={
                profession===null||profession===''?()=>setvprof(true):()=>setvprof(false)
              }
            ></TextField>
              <p className="p_alert" style={vprof===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee13">
            <TextField
              label="Número Telefónico Empresa"
              required
              value={company_cell_phone_1}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcompany_cell_phone_1(event.target.value.slice(0,10))}  
              InputLabelProps={{ shrink: true }}
              style={vcompanyphone1===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>{
                setvcompanyphone1(false);
                setigualesphone(false);
              }}
              onBlur={
                company_cell_phone_1===null||company_cell_phone_1===''||company_cell_phone_1.length<10?()=>setvcompanyphone1(true):()=>setvcompanyphone1(false)
              }
            ></TextField>
               <p className="p_alert" style={vcompanyphone1===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
              <p className="p_alert" style={igualesphone===true?{"display":"block"}:{"display":"none"}}>No pueden ser los mismos </p>
        
          </div>   
        </div>
        <div className="gridemployees5">
          <div className="employee14">
            <TextField
              label="Número Telefónico Empresa 2"
              value={company_cell_phone_2}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcompany_cell_phone_2(event.target.value.slice(0,10))}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
          <div className="employee15">
            <TextField
              label="Número Telefónico Personal"
              required
              value={personal_cell_phone_1}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setpersonal_cell_phone_1(event.target.value.slice(0,10))}  
              InputLabelProps={{ shrink: true }}
              style={vpersonphone===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>{
                  setvpersonphone(false);
                  setigualesphone(false);
                }}
              onBlur={
                personal_cell_phone_1===null||personal_cell_phone_1===''||personal_cell_phone_1.length<10?()=>setvpersonphone(true):()=>setvpersonphone(false)
              }
            ></TextField>
              <p className="p_alert" style={vpersonphone===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
              <p className="p_alert" style={igualesphone===true?{"display":"block"}:{"display":"none"}}>No pueden ser los mismos </p>
        
          </div>
          <div className="employee16">
            <TextField
              label="Número Telefónico Paricular de Casa"
              value={personal_home_phone}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setpersonal_home_phone(event.target.value.slice(0,10))}  
              InputLabelProps={{ shrink: true }}
             ></TextField>
          </div>
        </div>
        <div className="gridemployees6">
          <div className="employee17">
            <TextField
              label="Correo Electrónico Compañia"
              required
              value={company_email}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcompany_email(event.target.value.slice(0,100))}  
              InputLabelProps={{ shrink: true }}
              style={vcompanymail===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>{
                setvcompanymail(false);
                setigualesmail(false);
              }}
              onBlur={handleCorreo}
            ></TextField>
              <p className="p_alert" style={vcompanymail===true?{"display":"block"}:{"display":"none"}}>Formato de correo inválido </p>
              <p className="p_alert" style={igualesmail===true?{"display":"block"}:{"display":"none"}}>No pueden ser los mismos </p>
        
          </div>
          <div className="employee18">
            <TextField
              label="Correo electrónico personal"
              required
              value={personal_email}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setpersonal_email(event.target.value.slice(0,100))}  
              InputLabelProps={{ shrink: true }}
              style={vpersonmail===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>{
                setvpersonmail(false);
                setigualesmail(false);
              }}
              onBlur={handleCorreo2}
            ></TextField>
              <p className="p_alert" style={vpersonmail===true?{"display":"block"}:{"display":"none"}}>Formato de Correo inválido </p>
              <p className="p_alert" style={igualesmail===true?{"display":"block"}:{"display":"none"}}>No pueden ser los mismos </p>
        
          </div>
          </div>
          <div className="gridemployees7">
         
          <div className="employee19">
            <TextField
              label="Cuenta Bancaria"
              value={bank_account}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setbank_account(event.target.value.slice(0,30))}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
        </div>
        <br></br>
        <Box>
        
        <Button
        variant="contained"
        style={{"background-color":"green", "color":"white"}}
        sx={{ m: 1.5, width: "35ch" }}
        onClick={handledireccion}
      >Siguiente          
       </Button>    
        <Button
          style={{"background-color":"red"}}
          disabled={!bandera}
          variant="contained"
          sx={{ m: 1.5, width: "20ch" }}
          onClick={() => {
              dialog.close();
            } 
          }
        >
        cancelar
      </Button>
      </Box>
  </div>
  <div style={formdir===true?{"display":"block"}:{"display":"none"}}>
{/* datos de direccion */}
        <div className="gridemployees8">
          <div className="employee21">
            <TextField
              label="Calle"
              required
              value={street}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setstreet(event.target.value.slice(0,250))}  
              InputLabelProps={{ shrink: true }}
              style={vstreet===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvstreet(false)}
              onBlur={
                street===null||street===''?()=>setvstreet(true):()=>setvstreet(false)
              }
            ></TextField>
              <p className="p_alert" style={vstreet===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee22">
            <TextField
              label="Número Exterior"
              required
              value={outdoor_number}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setoutdoor_number(event.target.value.slice(0,250))}  
              InputLabelProps={{ shrink: true }}
              style={voutdoor===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvoutdoor(false)}
              onBlur={
                outdoor_number===null||outdoor_number===''?()=>setvoutdoor(true):()=>setvoutdoor(false)
              }
            ></TextField>
              <p className="p_alert" style={voutdoor===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee23">
            <TextField
              label="Número Interior"
              value={inside_number}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setinside_number(event.target.value.slice(0,250))}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
        </div>
        <div className="gridemployees9">
        <div className="employee24">
            <TextField
              label="Colonia"
              required
              value={neighborhood}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setneighborhood(event.target.value.slice(0,250))}  
              InputLabelProps={{ shrink: true }}
              style={vneigh===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvneigh(false)}
              onBlur={
                neighborhood===null||neighborhood===''?()=>setvneigh(true):()=>setvneigh(false)
              }
            ></TextField>
              <p className="p_alert" style={vneigh===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee25">
            <TextField
              label="Código Postal"
              required
              value={zip_code}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setzip_code(event.target.value.slice(0,250))}  
              InputLabelProps={{ shrink: true }}
              style={vzip===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvzip(false)}
              onBlur={
                zip_code===null||zip_code===''?()=>setvzip(true):()=>setvzip(false)
              }
            ></TextField>
              <p className="p_alert" style={vzip===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee26">
            <TextField
              label="Municipio"
              required
              value={city}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcity(event.target.value.slice(0,250))}  
              InputLabelProps={{ shrink: true }}
              style={vcity===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvcity(false)}
              onBlur={
                city===null||city===''?()=>setvcity(true):()=>setvcity(false)
              }
            ></TextField>
              <p className="p_alert" style={vcity===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
        </div>
        <div className="gridemployees10">
          <div className="employee27">
            <TextField
              label="Localidad"
              required
              value={town}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settown(event.target.value.slice(0,250))}  
              InputLabelProps={{ shrink: true }}
              style={vtown===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvtown(false)}
              onBlur={
                town===null||town===''?()=>setvtown(true):()=>setvtown(false)
              }
            ></TextField>
              <p className="p_alert" style={vtown===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee28">

          <TextField
            select
            required
            label="Estado"
            value={state}
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            style={
              vstate===true?{"border":"2px solid red", "border-radius":"5px"}:{}
            }
            onSelect={()=>setvstate(false)}
            onBlur={
              state===null?()=>setvstate(true):()=>setvstate(false)
            }
            onChange={(event) => setstate(event.target.value)}  
          >
            {
              estados.map(item =>(
                <option key={item.clave} value={item.label}>
                  {item.label}
                </option>
              ))
            }
            
            </TextField>
          <p className="p_alert" style={vstate===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
          <div className="employee29">
            <TextField
              label="País"
              required
              value={country}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcountry(event.target.value.slice(0,250))}  
              InputLabelProps={{ shrink: true }}
              style={vcountry===true?{
                "border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvcountry(false)}
              onBlur={
                country===null||country===''?()=>setvcountry(true):()=>setvcountry(false)
              }
            ></TextField>
              <p className="p_alert" style={vcountry===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        
          </div>
        </div>
        <div className="gridemployees11">
          <div className="employee30">
            <TextField
              label="Referencias"
              value={reference}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setreference(event.target.value.slice(0,250))}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
              
          </div>
          <div className="employee31">
          <TextField
            select
            label="Sucursal"
            defaultValue={""}
            value={subsidiary}
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setsubsidiary(event.target.value)}  
          >
            <option key={1} value={"MATRIZ"} selected >
                {"MATRIZ"}
            </option>
            <option key={2} value={"SUCURSAL"}>
                {"SUCURSAL"}
            </option>
          </TextField>
          
          </div>
          <div className="employee32">
          <TextField
            select
            label="Tipo de Dirección"
            value={address_type}
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setaddress_type(event.target.value)}  
          >
            <option key={1} value={"ENTREGA"} selected >
                {"ENTREGA"}
            </option>
            <option key={2} value={"FISCAL"}>
                {"FISCAL"}
            </option>
          </TextField>


          </div>
        </div>
        <br></br>
        <Box>
        
        <Button
        variant="contained"
        style={{"background-color":"green", "color":"white"}}
        sx={{ m: 1.5, width: "35ch" }}
        onClick={newemployee}
      >Actualizar Empleado         
       </Button>    
        <Button
          style={{"background-color":"red"}}
          disabled={!bandera}
          variant="contained"
          sx={{ m: 1.5, width: "20ch" }}
          onClick={() => {
              dialog.close();
            } 
          }
        >
        cancelar
      </Button>
      </Box>
       </div> 
      </div>
    </div>
  );
}

export default EditEmployees;
