//PAQUETERIAS
import { useEffect, useState, useRef} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { Checkbox } from "semantic-ui-react";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_productslines, put_discount } from "../../services/businesspartners/businesspartners";

function EditDiscount(props) {

  const notificationSystemRef = useRef();  
  const [plazo, setplazo]=useState(!(props.row.original.undefined));
  const [fi, setfi]=useState(props.row.original.undefined===false?(props.row.original.init_date)?.slice(0,10):null);
  const [ff, setff]=useState(props.row.original.undefined===false?(props.row.original.fin_date)?.slice(0,10):null);
  const [tag, settag] = useState(props.row.original.tag);
  const [discount, setdiscount] = useState(props.row.original.discount);
  const [discountOrder, setdiscountOrder] = useState(props.row.original.discount_order);
  const [enable, setenable] = useState(props.row.original.enable);
  const [idProductsLine, setidProductsLine] = useState(props.row.original.id_products_line_fk);
  const dialog = useDialog();
  const[productsline, setproductsline] = useState([]);
  const[bandera, setbandera]=useState(true);

useEffect(()=>{
  handlelineprod();
},[]);

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}
const handlelineprod = async ()=>{
  try {
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          var d = await get_productslines();
          setproductsline(d === null ? [] : d.data);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}  
  } catch (err) {}
}
const handleChange = () => {
  if(enable===true){
    setenable(false);
  }else{
    setenable(true);
  }  
};
const handleChange2=()=>{
  if(plazo===true){
    setplazo(false);
  }else{
    setplazo(true);
  }
}



function alertascreate(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'tr', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'tr', 
      autoDismiss: 60, 
    });
  }  
}


//Actualizar descuento
const updatediscount = async ()=>{

var linea = idProductsLine;

if(linea===0){
  linea=null;
}
if(!plazo===false){
  if(fi===null || ff === null){
    alert("Parametros faltantes en fecha inicial o fecha final");
    return null
  }
}
   var data = {
      "undefined": !plazo,
      "initDate": fi,
      "finDate": ff, 
      "enable":enable,
      "discount":parseInt(discount),
      "tag":tag,
      "discountOrder":parseInt(discountOrder),
      "idProductsLine":parseInt(linea),
  }
  
props.reconsultar(true);
setbandera(false);

try {
  var rf_token = await refresh_token();
  if(rf_token === true){
    setTimeout(async() => {
      try{
        const dt = await put_discount(data, props.row.original.idDiscount);
        console.log(dt);
        props.alertas("Descuento actualizado con éxito", true);
        props.reconsultar(false);
        dialog.close();
        props.handleSubmit();
      }catch(err){
        console.log("error post", err);
        alertascreate("No se pudo actualizar el descuento, vuelva a intertarlo",false);
        catchErrorModul("Descuentos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response)); 
        props.reconsultar(false);
        setbandera(true);
      }     
    }, 1000);
  }else{}
} catch (err) { 
  console.log(err);
}
} 


return (
  <div>
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

    <div className="formulariodescuentos">

        <Box> 

    <div className="gridds0">
    <div className="itemdis00">
      <label
          style={{"fontSize":"18px", "color":"black", "paddingTop":"25px", "paddingRight":"10px"}}
            >Descuento Sujeto a Plazo: 
             <Checkbox
            checked={plazo}
            disabled={!bandera}
            onChange={handleChange2}
            inputProps={{"aria-label": "primary checkbox"}}
            style={{"paddingRight":"10px", "paddingLeft":"20px"}}
            />     
      </label>  
    </div>
  </div>
  <div className="griddsfechas" style={plazo===true?{"display":"grid"}:{"display":"none"}}>
    <div className="itemdisfi">
    <label>Fecha Inicial:&nbsp;</label> 
    <input
                  type="text"
                  className="fi" 
                  disabled={!bandera}
                  value={fi}
                  style={{"width":"50%"}}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                placeholder="Fecha inicial"
                onChange={(event) => setfi(event.target.value)}
            ></input>
    </div>
    <div className="itemdisff">
    <label>Fecha Final:&nbsp;&nbsp;&nbsp;</label>
    <input
                  type="text"
                  className="ff"
                  style={{"width":"50%"}}
                  value={ff}
                  disabled={!bandera}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
            ></input>
    </div>
  </div>

  <div className="griddis4">
  <div className="itemdis1e" style={props.row.original.idPointSale==null?{"display":"none"}:{}}> 
    <TextField
              label="Punto de Venta"
              value={props.row.original.namePointSale}
              required
              disabled
              sx={{ m: 1.5, width: "100%" }}
              InputLabelProps={{ shrink: true }}
    ></TextField>
  </div>
  <div className="itemdis1" style={props.row.original.idPointSale==null?{}:{"display":"none"}}>
      <TextField
              label="Socio de Negocios"
              value={props.row.original.business_partner}
              required
              disabled
              sx={{ m: 1.5, width: "100%" }}
              InputLabelProps={{ shrink: true }}
    ></TextField>
  </div>  
  </div>
  <div className="griddis4">
    <div className="itemdis2">
    <TextField
              label="Etiqueta"
              value={tag}
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settag(event.target.value)}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
    </div>
  </div>
  <div className="griddis5">
    <div className="itemdis3">
    <TextField
              label="Descuento %"
              type={"number"}
              disabled={!bandera}
              value={discount}
              required
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setdiscount(event.target.value)}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
    </div>
    <div className="itemdis4">
    <TextField
              label="Orden de Cálculo"
              type={"number"}
              disabled={!bandera}
              value={discountOrder}
              required
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setdiscountOrder(event.target.value)}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
    </div>
    <div className="itemdis5">
    <label
              style={{"fontSize":"18px", "color":"black", "paddingTop":"25px", "paddingRight":"10px"}}
            >Disponible: 
            
             <Checkbox
            checked={enable}
            disabled={!bandera}
            onChange={handleChange}
            inputProps={{"aria-label": "primary checkbox"}}
            style={{"paddingRight":"10px", "paddingLeft":"20px"}}
            />      </label>  
    </div>
  </div>
  <div className="griddis6">
    <div className="itemdis6">
    <TextField
              select
              label="Línea de Productos"
              disabled={!bandera}
              defaultValue={null}
              InputLabelProps={{
                shrink: true,
              }}
              value={idProductsLine}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setidProductsLine(event.target.value)}  
            >
             
            {
              productsline?.productsLineList?.map((item) => (
                <option
                   key={item.id}
                   value={item.id}
                 >       
                  {item.name_line}
                 </option>
              ))
            }
             <option key={0} value={0}
                 >
                  {"GENERAL"}
                 </option>
              
             </TextField>
    </div>
  
  </div> 
          </Box>
          <br></br>
          <br></br>
          <Box>
            {bandera===true?
            <Button
            variant="contained"
            style={{"backgroundColor":"green"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={updatediscount}
          >
            Actualizar Descuento
          </Button>:
          <Button
          variant="contained"
          style={{"backgroundColor":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "30ch" }}
          onClick={updatediscount}
        ><Spinner/></Button>
        }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}
export default EditDiscount;
