import React, { useState, useEffect, Suspense } from "react";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import EFECTO from "../../../../../dataComponets/efect.json";
import {
  get_accounting_accounts,
  update_expenses_groups_policy,
} from "../../../../../services/expenses/expenses";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import { refresh_token } from "../../../../../main_components/tokens/tokenrefresh";
import MODAL_TABLE from "../../../../../main_components/modal/modal_Table";
import { ValidationTextField } from "../../../../../main_components/lable/labe";


const UPDATE_DETAIL_POLICY = ({
  id_detail,
  codaccounts,
  codconcept,
  codamount,
  codeffect,
  codidConcept,
  codIdPolicy,
  codIdDetail,
  codMod,
}) => {
  const dialog = useDialog();
  const [open, setOpen] = useState(false);
  const [idConceptUpdate, setidConceptUpdate] = useState(codidConcept);
  const [ConceptUpdate, setConceptUpdate] = useState(codconcept);
  const [accountUpdate, setaccountUpdate] = useState(codaccounts);
  const [importe, setImporte] = useState(codamount);
  const [efecto, setefecto] = useState(codeffect);
  const [pro_servData, setpro_servData] = useState([]);
  const handleOnSearch = (string, results) => {};

  const handleOnHover = (result) => {};
  const handleOnSelect = (item) => {
    setidConceptUpdate(item.id);
    setConceptUpdate(item.name);
    setaccountUpdate(item.code);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleOnClear = () => {
    console.log("Cleared");
  };

  const handelImporte = (event) => {
    setImporte(event?.target?.value);
  };
  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };

  const handelEfect = (event) => {
    setefecto(event?.target?.value);
  };

  var data = {
    id_account: idConceptUpdate,
    amount: Number(importe),
    effect: efecto,
  };

  console.log(data);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        var data = {
          id_account: idConceptUpdate,
          amount: Number(importe),
          effect: efecto,
        };
        console.log(data);
        setOpen(true);
    
        try {
          await update_expenses_groups_policy(data,sessionStorage.getItem("IdGrupExpense"),codIdPolicy,codIdDetail);
          setOpen(false);
          dialog.close(true);
        } catch (err) {
          setOpen(false);
          var error = err?.response?.data?.errorMessage;
          alert("Error.," + error);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }

  };


  const handleprod_concept_list = async () => {
    
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          var dt = await get_accounting_accounts(0);
          const data = dt.data;
          const dataStatic = {
            id_account: "9090909090909",
            concept: "SELECCIONE UNA OPCIÓN",
            account: "SELECCIONE UNA OPCIÓN",
          };
          data[data.length] = dataStatic;
          setpro_servData(data);
        } catch (error) {
          setpro_servData([]);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

 

  useEffect(() => {
    handleprod_concept_list();
  }, []);

  return (
    <div>
      <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>

      <div>
        <form>
          <Box>
            <div style={{ width: "88%", margin: "0 auto" }}>
              <ReactSearchAutocomplete
                items={pro_servData}
                fuseOptions={{ keys: ["id", "name_code"] }}
                resultStringKeyName={"name_code"}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                onClear={handleOnClear}
                showIcon={true}
                placeholder="Buscar concepto..."
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 10,
                }}
              />
            </div>
          </Box>

          <Box className="">
            <ValidationTextField
              label="Concepto"
              variant="outlined"
              placeholder="Cuenta"
              sx={{ m: 2, width: "50%" }}
              value={ConceptUpdate}
              InputLabelProps={{ shrink: true }}
            />
            <ValidationTextField
              label="Cuenta"
              variant="outlined"
              placeholder="Cuenta"
              sx={{ m: 2, width: "34%" }}
              value={accountUpdate}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box>
            <TextField
              select
              label="Efecto"
              value={efecto}
              sx={{ m: 1.5, width: "44%" }}
              onChange={handelEfect}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {EFECTO.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
            <ValidationTextField
              label="Importe"
              variant="outlined"
              placeholder="Importe"
              type="number"
              sx={{ m: 2, width: "40%" }}
              onChange={handelImporte}
              onBlur={handelImportOnBurl}
              value={importe}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Actualizar
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default UPDATE_DETAIL_POLICY;
