//PAQUETERIAS
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES

//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";
import "./modal.css";

//IMAGENES
import Spinner from "../../imgComponents/S.gif";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  opacity: "1 !important",
};

function MODAL_TABLE({ open, message, message2, modalGeneral, modalT }) {
  return (
    <div>
      <div style={{ display: modalGeneral === true ? "" : "none" }}>
        <Modal
          open={modalGeneral}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img alt="IMG" src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                <p>{message}</p>
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>

      <div
        style={{
          display:
            modalT === true ? (modalGeneral === false ? "" : "none") : "none",
        }}
      >
        <Modal
          open={modalT}
          className="modal2Carga"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style1}>
            <div className="">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {message2}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default MODAL_TABLE;
