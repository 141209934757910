//Paqueteria
import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../../../imgComponents/S.gif";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useDialog } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from '@mui/material/CircularProgress';
//Componentes
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";
import { get_units, get_prod_serv, post_concepts, get_category_sat, get_section_sat, get_section_codes_sat, get_unit_codes_sat,put_concepts_taxes } from "../../../../services/invoices/invoice"
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import Autocomplete from '@mui/material/Autocomplete';


const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2, height: '45px' },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const ObjetoImp = [
  { value: "01", label: "No objeto de impuesto" },
  { value: "02", label: "Sí objeto de impuesto" },
  { value: "03", label: "Sí objeto del impuesto y no obligado al desglose" },
];

const type_code = [
  { value: "product", label: "Producto" },
  { value: "service", label: "Servicio" },
];

const SET_CONCEPT_V2 = (props) => {
  const [open, setOpen] = useState(false);
  const [Concepto, setConcepto] = useState(props?.Concepto);
  const [Clave_prod_serv, setClave_prod_serv] = useState(props?.Clave_prod_serv);//01010101
  const [Clave_unidad, setClave_unidad] = useState(props?.Clave_unidad);//ACT
  const [Clave_unidad_autocomplete, setClave_unidad_autocomplete] = useState({ "code_unit": "ACT", "description": "Actividad" });
  const [Descripcion, setDescripcion] = useState(props?.Descripcion);
  const [No_identificacion, setNo_identificacion] = useState(props?.No_identificacion);
  const [Objeto_imp, setObjeto_imp] = useState(props?.Objeto_imp);//01
  const [Peso_en_kg, setPeso_en_kg] = useState(props?.Peso_en_kg); //1k
  const [Unidad, setUnidad] = useState(props?.Unidad);//ACTIVIDAD
  const [Valor_unitario, setValor_unitario] = useState(props?.Valor_unitario);
  const [unidadData, setunidadData] = useState([]);
  const [pro_servData, setpro_servData] = useState([]);

  const [Clave_prod_serv_input, setClave_prod_serv_input] = useState(true);
  const [Clave_unidad_input, setClave_unidad_input] = useState(true);
  
  const [Clave_unidad_input_manual, setClave_unidad_input_manual] = useState(true);
  const [Clave_prod_serv_input_manual, setClave_prod_serv_input_manual] = useState(true);


  const [category_data, setcategory_data] = useState([]);
  const [category_data_filter, setcategory_data_filter] = useState([]);
  const [section_data, setsection_data] = useState([]);
  const [select_section_data, setselect_section_data] = useState([]);
  const [select_category_data, setselect_category_data] = useState("N/A");
  const [select_type_data, setselect_type_data] = useState("product");
  const [codes_data, setcodes_data] = useState([]);
  const [sat_units_codes, setsat_units_codes] = useState([]);
  const [flag_section, setflag_section] = useState(false);
  const [flag_section_code, setflag_section_code] = useState(false);

  const [color_manual_mode, setcolor_manual_mode] = useState("#8989b9");
  const [color_slect_mode, setcolor_slect_mode] = useState("blue");
  const [color_cataloge_mode, setcolor_cataloge_mode] = useState("blue");



  const handle_concept = (event) => { setConcepto(event?.target?.value); };
  const handelClave_prod_serv = (event) => {
    setClave_prod_serv(event?.target?.value);
  };

  const handle_cataloge_mode = () => {
    setClave_prod_serv_input(false);
    setClave_prod_serv("N/A");
    setClave_unidad_input(false);
    setClave_prod_serv_input_manual(false);
    setClave_unidad_input_manual(false);
    setcolor_manual_mode("blue");
    setcolor_slect_mode("blue");
    setClave_unidad("ACT");
    setcolor_cataloge_mode("#8989b9");
    setUnidad("ACTIVIDAD");
  }


  const handle_select_mode = () => {
    setClave_prod_serv_input(true);
    setClave_prod_serv("01010101");
    setClave_unidad_input(true);
    setClave_prod_serv_input_manual(false)
    setClave_unidad_input_manual(false)
    setClave_unidad("ACT");
    setUnidad("ACTIVIDAD");
    setcolor_manual_mode("blue");
    setcolor_slect_mode("#8989b9");
    setcolor_cataloge_mode("blue");
  }


  const handle_manual_mode = () => {
    setClave_prod_serv_input(true);
    setClave_prod_serv("");
    setClave_unidad_input(true);
    setClave_prod_serv_input_manual(true);
    setClave_unidad_input_manual(true);
    setClave_unidad("")
    setUnidad("");
    setcolor_manual_mode("#8989b9");
    setcolor_slect_mode("blue");
    setcolor_cataloge_mode("blue");
  }



  const handelClave_unidad = (event) => {
    setClave_unidad(event?.target?.value);
    var dt = sat_units_codes.filter((d) => d.code_unit === event.target.value)
  };

  const handelClave_unidad_autocomplete = (event, newValue) => {
    console.log(newValue)
    setClave_unidad(newValue?.code_unit);
    setClave_unidad_autocomplete(newValue)
  };


  const handelDescripcion = (event) => { setDescripcion(event?.target?.value); };
  const handelNo_identificacion = (event) => { setNo_identificacion(event?.target?.value); };
  const handelObjeto_imp = (event) => { setObjeto_imp(event?.target?.value); };
  const handelPeso_en_kg = (event) => {
    setPeso_en_kg(event?.target?.value);
    if (Number(event?.target?.value) > 0) {
    } else {
      alert("El peso debe ser mayor a 0");
    }
  };
  const handelUnidad = (event) => { setUnidad(event?.target?.value); };
  const handelValor_unitario = (event) => { setValor_unitario(event?.target?.value); };
  const handelOnValor_unitario = () => { setValor_unitario(Number(Valor_unitario).toFixed(2)); };
  const dialog = useDialog();

  var data = {
    concept: Concepto,
    clave_prod_serv: Clave_prod_serv,
    clave_unidad: Clave_unidad,
    descripcion: Descripcion,
    no_identificacion: No_identificacion,
    objeto_imp: Objeto_imp,
    peso_en_kg: Number(Peso_en_kg),
    unidad: Unidad,
    valor_unitario: Number(Valor_unitario),
  };
  console.log(data);
  const handleSubmit = async (event) => {
    event?.preventDefault();
    var data = {
      concept: Concepto,
      clave_prod_serv: Clave_prod_serv,
      clave_unidad: Clave_unidad,
      descripcion: Descripcion,
      no_identificacion: No_identificacion,
      objeto_imp: Objeto_imp,
      peso_en_kg: Number(Peso_en_kg),
      unidad: Unidad,
      valor_unitario: Number(Valor_unitario),
    };
    console.log(data);
    setOpen(true);
    props?.setmodalT ? props?.setmodalT(true) : console.log("");

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            if(props?.method==="CREATE"){
              const d = await post_concepts(data);
              console.log(d);
              setOpen(false);
              props?.setmodalT ? props?.setmodalT(false) : console.log("");
              props?.alertas ? props?.alertas("Se genero correctamente el concepto", true) : alert("Se genero correctamente el concepto");
              dialog.close(true);
              props?.handleSubmit ? props?.handleSubmit() : console.log("");
            }else{
              const d = await put_concepts_taxes(props?.id_concept, data);
              props?.setmodalT ? props?.setmodalT(false) : console.log("");
              props?.alertas ? props?.alertas("Se actualizo correctamente el concepto", true) : alert("Se actualizo correctamente el concepto");
              dialog.close(true);
              props?.handleSubmit ? props?.handleSubmit() : console.log("");

            }
           
          } catch (err) {
            setOpen(false);
            props?.setmodalT ? props?.setmodalT(false) : console.log("");
            catchErrorModul("Conceptos", JSON.stringify(data), JSON.stringify(err?.response));
            props?.alertas ? props?.alertas("Error.," + err.response.data.errorMessage, false) : alert("Error.," + err.response.data.errorMessage);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }

  };

  const handleUnidades = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_units();
            setunidadData(dt.data);
          } catch (err) {
            setunidadData([]);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleprod_serv = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_prod_serv();
            setpro_servData(dt.data);
          } catch (err) {
            setpro_servData([]);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };



  const handle_category = async (event) => {
    event?.preventDefault();
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_category_sat();
            const data = d === null ? [] : d.data;
            // Los dos nuevos objetos que deseas agregar
            const newObjects = [
              {
                "category": "N/A",
                "description": "Seleccione un servicio",
                "type": "service"
              },
              {
                "category": "N/A",
                "description": "Seleccione un producto",
                "type": "product"
              }
            ];

            // Concatena los nuevos objetos con el arreglo original
            const newData = newObjects.concat(data);
            setcategory_data(newData);
            setcategory_data_filter(newData.filter((d) => d.type === "product"));

            console.log(data)

          } catch (err) {
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };


  const handle_unit_codes_sat = async (event) => {
    event?.preventDefault();
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_unit_codes_sat();
            const data = d === null ? [] : d.data;
            // Los dos nuevos objetos que deseas agregar
            const newObjects = [
              {
                "code_unit": "N/A",
                "description": "Seleccione un producto",
              }
            ];

            // Concatena los nuevos objetos con el arreglo original
            const newData = newObjects.concat(data);
            setsat_units_codes(newData)
            console.log(newData)

          } catch (err) {
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };


  const handle_section = async (e) => {
    try {
      setflag_section(true);
      setsection_data([]);
      setcodes_data([]);
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_section_sat(e);
            const data = d === null ? [] : d.data;
            const newObjects = [
              {
                "description_section": "Selecciona una opcion",
                "section": "N/A",
                "id": "N/A"
              },
            ];

            // Concatena los nuevos objetos con el arreglo original
            const newData = newObjects.concat(data);
            setsection_data(newData);


            console.log(data)

          } catch (err) {
          } finally {
            setflag_section(false)
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };
  const handle_section_codes = async (e) => {
    try {
      setflag_section_code(true);
      setcodes_data([]);

      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_section_codes_sat(e);
            const data = d === null ? [] : d.data;
            const newObjects = [
              {
                "description": "Selecciona una opcion",
                "code": "N/A",
              },
            ];

            // Concatena los nuevos objetos con el arreglo original
            const newData = newObjects.concat(data);
            setcodes_data(newData)
            console.log(data)

          } catch (err) {
          } finally {
            setflag_section_code(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleprod_serv();
    handleUnidades();
    handle_category();
    handle_unit_codes_sat();
  }, []);

  return (
    <div>
      <form style={{ width: "90%", margin: "0 auto" }}>
        <Box>

          <TextareaAutosize
            label="Concepto"
            variant="outlined"
            aria-label="empty textarea"
            value={Concepto}
            placeholder="Concepto"
            className="TexAreaAutosize_data"
            onChange={handle_concept}
          />
        </Box>


        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px', paddingBottom: '5px' }}>
          <Button
            variant="contained"
            sx={{ width: "30%" }}
            style={{ background: color_slect_mode }}
            onClick={handle_select_mode}
          >
            SELECTOR
          </Button>
          <Button
            variant="contained"
            sx={{ width: "30%" }}
            style={{ background: color_manual_mode }}
            onClick={handle_manual_mode}

          >
            MANUAL
          </Button>
          <Button
            variant="contained"
            sx={{ width: "30%" }}
            style={{ background: color_cataloge_mode }}
            onClick={() => {
              handle_cataloge_mode()
            }}
          >
            CATALOGO
          </Button>
        </Box>


        <div
          style={{ display: Clave_prod_serv_input === true ? "" : "none" }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <TextField
              select
              label="Clave producto/servicio"
              value={Clave_prod_serv}
              style={{ display: Clave_prod_serv_input_manual ? "none" : "" }}
              sx={{ width: '69%' }}
              onChange={handelClave_prod_serv}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {pro_servData.map((option) => (
                <option
                  key={option.clave_prod_serv}
                  value={option.clave_prod_serv}
                  selected={option.clave_prod_serv}
                >
                  {option.clave_prod_serv +
                    " " +
                    "(" +
                    option.descripcion +
                    ")"}
                </option>
              ))}
            </TextField>
            <ValidationTextField
              label="Clave Producto Servicio"
              variant="outlined"
              placeholder="Clave Producto Servicio"
              sx={{ width: '69%' }}
              style={{ display: Clave_prod_serv_input_manual ? "" : "none" }}
              onChange={(e) => setClave_prod_serv(e.target.value)}
              value={Clave_prod_serv}
              InputLabelProps={{ shrink: true }}
            />

            <ValidationTextField
              label="N. Identificación"
              variant="outlined"
              placeholder="N. Identificación"
              sx={{ width: '29%' }}
              onChange={handelNo_identificacion}
              value={No_identificacion}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

        </div>

        <div style={{ display: Clave_prod_serv_input === true ? "none" : "" }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <TextField
              select
              label="Tipo"
              value={select_type_data}
              sx={{ width: '49%' }}
              onChange={(e) => {
                setselect_type_data(e.target.value);
                console.log(e.target.value)
                setcategory_data_filter(category_data.filter((d) => d.type === e.target.value));
                setsection_data([]);
              }}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {type_code.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>


            <ValidationTextField
              label="N. Identificación"
              variant="outlined"
              placeholder="N. Identificación"
              sx={{ width: '49%' }}
              onChange={handelNo_identificacion}
              value={No_identificacion}
              InputLabelProps={{ shrink: true }}
            />

          </Box>

        </div>


        <div style={{ display: Clave_prod_serv_input === true ? "none" : "" }}>

          <Box sx={{ display: 'flex', justifyContent: "flex-end", width: '100%', marginTop: '10px' }}>


            <TextField
              select
              label="Categoría"
              value={select_category_data}
              sx={{ width: '100%' }}
              onChange={(e) => {
                setselect_category_data(e.target.value);
                handle_section(e.target.value)

              }}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {/* {category_data_filter.map((option) => (
                <option
                  key={option.category}
                  value={option.category}
                  selected={option.category}
                >
                  {option.category + "-" + option.description}
                </option>
              ))} */}

              {category_data_filter
                .sort((a, b) => a.category.localeCompare(b.category)) // Ordenar por categoría
                .map((option) => (
                  <option
                    key={option.category}
                    value={option.category}
                    selected={option.category}
                  >
                    {option.category + "-" + option.description}
                  </option>
                ))}

            </TextField>

          </Box>

        </div>

        <div style={{ display: Clave_prod_serv_input === true ? "none" : "" }}>

          <Box sx={{ display: 'flex', justifyContent: "flex-end", width: '100%', marginTop: '10px' }}>
            <Box sx={{ position: 'fixed', display: flag_section === true ? "" : "none" }}>
              <CircularProgress />
            </Box>
            <TextField
              select
              label="Sección"
              value={select_section_data}
              sx={{ width: '100%' }}
              onChange={(e) => {
                handle_section_codes(e.target.value)
                setselect_section_data(e.target.value)
                console.log(e.target.value)
              }}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {section_data
                .sort((a, b) => a.section.localeCompare(b.section)) // Ordenar por sección
                .map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    selected={option.id}
                  >
                    {option.section + "-" + option.description_section}
                  </option>
                ))}

            </TextField>
          </Box>

        </div>

        <div style={{ display: Clave_prod_serv_input === true ? "none" : "" }}>

          <Box sx={{ display: 'flex', justifyContent: "flex-end", width: '100%', marginTop: '10px' }}>
            <Box sx={{ position: 'fixed', display: flag_section_code === true ? "" : "none" }}>
              <CircularProgress />
            </Box>
            <TextField
              select
              label="Concepto"
              value={Clave_prod_serv}
              sx={{ width: '100%' }}
              onChange={handelClave_prod_serv}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {codes_data.map((option) => (
                <option
                  key={option.code}
                  value={option.code}
                  selected={option.code}
                >
                  {option.code + " - " + option.description}
                </option>
              ))}
            </TextField>


          </Box>

        </div>
        <div style={{ margin: "18px" }}></div>
        <div style={{ display: Clave_unidad_input === true ? "none" : "", }}>
          <Box sx={{ display: 'flex', justifyContent: "flex-end", width: '100%', marginTop: '10px', }}>
            {/* <TextField
              select
              label="Clave Unidad"
              value={Clave_unidad}
              sx={{ width: '100%' }}
              onChange={handelClave_unidad}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {sat_units_codes.map((option) => (
                <option
                  key={option.code_unit}
                  value={option.code_unit}
                  selected={option.code_unit}
                >
                  {option.code_unit + " - " + option.description}
                </option>
              ))}
            </TextField> */}

            <Autocomplete
              id="product-search"
              options={sat_units_codes}
              sx={{ width: "100%" }}
              getOptionLabel={(option) => `${option.code_unit} - ${option.description}`}
              value={Clave_unidad_autocomplete}
              onChange={handelClave_unidad_autocomplete}
              renderInput={(params) => <TextField {...params} label="Buscar producto" />}
            />


          </Box>

        </div>

        <div
          style={{ display: Clave_unidad_input === true ? "" : "none" }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px', paddingBottom: "5px" }}>
            <TextField
              select
              label="Clave Unidad"
              style={{ display: Clave_unidad_input_manual ? "none" : "" }}
              value={Clave_unidad}
              sx={{ width: "59%" }}
              onChange={handelClave_unidad}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {unidadData.map((option) => (
                <option
                  key={option.clave_unidad}
                  value={option.clave_unidad}
                  selected={option.clave_unidad}
                >
                  {option.clave_unidad + " " + "(" + option.unidad + ")"}
                </option>
              ))}
            </TextField>
            <ValidationTextField
              label="Clave Unidad"
              style={{ display: Clave_unidad_input_manual ? "" : "none" }}
              variant="outlined"
              placeholder="Clave Unidad"
              sx={{ width: '59%' }}
              onChange={(e) => setClave_unidad(e.target.value)}
              value={Clave_unidad}
              InputLabelProps={{ shrink: true }}
            />
            <ValidationTextField
              label="Unidad"
              variant="outlined"
              placeholder="Unidad"
              onChange={(e) => setUnidad(e.target.value)}
              sx={{ width: "39%" }}
              value={Unidad}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

        </div>



        <Box sx={{ marginTop: '10px' }}>
          <TextareaAutosize
            label="Descripciónn"
            variant="outlined"
            aria-label="empty textarea"
            value={Descripcion}
            placeholder="Descripción"
            className="TexAreaAutosize_data"
            onChange={handelDescripcion}
          />
        </Box>


        <div
          style={{ display: Clave_unidad_input === true ? "" : "" }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <TextField
              select
              label="Objeto Impuesto"
              value={Objeto_imp}
              sx={{ width: "40%" }}
              onChange={handelObjeto_imp}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              <option
                  key={0}
                  value={""}
                >
                  {"Seleccione una opción"}
                </option>
              {ObjetoImp.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>

            <ValidationTextField
              label="Peso en KG"
              variant="outlined"
              placeholder="Peso en KG"
              sx={{ width: "28%" }}
              onChange={handelPeso_en_kg}
              type="number"
              value={Peso_en_kg}
              InputLabelProps={{ shrink: true }}
            />

            <ValidationTextField
              label="Valor Unitario"
              variant="outlined"
              type="number"
              placeholder="Valor Unitario"
              sx={{ width: "28%" }}
              onChange={handelValor_unitario}
              onBlur={handelOnValor_unitario}
              value={Valor_unitario}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

        </div>

        <div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <Button
              variant="contained"
              sx={{ width: "49%" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              {props?.method==="CREATE"?"CREAR":"ACTUALIZAR"}
            </Button>

            <Button
              variant="contained"
              sx={{ width: "49%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>

        </div>
        <br></br>
      </form>
    </div>
  );
};

export default SET_CONCEPT_V2;
