//PAQUETERIAS
import React, {useState, useMemo } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import { base64toBlob } from "../../../../../main_components/donwloadfuncion/download";
//COMPONENTES
import SubTableContainer from "../../../SubTableContainer";
import MODAL_TABLE from "../../../../../main_components/modal/modal_Table";
import { download_invoice } from "../../../../../services/invoices/invoice";
import { generate_invoice_return_cashclosing } from "../../../../../services/cashclosing/cashclosing";
import CONSULT_DETAIL_SALE from "./consult_detail_sale";
import { delete_bon_dev } from "../../../../../services/cashclosing/cashclosing";
//ESTILOS
import "../../../../../components_cfdi/estilo.css";
import "../../../cashclosing.css";
import "../../../../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import PDF from "../../../../../imgComponents/pdf.png";
import VIEW from "../../../../../imgComponents/lupa.png";
import INVOICE from "../../../../../imgComponents/invoice.png";
import DELETE from "../../../../../imgComponents/borrar.png";

import { catchErrorModul } from "../../../../../main_components/catchErr/catchError";
import { refresh_token  } from "../../../../../main_components/tokens/tokenrefresh";

const CONSULT_RETURN_CASHCLOSING = (props) => {
  const [data] = useState(
    JSON.parse(sessionStorage.getItem("TikectconsulReturn"))
  );
  const [modalT, setmodalT] = useState(false);



  const handelGenerateFactureReturn = async (id_sale_return) => {
    const bonD = {};

    try {
      await refresh_token ();
      const dt= await generate_invoice_return_cashclosing(id_sale_return,bonD);
      console.log(dt);
      alert("Éxito,venta facturada correctamente");
      props.handleReconsulta(true);
      setmodalT(false);

    } catch (err) {
      catchErrorModul("Corte de Caja => Facturar Devolución/Bonificación",JSON.stringify(bonD),JSON.stringify( err?.response));
      alert("Error.," + JSON.stringify(err?.response?.data));
      setmodalT(false);
    }
  };

  

  const handleDonwload  = async (typedocumentexmlorpdf, id_invoce, ticket) => {
    setmodalT(true);
    try {
      const data = await download_invoice(typedocumentexmlorpdf,null,id_invoce);
      var dt = data.data.file;
      var blob = base64toBlob(dt);
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      var arr = ticket;
      downloadLink.download = arr + `.${typedocumentexmlorpdf}`;
      downloadLink.click();
      setmodalT(false);

    } catch (err) {
      setmodalT(false);
    }

  };

  const handle_delete_bon_dev  = async (id) => {
    setmodalT(true);
    try {
      const dt = await delete_bon_dev(id);
      console.log(dt);
      alert("Éxito, Bonificación eliminada correctamente");
      props.handleReconsulta(true);
      setmodalT(false);
    } catch (err) {
      catchErrorModul("Corte de Caja => Eliminar Bonificación",JSON.stringify({}),JSON.stringify( err?.response));
      alert("Error.," + JSON.stringify(err?.response?.data));
      setmodalT(false);
    }

  };

  

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: "Acciones",
        Cell: ({ row }) => {
          return (
            <div>
              <img
                alt=""
                title="Facturar bonificación/devolución"
                style={{display:row.original.folio_invoice === null? row.original.partner_type === "PROPIO" ? props.invocedata !== true ? "" : "none" : "none" : "none"}}
                src={INVOICE}
                className="withImg"
                onClick={async () => {
                  if (sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                    const result = await Confirm("¿Esta usted seguro de facturar esta venta con devolucion?","Facturar Venta con devolucion","Si","No");
                    if (result) {handelGenerateFactureReturn(row.original.id)} else {}
                  } else {alert("No tienes permisos para facturar")}
                }}
              ></img>
              <img
                alt=""
                style={{display: row.original.id_invoice_fk !== null ? "" : "none"}}
                title="Descargar PDF"
                src={PDF}
                onClick={async () => {
                  const result = await Confirm("¿Esta usted seguro de descargar esta factura?","Descargar Facrtura","Si","No");
                  if (result) {handleDonwload("pdf",row.original.id_invoice_fk,row.original.ticket);} else {}
                }}
              ></img>
               <img
              alt=""
                src={VIEW}
                title="Detalles de bonifición/devolución"
                onClick={async () => {
                  // eslint-disable-next-line no-unused-vars
                  const result = await CustomDialog(
                    <CONSULT_DETAIL_SALE id_sale={row.original.id} typeSale={"RETURN"}></CONSULT_DETAIL_SALE>,
                    {
                      className: "cutBoxModal2",
                      title: "Detalles de venta",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
               
                }}
              ></img>
              <img
                alt=""
                src={DELETE}
                style={{display:row.original.type==="BONIFICACION"?"":"none"}}
                title="Eliminar Bonificación"
                className="withImg"
                onClick={async () => {
                  if (sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                    const result = await Confirm("¿Esta usted seguro de eliminar esta Bonificación?","Eliminar Bonificación","Si","No");
                    if (result) {handle_delete_bon_dev(row.original.id)} else {}
                  } else {alert("No tienes permisos para facturar")}
                }}
              ></img>
            </div>
          );
        },
      },
  
      {
        Header: "TIpo",
        accessor: "type",
      },
      {
        Header: "Ticket",
        accessor: "ticket",
      },
      
      {
        Header: "Cliente",
        accessor: "name_pos",
      },

      {
        Header: "Fecha",
        accessor: "date",
      },
      {
        Header: "Tipo",
        accessor: "saletype",
      },
      {
        Header: "Sub Total",
        accessor: "subtotal",
      },
      {
        Header: "Descuentos",
        accessor: "discount",
      },
      {
        Header: "Impuestos",
        accessor: "tax",
      },
      {
        Header: "Total",
        accessor: "total",
      },

      {
        Header: "Vendedor",
        accessor: "name_person_user",
      },
      {
        Header: "N. Crédito",
        accessor: "pagado",
      },
      {
        Header: "Credito",
        accessor: "folio_invoice",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <MODAL_TABLE open={true} message={""} modalT={modalT}></MODAL_TABLE>

      <div className="TableConsultarTodo2">
        <SubTableContainer
          columns={columns}
          data={data}
          style={{ overflowx: 10 }}
        />
      </div>
    </div>
  );
};

export default CONSULT_RETURN_CASHCLOSING;
