import { useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
//COMPONENTES
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { put_semaphore } from "../../../services/reports/reports";
import { post_semaphore } from "../../../services/reports/reports";


function OPERATION_SEMAPHORE(props) {

 
  const dialog = useDialog();
  const[bandera, setbandera]=useState(true);
  
  const [color, setcolor]=useState(props.operation==="EDIT"?props.row.original.color_hex:null);
  const [max, setmax]=useState(props.operation==="EDIT"?props.row.original.max:0.0);
  const [min, setmin]=useState(props.operation==="EDIT"?props.row.original.minimum:0.0);
  
  
  //crear un nuevo Rol
  const updatesemaphore = async () =>{
  
    if(color===null){
      props.alertas("No ha seleccionado color", false);
      return null;
    }    
  props.reconsultar(true);
  setbandera(false);
    var data = {
      "color_hex":color, //String = Indica el color en hexadecimal	
      "max": max,//Float = Indica el maximo valor
      "minimum": min,//Float = Indica el minimo valor
  }
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const dt = await put_semaphore(props.row.original.id, data);
            console.log(dt);
            props.alertas("Semaforo actualizado con éxito", true);
            props.reconsultar(false);
            dialog.close();
            props.handleSubmit();
          }catch(err){
            console.log(err);
            catchErrorModul("Semáforo", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            setbandera(true);
            props.reconsultar(false);
          }
        }, 1000);
      }else{}
    } catch (err) {  
        console.log(err);
    };  
  } 

  
const createsemaphore = async () => {
    if(color===null){
      props.alertas("No ha seleccionado color", false);
      return null;
    }
    props.reconsultar(true);
    setbandera(false);
      var data = {
        "color_hex":color, //String = Indica el color en hexadecimal	
        "max": max,//Float = Indica el maximo valor
        "minimum": min,//Float = Indica el minimo valor
    }
      try {
    
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const dt = await post_semaphore(data);
              console.log(dt);
              props.alertas("Semáforo creado con éxito", true);
              props.reconsultar(false);
              dialog.close();
              props.handleSubmit();
            }catch(err){
              console.log(err);
              catchErrorModul("Semáforo", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
              console.log("error post", err);
              setbandera(true);
              props.reconsultar(false);
            }
          }, 1000);
        }else{}  
      } catch (err) {  
        console.log(err);
      };
    } 


  function Spinner() {
    return (
      <div className="spinner-border spinner-border-sm text-light" role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    );
  }
  
    return (
      <div>
        <div className="addpointsalecss">
          <div className="grid-semaphore">
          <div className="semaphore1">
            <TextField
              type="color"
              value={color}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcolor(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
           </div>
            <div className="semaphore2">
            <TextField
                label="Cantidad Mínima"
                type={"number"}
                value={min}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setmin(event.target.value.slice(0,9))}  
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>   
            <div className="semaphore3">
            <TextField
                label="Cantidad Máxima"
                type={"number"}
                value={max}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setmax(event.target.value.slice(0,9))}  
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>   
               
          </div> 
          <br></br>
          <br></br>
            <Box>
              {
                bandera===true?
              <Button
              variant="contained"
              style={{"background-color":"green"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={()=>props.operation==="EDIT"?updatesemaphore():createsemaphore()}
            >
              {props.operation==="EDIT"?"Actualizar Semaforo":"Agregar Semaforo"}
            </Button>:
            <Button
            variant="contained"
            disabled
            style={{"background-color":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "30ch" }}
          >
            <Spinner></Spinner>
          </Button>
              }
              <Button
                style={{"background-color":"red"}}
                variant="contained"
                sx={{ m: 1.5, width: "20ch" }}
                disabled={!bandera}
                onClick={() => {
                    dialog.close();
                  } 
                }
              >
              cancelar
            </Button>
            </Box>
        </div>
      </div>
    );
  }

export default OPERATION_SEMAPHORE;
