import { useEffect, useMemo, useState } from 'react'
import TableContainer from '../../../main_components/Table/TableContainer';
import { StickyTable } from "react-sticky-table";
import { get_cabinets_movement } from '../../../services/assets/assets';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';

export const HistoryFrezz = (props) => {

    const [data, setdata]=useState([]);
    const [open, setopen]=useState(false);

useEffect(() => {
    handlemovement();
}, [])

const handlemovement = async () => {
  setopen(true);
  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        var d = await get_cabinets_movement(props.id);
        setdata(d === null ? [] : d.data.movements);
        setopen(false);
      }, 1000);
    }else{}
  }catch(error){
    console.log("error", error);
    setopen(false);
  }
}


const columns = useMemo(
    () => [
      {
        Header: "Tipo de Movimiento",
        accessor : "movement_type"
      },
      {
        Header: "Fecha Movimiento",
        accessor: "date_movement"
      },
      {
        Header: "Almacén",
        accessor: "warehouse_name",
      },
      {
        Header: "Punto de Venta",
        accessor: "name_pos"
      },
      {
        Header: "Empleado",
        accessor: "name_employee"  
      },
      {
        Header: "Nota",
        accessor: "note"
      }
    ]
)
  return (
    <div className="subtablehistoryfrez">
            <StickyTable>
              <TableContainer
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                Gmodal={open}
                consultas={"Movimientos Consultados"}
                paginado={"Modelos"}
              />
            </StickyTable>
    </div>
    
  )
}
