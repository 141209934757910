import axios from "axios";
import config from "../../config";

const urlbp = `${config?.baseURLFINANCE}`;

////////////////////M A R C A S ///////////////////////////

//consultar todas las cuentas contables
export const get_accountingA = async () => {
  const url = `${urlbp}/dev/accounting-account`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//ELIMINAR cuenta contable
export const delete_accountingA = async (id) => {
  const url = `${urlbp}/dev/accounting-account/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Crear CUENTA CONTABLE
export const post_accountingA = async (data) => {
  const url = `${urlbp}/dev/accounting-account`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//actualizar cuenta contable
export const put_accountingA = async (id, data) => {
  const url = `${urlbp}/dev/accounting-account/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
