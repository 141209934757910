import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Spinner from "../../../../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { update_expenses_groups_detail } from "../../../../../services/expenses/expenses";
import "../../../../expense.css";
import { refresh_token } from "../../../../../main_components/tokens/tokenrefresh";
import MODAL_TABLE from "../../../../../main_components/modal/modal_Table";
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValueType = [
  { value: "INGRESO", label: "INGRESO" },
  { value: "EGRESO", label: "EGRESO" },
];

const UPDATE_POLICY = ({ codCocept, codType, codId, codAction, date }) => {
  const dialog = useDialog();
  const [open, setOpen] = useState(false);
  const [codTypePolicy, setcodTypePolicy] = useState(codType);
  const [codConceptPolicy, setcodConcepPolicy] = useState(codCocept);
  const [ff, setff] = useState(date);

  const handelConcept = (event) => {
    setcodConcepPolicy(event?.target?.value);
  };
  const handelType = (event) => {
    setcodTypePolicy(event?.target?.value);
  };

  var data = {
    policy: {
      concept: codConceptPolicy,
      type: codTypePolicy,
      export_date: ff,
    },
  };

  console.log(data);

  const handle_update_expenses_groups_detail = async (event) => {
    event?.preventDefault();

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        var fechaI = ff?.split(" ");
        var data = {
          policy: {
            concept: codConceptPolicy,
            type: codTypePolicy,
            export_date: codAction === "INJECTION" ? ff : fechaI[0],
          },
        };
        setOpen(true);
        try {
          await update_expenses_groups_detail(
            data,
            sessionStorage.getItem("IdGrupExpense"),
            codId,
            codAction
          );
          setOpen(false);
          dialog.close(true);
        } catch (err) {
          setOpen(false);
          alert("Error.," + err?.response?.data?.errorMessage);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>

      <div>
        <form>
          <div style={{ display: codAction === "UPDATE" ? "" : "none" }}>
            <Box>
              <TextareaAutosize
                label="Descripciónn"
                variant="outlined"
                aria-label="empty textarea"
                value={codConceptPolicy}
                placeholder="Descripción"
                className="TexAreaAutosize"
                onChange={handelConcept}
              />
            </Box>

            <div className="generarExpense">
              <div className="expen-6">
                <input
                  type="text"
                  className="fExpense2"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder={ff}
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>
              <div className="expen-7">
                <TextField
                  select
                  label="Tipo"
                  value={codTypePolicy}
                  sx={{ m: 1.5, width: "94%" }}
                  onChange={handelType}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {ValueType.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      selected={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>
          </div>

          <br></br>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handle_update_expenses_groups_detail}
            >
              {codAction === "UPDATE" ? "ACTUALIZAR" : "INYECTAR"}
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default UPDATE_POLICY;
