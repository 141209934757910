import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Spinner from "../../../../imgComponents/S.gif";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../../../expense.css";
import { create_expense_group } from "../../../../services/expenses/expenses";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const GENERATE_EXPENSE_GROUP = ({ grupExpense }) => {
  const [open, setOpen] = useState(false);
  const dialog = useDialog();

  const handleSubmit = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        const result = grupExpense?.filter(
          (idExpense) => idExpense?.id !== "elminarEsto"
        );
        var data = { expense: result };

        setOpen(true);

        try {
          await create_expense_group(data);
          setOpen(false);
          dialog.close(true);
          window.location = "/expensesReport";
        } catch (error) {
          console.log(error);
          setOpen(false);
          dialog.close(true);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img alt="" src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {"Agrupando gastos..."}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
      <div></div>
    </div>
  );
};

export default GENERATE_EXPENSE_GROUP;
