//PAQUETERIAS
import { useEffect, useState, useMemo, useRef} from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";

import AlertResponse from "../../main_components/alerts/alertResponse";
import { SelectColumnFilter } from "../../components_cfdi/filters";
//ESTILOS
import "../../components_cfdi/estilo.css";
import './promotions.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import CreatePromotions from "./utilities/CreatePromotions";
import EditPromotion from "./utilities/EditPromotion";
import SubTable from "./subTable";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { delete_promotion, get_promotions } from "../../services/businesspartners/businesspartners";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";


const TablePromotions = () => {
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  const notificationSystemRef = useRef();
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(()=>{
    handleSubmit();
  },[]);

  const reconsultar=(n)=>{
    if(n===true){
      setmodalT(true);  
    }else{
      setmodalT(false);
    } 
  }
  function alertas(msj,status){

    if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br', 
        autoDismiss: 60, 
      });
    }  
  }

//proceso de consultar todas las promociones
const handleSubmit = async () => {
    console.log("entra a consultar todas las promociones");
    if(entrada===0){
      setmenssage("Buscando Promociones...")
      setmodalGeneral(true);
      setmodalT(true); 
      entrada=entrada+1;
    }else{
      setmodalT(true);
    }  
    setData([]);
    setopen(true);
    try {
      var rf_token = await refresh_token();
      if(rf_token === true){
        setTimeout(async() => {
          try{
            var d = await get_promotions();
            setData(d === null ? [] : d.data.promotions); 
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);     
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    } catch (error) {
    }
};

const columns = useMemo(
  () => [
    { Header:()=>(
      <div>
        <img
          id="crearsector"
          src={CREATE}
          onClick={async () => {
            const result = await CustomDialog(               
           <div>
            <CreatePromotions handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreatePromotions>
           </div>,
         {
           className: "modalpuntosventa",
           title:"Crear nueva promoción",
           showCloseIcon: true,
           isCanClose:false,
         }
     );
   }}
          title="Crear Promoción"
          alt=""
        ></img>
      </div>
    ),
    id: "Expand",
    Cell: ({ row }) => {  

      function guardardato(){
        console.log(row.original.id);
        sessionStorage.setItem("id_detalles",row.original.id); 
        sessionStorage.setItem("datospromos",row.original.promo_name); 
      }

      return(
        <a {...row.getToggleRowExpandedProps()}>
          {row?.isExpanded ? <span title="Ocultar Detalles">🔼</span>: <span title="Mostrar Detalles" onClick={guardardato}>🔽</span>}
        </a>
        )
      },
    },
    {
      Header:"Acciones",
      Cell: ({ row }) => {

      const DeletePromotion = async () => {
      
      setmodalT(true);
      setData([]);
      setopen(true);
      try {
        var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                const dt = await delete_promotion(row.original.id);
                console.log(dt);
                alertas("Promoción Eliminada", true);
                handleSubmit();  
              }catch(err){
                console.log("error", err);
                setopen(false);
                setmodalT(false);
                alertas("No se pudo eliminar la promoción, vuelva a intentarlo", false);
                handleSubmit();
              }     
            }, 1000);
          }else{}
        } catch (err) {
          console.log(err);
        }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar la promoción?','Eliminar Promoción','Si','No');
                  if(result){
                    DeletePromotion()
                    }
                  }            
                }
              className="cursorPointer"
              title="Eliminar Promoción"
              alt=""
            ></img>
             
            <img
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                  <EditPromotion handleSubmit={handleSubmit} row={row} reconsultar={reconsultar} alertas={alertas}></EditPromotion>
                 </div>,
               {
                 className: "modalpuntosventa",
                 title:"Editar Promoción",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              className="cursorPointer"
              title="Editar Promoción"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Código",
      accessor: "promo_code",
    },
    {
      Header: "Nombre de Promoción",
      accessor: "promo_name",
    },
    {
      Header: "Tipo de Socio",
      accessor: "type_business_partner",
      Filter: SelectColumnFilter,
    },
    {
      Header:"Descuento",
      accessor: "customer_with_discount"
    },
    {
      Header: "Inicia",
      accessor: "initial_date_promo"
    },
    {
      Header: "Finaliza",
      accessor: "final_date_promo"
    },
    {
      Header:"Importe",
      accessor: (d) => {
        return d.amount_required
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.amount_required)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.amount_required);
      },    
    },
    {
      Header: "Disponible",
      Filter: SelectColumnFilter,
      accessor: (d) => {
        return d.enable === true?"Disponible":"No Disponible"
         },
    }
    ],
    []
);
  return (
    <>  
  <NotificationSystem  
    ref={notificationSystemRef} 
  ></NotificationSystem>

  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    <BREADCRUMBS niveles={
            [
              {label:"CONSULTAR PROMOCIONES", path: null}, 
            ]
          }
          ></BREADCRUMBS>
      <div className="TablePromotions">
          <div className="tablepromop">           
          <>
          <StickyTable>
            <TableContainer
              paginado={"Promociones"}
              consultas={"Promociones Consultadas"}
              nametable={"Promociones"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              renderRowSubComponent={row => {
                return (
                  <div style={{ padding: "20px" }}>
                    <div className="prodpromotionsubtable">
                    <SubTable
                      reconsultar={reconsultar}
                      alertas={alertas}
                      data={data}
                      columns={columns}
                      defaultPageSize={3}
                      showPagination={false}
                    />
                    </div>
                
                  </div>
                );
              }}
              Gmodal={open}
            />
          </StickyTable>
        </>             
          </div>
        </div>
     
    </>
  );
}

export default TablePromotions;
