import axios from "axios";

export function catchErrorModul(modulo, json, erroback) {
  var id_user = sessionStorage.getItem("id_user");
  var roles_user = sessionStorage.getItem("roles_user");
  var email_uset = sessionStorage.getItem("email_uset");

  const tokenId = sessionStorage.getItem("getIdTokenRefresh");
  const tokenAcces = sessionStorage.getItem("getAccesTokenRefresh");

  const expirationDate = new Date(0);
  expirationDate.setUTCSeconds(JSON.parse(atob(tokenId.split(".")[1])).exp);
  const expirationDateAcces = new Date(0);
  expirationDateAcces.setUTCSeconds(
    JSON.parse(atob(tokenAcces.split(".")[1])).exp
  );

  console.log(expirationDate);

  var nresponse = erroback;
  console.log(nresponse);

  if (JSON.parse(erroback) === "Token expirado") {
    var cadenaNueva = erroback;
    console.log("entra 1");
  } else {
    console.log("entra 2");
    var cadenaNueva = JSON.stringify(nresponse);
    console.log(cadenaNueva);
  }

  var mensajeEmail =
    "Id_usuario:" +
    id_user +
    "<br/>" +
    "-----------------------" +
    "<br/>" +
    "Roles:" +
    roles_user +
    "<br/>" +
    "-----------------------" +
    "<br/>" +
    "Email:" +
    email_uset +
    "<br/>" +
    "-----------------------" +
    "<br/>" +
    "Módulo de error:" +
    modulo +
    "<br/>" +
    "-----------------------" +
    "<br/>" +
    "Json FrontEnd:" +
    json +
    "<br/>" +
    "-----------------------" +
    "<br/>" +
    "Respuesta BackEnd:" +
    cadenaNueva;

  var data = {
    to: "yordi.garcia@herramientasinformaticas.com",

    subject: "Error en el módulo:" + modulo + " de SIMA",

    message: mensajeEmail,

    email_type: "SOPORTE",
  };

  console.log(data);
  axios
    .post(
      "https://1yx84i8mj4.execute-api.us-east-1.amazonaws.com/prod/email",
      data
    )
    .then((res) => {
      console.log("RESPONSE RECEIVED: ", res);
    })

    .catch((err) => {
      // console.log("AXIOS ERROR: ", err);
    });
}
