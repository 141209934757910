//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

//COMPONENTES
import TableContainer from "../../Table_Container_Cashclosing";
import CONSULT_RETURN_CASHCLOSING from "../cahsclosing_sale/detail/consult_return_cashclosing";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { base64toBlob } from "../../../../main_components/donwloadfuncion/download";
import { SelectColumnFilter } from "../../../../components_cfdi/filters";
import { MaterialUISwitch } from "../../../../main_components/material_switch/switch";
import CONSULT_DETAIL_SALE from "../cahsclosing_sale/detail/consult_detail_sale";
import { download_invoice } from "../../../../services/invoices/invoice";

//ESTILOS
import "../../../../components_cfdi/estilo.css";
import "../../cashclosing.css";
import "../../../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import PDF from "../../../../imgComponents/pdf.png";
import VIEW from "../../../../imgComponents/lupa.png";
import { BREADCRUMBS } from "../../../../main_components/pagination/breadcrumbs";



const Consult_cut_box_sale_report = ({ route, datei, datef }) => {
  const [data, setData] = useState([]);
  const [dataSinFil, setdataSinFil] = useState([]);
  const [filterVPCo, setfilterVPCo] = useState(false);
  const [filterVPCr, setfilterVPCr] = useState(false);
  const [filterVSCo, setfilterVSCo] = useState(false);
  const [filterVSCr, setfilterVSCr] = useState(false);
  const [filterVO, setfilterVO] = useState(false);
  const [menssage, setMenssage] = useState("");
  const [ticketS, setticketS] = useState("");
  const [modalT, setmodalT] = useState(true);
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const [filtersColumsView2, setfiltersColumsView2] = useState(true);

  const [viewPV, setviewPV] = useState(localStorage.getItem("viewPV")===null?true:localStorage.getItem("viewPV")==="true"?true:false);
  const [viewSN, setviewSN] = useState(localStorage.getItem("viewSN")===null?true:localStorage.getItem("viewSN")==="true"?true:false);
  const [viewF, setviewF] = useState(localStorage.getItem("viewF")===null?true:localStorage.getItem("viewF")==="true"?true:false);
  const [viewTp, setviewTp] = useState(localStorage.getItem("viewTp")===null?true:localStorage.getItem("viewTp")==="true"?true:false);
  const [viewSt, setviewSt] = useState(localStorage.getItem("viewSt")===null?true:localStorage.getItem("viewSt")==="true"?true:false);
  const [viewDesc, setviewDesc] = useState(localStorage.getItem("viewDesc")===null?true:localStorage.getItem("viewDesc")==="true"?true:false);
  const [viewImp, setviewImp] = useState(localStorage.getItem("viewImp")===null?true:localStorage.getItem("viewImp")==="true"?true:false);
  const [viewT, setviewT] = useState(localStorage.getItem("viewT")===null?true:localStorage.getItem("viewT")==="true"?true:false);
  const [viewAd, setviewAd] = useState(localStorage.getItem("viewAd")===null?true:localStorage.getItem("viewAd")==="true"?true:false);
  const [viewPgd, setviewPgd] = useState(localStorage.getItem("viewPgd")===null?true:localStorage.getItem("viewPgd")==="true"?true:false);
  const [viewPg, setviewPg] = useState(localStorage.getItem("viewPg")===null?true:localStorage.getItem("viewPg")==="true"?true:false);
  const [viewCd, setviewCd] = useState(localStorage.getItem("viewCd")===null?true:localStorage.getItem("viewCd")==="true"?true:false);
  const [viewVd, setviewVd] = useState(localStorage.getItem("viewVd")===null?true:localStorage.getItem("viewVd")==="true"?true:false);
  const [viewCp, setviewCp] = useState(localStorage.getItem("viewCp")===null?true:localStorage.getItem("viewCp")==="true"?true:false);
  const [viewFc, setviewFc] = useState(localStorage.getItem("viewFc")===null?false:localStorage.getItem("viewFc")==="true"?true:false);
  const [viewGd, setviewGd] = useState(localStorage.getItem("viewGd")===null?false:localStorage.getItem("viewGd")==="true"?true:false);
  const [viewOp, setviewOp] = useState(localStorage.getItem("viewOp")===null?false:localStorage.getItem("viewOp")==="true"?true:false);
  const [viewNf, setviewNf] = useState(localStorage.getItem("viewNf")===null?false:localStorage.getItem("viewNf")==="true"?true:false);
  const [viewVc, setviewVc] = useState(localStorage.getItem("viewVc")===null?false:localStorage.getItem("viewVc")==="true"?true:false);
  const [viewTikect, setviewTikect] = useState(localStorage.getItem("viewTikect")==="null"?true:localStorage.getItem("viewTikect")==="true"?true:false);
console.log(viewVc);

 

  useEffect(()=>{

    localStorage.setItem("viewTikect",viewTikect);
    localStorage.setItem("viewPV",viewPV);
    localStorage.setItem("viewSN",viewSN);
    localStorage.setItem("viewF",viewF);
    localStorage.setItem("viewTp",viewTp);
    localStorage.setItem("viewSt",viewSt);
    localStorage.setItem("viewDesc",viewDesc);
    localStorage.setItem("viewImp",viewImp);
    localStorage.setItem("viewT",viewT);
    localStorage.setItem("viewAd",viewAd);
    localStorage.setItem("viewPgd",viewPgd);
    localStorage.setItem("viewPg",viewPg);
    localStorage.setItem("viewCd",viewCd);
    localStorage.setItem("viewVd",viewVd);
    localStorage.setItem("viewCp",viewCp);
    localStorage.setItem("viewFc",viewFc);
    localStorage.setItem("viewGd",viewGd);
    localStorage.setItem("viewOp",viewOp);
    localStorage.setItem("viewNf",viewNf);
    localStorage.setItem("viewVc",viewVc);

  },[ viewTikect,
    viewPV,
    viewSN,
    viewF,
    viewTp,
    viewSt,
    viewDesc,
    viewImp,
    viewT,
    viewAd,
    viewPgd,
    viewPg,
    viewCd,
    viewVd,
    viewCp,
    viewFc,
    viewGd,
    viewOp,
    viewNf,
    viewVc,
    filterVPCo,
    filterVPCr,
    filterVSCo,
    filterVSCr]);

    
  //consulta todas las ventas


  const handleSubmit = (event) => {
    event?.preventDefault();
    var data = JSON.parse(sessionStorage.getItem("cutBoxReport")).filter(
      (d) => d.id !== undefined
    );
    console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
    console.log(data);
    console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

    var filter = sessionStorage.getItem("FilterData");
    console.log(filter);

    setData(data);
    setdataSinFil(data);

    setmodalT(false);
    setmodalGeneral(false);
  };
  
 
  //Reconsulta todas las ventas
  const handleReconsulta = (result) => {
    console.log(result);
    if (result === false) {
    } else {
      handleSubmit();
    }
  };
  //Reconsulta todas las ventas
  const handleFIlterSalePrimaryC = () => {
    const noObsequio = dataSinFil.filter(
      (sale) => sale.saletype !== "OBSEQUIO"
    );
    const ventaPrimaria = noObsequio
      .filter((sale) => sale.generate_debt_pos === true)
      .filter((sale) => sale.partner_type === "PROPIO");
    const vPContado = ventaPrimaria.filter(
      (sale) => sale.pay_condition_pos === "CONTADO"
    );
    if (data.length !== vPContado.length) {
      setData(vPContado);
      setfilterVPCo(true);
      setfilterVPCr(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    } else {
      setData(dataSinFil);
      setfilterVPCo(false);
      setfilterVPCr(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    }
  };
  const handleFIlterSalePrimaryCredit = () => {
    const noObsequio = dataSinFil.filter(
      (sale) => sale.saletype !== "OBSEQUIO"
    );
    const ventaPrimaria = noObsequio
      .filter((sale) => sale.generate_debt_pos === true)
      .filter((sale) => sale.partner_type === "PROPIO");
  
    if (data.length !== ventaPrimaria.length) {
      setData(ventaPrimaria);
      setfilterVPCr(true);
      setfilterVPCo(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    } else {
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
      setData(dataSinFil);
    }
  };
  const handleFIlterSaleSecundaryC = () => {
    const noObsequio = dataSinFil.filter(
      (sale) => sale.saletype !== "OBSEQUIO"
    );
    const ventaPrimaria = noObsequio
      .filter((sale) => sale.generate_debt_pos === false)
      .filter((sale) => sale.partner_type !== "PROPIO");
    const vPContado = ventaPrimaria.filter(
      (sale) => sale.pay_condition_pos === "CONTADO"
    );

    const ventaPrimaria2 = noObsequio
      .filter((sale) => sale.generate_debt_pos === true)
      .filter((sale) => sale.partner_type === "EXTERNO");
    const vPContado2 = ventaPrimaria2.filter(
      (sale) => sale.pay_condition_pos === "CONTADO"
    );
    const factureAll = vPContado.concat(vPContado2);

    if (data.length !== factureAll.length) {
      setData(factureAll);
      setfilterVSCo(true);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    } else {
      setData(dataSinFil);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    }
  };
  const handleFIlterSaleSecundaryCredit = () => {
    const noObsequio = dataSinFil.filter(
      (sale) => sale.saletype !== "OBSEQUIO"
    );
    const ventaPrimaria = noObsequio
      .filter((sale) => sale.generate_debt_pos === false)
      .filter((sale) => sale.partner_type !== "PROPIO");
    const vPContado = ventaPrimaria.filter(
      (sale) => sale.pay_condition_pos !== "CONTADO"
    );

    const ventaPrimaria2 = noObsequio
      .filter((sale) => sale.generate_debt_pos === true)
      .filter((sale) => sale.partner_type === "EXTERNO");
    const vPContado2 = ventaPrimaria2.filter(
      (sale) => sale.pay_condition_pos !== "CONTADO"
    );
    const factureAll = vPContado.concat(vPContado2);

    if (data.length !== factureAll.length) {
      setData(factureAll);
      setfilterVSCr(true);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVO(false);
    } else {
      setData(dataSinFil);
      setfilterVSCr(false);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVO(false);
    }
  };
  const handle_obsequio = () => {
    const obsequio = dataSinFil.filter(
      (sale) => sale.saletype === "OBSEQUIO"
    );

    if (data.length !== obsequio.length) {
      setfilterVO(true);
      setfilterVSCr(false);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setData(obsequio);
    } else {
      setfilterVO(false);
      setfilterVSCr(false);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setData(dataSinFil);
    }
  };


  //Descargar PDF
  const handle_donwload  = async (typedocumentexmlorpdf, id_invoce, ticket) => {
    setmodalT(true);
    try {
      const data = await download_invoice(typedocumentexmlorpdf,null,id_invoce);
      var dt = data.data.file;
      var blob = base64toBlob(dt);
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      var arr = ticket;
      downloadLink.download = arr + `.${typedocumentexmlorpdf}`;
      downloadLink.click();
      setmodalT(false);
    } catch (err) {
      setmodalT(false);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);
  useEffect(() => {
    setviewTikect(viewTikect);
  }, []);
  useEffect(() => {
    setticketS(ticketS);
  }, []);

 
  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
    console.log(event.target.checked);
  };

  const handlechecked2 = (event) => {
    setfiltersColumsView2(event.target.checked);
    console.log(event.target.checked);
  };
  
  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ".......",
        Cell: ({ row }) => {
          function guardardato() {
            setticketS(row.original.returns);
            sessionStorage.setItem(
              "TikectconsulReturn",
              JSON.stringify(row.original.returns)
            );
          }
          return (
            <div>
              <a
                title="Consultar impuestos del concepto"
                {...row.getToggleRowExpandedProps()}
                style={{
                  display: row.original.returns?.length > 0 ? "" : "none",
                }}
              >
                {row?.isExpanded ? (
                  <span title="Consultar detalles">🔼</span>
                ) : (
                  <span title="Consultar detalles" onClick={guardardato}>
                    🔽
                  </span>
                )}
              </a>

              <img
              alt=""
                style={{
                  display: row.original.id_invoice_fk !== null ? "" : "none",
                }}
                src={PDF}
                title="Descargar PDF"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de descargar esta factura?",
                    "Descargar Facrtura",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_donwload("pdf",row.original.id_invoice_fk,row.original.ticket);
                  } else {
                  }
                }}
              ></img>

              <img
                alt=""
                src={VIEW}
                title="Consultar venta"
                onClick={async () => {
                  const result = await CustomDialog(
                    <CONSULT_DETAIL_SALE
                      id_sale={row.original.id}
                      typeSale={"SALE"}
                    ></CONSULT_DETAIL_SALE>,
                    {
                      className: "cutBoxModal2",
                      title: "Consultar ventas",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(result);
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        id: "tikect",
        show: viewTikect,
        Header: "Ticket",
        accessor: "ticket",
      },
      {
        id: "punto_de_venta",
        show: viewPV,
        Header: "Punto de venta",
        accessor: "name_pos",
      },
      {
        id: "socio_de_negocios",
        show: viewSN,
        Header: "Socio de Negocios",
        accessor: "name_business_partner",
        Filter: SelectColumnFilter,
      },
      {
        id: "fecha",
        show: viewF,
        Header: "Fecha",
        accessor: "date",
      },
      {
        id: "tipo",
        show: viewTp,
        Header: "Tipo",
        accessor: "saletype",
        Filter: SelectColumnFilter,
      },
      {
        id: "sub_total",
        show: viewSt,
        Header: "Sub Total",
        accessor: (d) => {
          return d.subtotal?.toFixed(2);
        },
      },
      {
        id: "descuentos",
        show: viewDesc,
        Header: "Descuentos",
        accessor: (d) => {
          return d.discount?.toFixed(2);
        },
      },
      {
        id: "impuestos",
        show: viewImp,
        Header: "Impuestos",
        accessor: (d) => {
          return d.tax?.toFixed(2);
        },
      },
      {
        id: "total",
        show: viewT,
        Header: "Total",
        accessor: (d) => {
          return d.total?.toFixed(2);
        },
        // Footer:     (state) => {
        //   const suma = state.data.reduce(
        //     (total, d) => total + d.total,
        //     0
        //   );
        //   console.log(state);
        //   return <div>Total: {suma.toFixed(2)}</div>;
        // }
      },

      {
        id: "adeudo",
        show: viewAd,
        Header: "Adeudo",
        accessor: (d) => {
          return d.debt?.toFixed(2);
        },
      },
      {
        id: "pagado",
        show: viewPgd,
        Header: "Pagado",
        accessor: (d) => {
          return d.payed?.toFixed(2);
        },
      },
      {
        id: "pago",
        show: viewPg,
        Header: "Pago",
        accessor: "payment_method",
      },
      {
        id: "credito",
        show: viewCd,
        Header: "Crédito",
        accessor: "pay_condition_pos",
      },

      {
        id: "vendedor",
        show: viewVd,
        Header: "Vendedor",
        accessor: "name_person_user",
      },
      {
        id: "completa",
        show: viewCp,
        Header: "Completa",
        Filter: SelectColumnFilter,
        accessor: (d) => {
          return d.complete === false ? "❌" : "✅";
        },
      },
      {
        id: "factura",
        show: viewFc,
        Header: "Factura",
        accessor: "folio_invoice",
      },

      {
        id: "gen_deuda",
        show: viewGd,
        Header: "Gen. Deuda",
        accessor: (d) => {
          return d.generate_debt_pos === false ? "NO" : "SI";
        },
      },
      {
        id: "oportuno",
        show: viewOp,
        Header: "Oportuno",
        accessor: (d) => {
          return d.no_invoice === true ? "SI" : "NO";
        },
      },

      {
        id: "nunca facturar",
        show: viewNf,
        Header: "Nunca facturar",
        accessor: (d) => {
          return d.never_invoice === true ? "SI" : "NO";
        },
      },

      {
        id: "venta_cerrada",
        show: viewVc,
        Header: "Venta Cerrada",
        accessor: (d) => {
          return d.close === true ? "SI" : "NO";
        },
      },
      {
        Header: "TIpo de socio",
        accessor: "partner_type",
        Filter: SelectColumnFilter,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      viewTikect,
      viewPV,
      viewSN,
      viewF,
      viewTp,
      viewSt,
      viewDesc,
      viewImp,
      viewT,
      viewAd,
      viewPgd,
      viewPg,
      viewCd,
      viewVd,
      viewCp,
      viewFc,
      viewGd,
      viewOp,
      viewNf,
      viewVc,
      filterVPCo,
      filterVPCr,
      filterVSCo,
      filterVSCr,
    ]
  );

  return (
    <div className="">
      <BREADCRUMBS niveles={
                [
                  {label: "CORTE DE CAJA", path: "/cashclosing"},
                  {label: "DETALLES DE VENTAS", path: null}
                ]
              }
      ></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>

      <div>
        <h1>Liquidaciones de Rutas</h1>
      </div>

      <div
        className="contanerFIltersTableSelect"
        style={{ display: filtersColumsView === true ? "none" : "" }}
      >
        <div className="filter-1 color-1">
          <h2>Filtros Personalizados</h2>
        </div>
        <div className="filter-2 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewTikect}
                onChange={(e) => {
                  setviewTikect(e.target.checked);
                }}
              />
            }
            label="Ticket"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-3 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewPV}
                onChange={(e) => {
                  setviewPV(e.target.checked);
                }}
              />
            }
            label="Puntos de venta"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-4 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewSN}
                onChange={(e) => {
                  setviewSN(e.target.checked);
                }}
              />
            }
            label="Socio de negocios"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-5 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewF}
                onChange={(e) => {
                  setviewF(e.target.checked);
                }}
              />
            }
            label="Fecha"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-6 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewTp}
                onChange={(e) => {
                  setviewTp(e.target.checked);
                }}
              />
            }
            label="Tipo"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-7 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewSt}
                onChange={(e) => {
                  setviewSt(e.target.checked);
                }}
              />
            }
            label="Sub Total"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-8 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewDesc}
                onChange={(e) => {
                  setviewDesc(e.target.checked);
                }}
              />
            }
            label="Descuentos"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-9 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewImp}
                onChange={(e) => {
                  setviewImp(e.target.checked);
                }}
              />
            }
            label="Impuestos"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-10 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewT}
                onChange={(e) => {
                  setviewT(e.target.checked);
                }}
              />
            }
            label="Total"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-11 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewAd}
                onChange={(e) => {
                  setviewAd(e.target.checked);
                }}
              />
            }
            label="Adeudo"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-12 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewPgd}
                onChange={(e) => {
                  setviewPgd(e.target.checked);
                }}
              />
            }
            label="Pagado"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-13 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewPg}
                onChange={(e) => {
                  setviewPg(e.target.checked);
                }}
              />
            }
            label="Pago"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-14 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewCd}
                onChange={(e) => {
                  setviewCd(e.target.checked);
                }}
              />
            }
            label="Crédito"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-15 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewVd}
                onChange={(e) => {
                  setviewVd(e.target.checked);
                }}
              />
            }
            label="Vendedor"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-16 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewCp}
                onChange={(e) => {
                  setviewCp(e.target.checked);
                }}
              />
            }
            label="Completa"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-17 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewFc}
                onChange={(e) => {
                  setviewFc(e.target.checked);
                }}
              />
            }
            label="Factura"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-18 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewGd}
                onChange={(e) => {
                  setviewGd(e.target.checked);
                }}
              />
            }
            label="Genera deuda"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-19 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewNf}
                onChange={(e) => {
                  setviewNf(e.target.checked);
                }}
              />
            }
            label="Nunca Facturar"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-20 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={viewVc}
                onChange={(e) => {
                  setviewVc(e.target.checked);
                }}
              />
            }
            label="Venta Cerrada"
            labelPlacement="bottom"
          />
        </div>
      </div>

      <div
        className="contanerFIltersTableSelect"
        style={{ display: filtersColumsView2 === true ? "none" : "" }}
      >
        <div className="filter-1 color-1">
          <h2>Filtros de Busqueda</h2>
        </div>
        <div className="filter-2 color-1">
          <button
            style={{ width: "60%", height: "60px", background:"green"}}
            title="GUARDAR LIQUIDACION"
            className="button"
            onClick={handleFIlterSalePrimaryC}
          >
            VENTA PRIMARIA DE CONTADO
          </button>
        </div>
        <div className="filter-3 color-1">
          <button
            style={{ width: "60%", height: "60px", background:"green"}}
            title="GUARDAR LIQUIDACION"
            className="button"
            onClick={handleFIlterSalePrimaryCredit}
          >
            VENTA PRIMARIA DE CRÉDITO
          </button>
        </div>
        <div className="filter-4 color-1">
          <button
            style={{ width: "60%", height: "60px", background:"red"}}
            title="GUARDAR LIQUIDACION"
            className="button"
            onClick={handleFIlterSaleSecundaryC}
          >
            VENTA SECUNDARIA DE CONTADO
          </button>
        </div>
        <div className="filter-5 color-1">
          <button
            style={{ width: "60%", height: "60px", background:"red"}}
            title="GUARDAR LIQUIDACION"
            className="button"
            onClick={handleFIlterSaleSecundaryCredit}
          >
            VENTA SECUNDARIA DE CRÉDITO
          </button>
        </div>
        <div className="filter-6 color-1">
          <button
            style={{ width: "60%", height: "60px" }}
            title="GUARDAR LIQUIDACION"
            className="button"
            onClick={handle_obsequio}
          >
            VENTA OBSEQUIO
          </button>
        </div>
        <div className="filter-7 color-1">
          <button
            style={{ width: "60%", height: "60px" }}
            title="GUARDAR LIQUIDACION"
            className="button"
            onClick={handleFIlterSalePrimaryCredit}
          >
            VENTA PRIMARIA
          </button>
        </div>
        <div className="filter-8 color-1">
          <button
            style={{ width: "60%", height: "60px", background:"#ff9900"}}
            title="GUARDAR LIQUIDACION"
            className="button"
            onClick={handleReconsulta}
          >
            QUITAR FILTROS
          </button>
        </div>
      </div>

      <div className="containerCutBox">
        <div className="expen-1 color-1">
          <h1>Ventas</h1>
        </div>

        <div className="expen-2 color-1">
          <FormControlLabel
            control={
              <MaterialUISwitch
                sx={{ m: 1 }}
                onChange={handlechecked}
                checked={filtersColumsView}
              />
            }
            label={
              filtersColumsView === true
                ? "Activar Filtros de Columnas"
                : "Desactivar Filtros de Columnas"
            }
          />
        </div>
        <div className="expen-3 color-1">
          <FormControlLabel
            control={
              <MaterialUISwitch
                sx={{ m: 1 }}
                onChange={handlechecked2}
                checked={filtersColumsView2}
              />
            }
            label={
              filtersColumsView === true
                ? "Activar Filtros de Busqueda"
                : "Desactivar Filtros de Busqueda"
            }
          />
        </div>
      </div>


      

      <div className="TableReport TableSaleCutBox">
        <div style={{ overflowX: "auto" }}>
          <TableContainer
            exportEx={true}
            columns={columns}
            data={data}
            Gmodal={modalT}
            Gmodal2={modalT}
            pgd={viewPgd}
            imp={viewImp}
            desc={viewDesc}
            totalv={viewT}
            ad={viewAd}
            st={viewSt}
            TableStickyHeader={true}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <CONSULT_RETURN_CASHCLOSING
                    reportsale={true}
                    invocedata={true}
                    verData={true}
                    ticket={ticketS}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Consult_cut_box_sale_report;
