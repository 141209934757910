import React, { useState } from 'react'
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { cabinets_descapitalizar } from '../../../services/assets/assets';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { catchErrorModul } from '../../../main_components/catchErr/catchError';

export const ConfirmDesc = (props) => {
  
const dialog = useDialog();
console.log(props);
console.log(props.detalles.length);    

const [nota, setnote]=useState("");
const [vnota, setvnota]=useState(false);

const handledesc = async ()=>{

    if(nota===" "||nota===""){
        props.alertas("Falta asignar Nota", false);
    }else{
        var data = {
            cabinets: props.array,
            note:nota
        }
        console.log(data);
  
          props.reconsultar(true);
          await refresh_token(); 

          try {  
            var rf_token = await refresh_token();
              if(rf_token===true){
                setTimeout(async() => {
                  try{
                    var d = await cabinets_descapitalizar(data);
                    console.log(d);
                    props.setSelectedRows([]);
                    props.alertas("Congelador Descapitalizado", true);
                    props.reconsultar(false);
                    dialog.close();
                    props.handleSubmit();       
                  }catch(err){
                    catchErrorModul("Congeladores descapitalizacion", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
                    console.error(err);
                    props.reconsultar(false);
                    props.alertas("Error, no se pudo descapitalizar congeladores", false);
                  }    
                }, 1000);
              }else{}
            } catch (err) {
              console.log(err);
          } 
  }
}



return (
    <div className='container-confirm-desc'>
        <div className='descripcion-desc-confirm'>
            {
                props.detalles.length===1?
                <>
                <p>Número Económico: <b>{props.detalles[0]?.economic_number}</b> </p>
                <p>Activo FIjo: <b>{props.detalles[0]?.fixed_asset}</b></p>                
                <p>Número Serie: <b>{props.detalles[0]?.serial_number}</b></p>
                </>:
                <p>Congeladores a Descapitalizar: <b className='b-p'>{props.array.length}</b></p>
            }
           
        </div>

        <div className='nota-desc'>
        <TextField
              label="Nota"
              sx={{ m: 1.5, width: "100%" }}
              value={nota}
              onChange={(event) => setnote(event.target.value)}  
             InputLabelProps={{ shrink: true }}
             style={
                vnota===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvnota(false)}
              onBlur={
                nota===""?()=>setvnota(true):()=>setvnota(false)
              }
            ></TextField>
             <p className="p_alert" style={vnota===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        </div>


         <Box>
          <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={()=>handledesc()}
          >
            Continuar
          </Button>
          <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
    </div>
  )
}
