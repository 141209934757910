import Validador  from '../../components_Expenses/supplier/validador';

import { Component } from "react";

export class SetValidador extends Component {
 

  render() {


    

      return (    
      
      <div className="tc">
      <Validador></Validador>

      </div>
          );
       }     
}



export default SetValidador;
