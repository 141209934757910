import React, { useEffect, useState, useMemo, useRef} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {  useDialog } from "react-st-modal";
import { Checkbox } from "semantic-ui-react";
import { StickyTable } from "react-sticky-table";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_products, post_promotion, post_promotion_details } from "../../../services/businesspartners/businesspartners";
import TableContainer from "../../../main_components/Table/TableContainer";


function CreatePromotions(props) {

  const dialog = useDialog();
  const notificationSystemRef = useRef();
  
  const[namepromotion, setnamepromotion] = useState(null);
  const[client,setclient] = useState(null);
  const[clientdisc, setclientedisc] = useState(null);
  const[cant, setcant] = useState(null);
  const[enable, setenable] = useState(false);
  const [fi, setfi] = useState(null);
  const [ff, setff] = useState(null);
  const [difT, setDifT] = useState([{ id: "eliminar" }]);
  const [products, setproducts] = useState([]);
  const [bandera, setbandera]=useState(true);

  useEffect(()=>{
    handleproducts();
  },[]);

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

function alertascreate(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'tr', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'tr', 
      autoDismiss: 60, 
    });
  }  
}

const handleChange = () => {
  if(enable===true){
    setenable(false);
  }else{
    setenable(true);
  }
};
  //console.log(difT);

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      //cuando se selecciona uno por uno
      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            title="Seleccionar venta"
            
            ref={resolvedRef}
            {...rest}
            style={{ width: 20 + "px" }}
          />
        </>
      );
    }
);

const handleproducts = async ()=>{
  try {
    var rf_token = await refresh_token ();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_products();
          setproducts(d === null ? [] : d.data.productList);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}
const itemStyle = {
  padding: "2px",
  border: "1px solid silver",
  color: "Black",
  width: "100px",
  height: "20px",
};
const textStyle = {
  ...itemStyle,
  padding: "3px 2px",
  "font-size":"12px"
};

const addpromotion =async ()=>{

  if(namepromotion===null || fi===null || ff===null || client===null || clientdisc===null || cant===null){
    alertascreate("Datos faltantes de promoción",false);
  }
  else{

    const arreglo = (difT.filter(item=>item.select!=="noselect"));
    var bam = 0;
    arreglo.map(item => {
      if( item.quantity===undefined || item.quantity===0 || item.promoPrice===0){
        bam=1;
      }
    })

    if(bam===1){
      alertascreate("No puede tener productos en precio o cantidad igual a 0", false);
      return null;
    }
  
    if(arreglo.length!==0){
     
      props.reconsultar(true);
      setbandera(false);
      
      var data = {
        promoName: namepromotion,
        enable: enable,
        initialDatePromo: fi,
        finalDatePromo: ff,
        typeBusinessPartner: client,
        customerWithDiscount:  clientdisc,
        amountRequired:  cant
      }    
      try {
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const dt = await post_promotion(data);
              console.log(dt);
              filtrar(dt, arreglo);  
            }catch(err){
              console.log("error post", err);
              catchErrorModul("Promociones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
              props.reconsultar(false);
              alertascreate("No se pudo crear la promoción, vuelva a intertarlo",false);
              setbandera(true);
            }       
          }, 1000);
        }else{}
      } catch (err) {}
    }
    else{
     alertascreate("Falta agregar productos a la promoción", false); 
    }  
  }  
}


const filtrar = async (response, arreglo) => {
  
   var data = {
     details:arreglo
   } 
   try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const dt = await post_promotion_details(response.data.idPromotion, data);
          console.log(dt);
          props.alertas("Promoción creada con éxito", true);
          props.reconsultar(false);
          dialog.close();     
          props.handleSubmit();
        }catch(err){
          console.log("error post", err);
          catchErrorModul("Promociones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          alertascreate("Error al agregar detalles a una promoción", false);
          setbandera(true);
          props.reconsultar(false);
        }    
      }, 1000);
    }else{}
    } catch (err) {}
}


useEffect(() => {
  const handlerestar = () => {
      setDifT(difT);
  };
  handlerestar();
});  

const columns = useMemo(
  () => [  
     {
        Header: "Código",
        accessor: "code",
      },
      {
       Header: "Nombre",
       accessor: "product_name",
     },
     {
        Header: "Precio",
        Cell:({ row, value}) => {
        
        const [text2, setText2] = useState(value ?? 0.00);     
       
        useEffect(() => {
          const handlerestar = () => {
              setDifT(difT);
          };
          handlerestar();
        });     

        useEffect(() => {
          setText2(value);
        }, [value]);

        const handleInputChange3 = (e) => {
          console.log("entra 1");
          setText2(e.target.value);
          row.original.cost = e.target.value;
        }        
        const handleInputChange4 = (e) => {
          console.log("entra 2");
          setText2(0);
        };
        const handleSaveClick2 = () => {   
          console.log("entra 3");  
           
          difT[row.index].promoPrice = parseFloat(text2);
          setDifT(difT);
          console.log(difT)
        };
        return(
          row.isSelected===true?
            <input type={"number"} 
            value={row.original.cost}
            style={textStyle} 
            onChange={handleInputChange3}
            onClick={handleInputChange4}
            onBlur={handleSaveClick2}
          />:
          <input type={"number"} 
          value={0.0}
          style={textStyle} 
          disabled
         />
            
          )    
        }     
     },
     {
        Header: "Cantidad",
        Cell:({ row, value}) => {
        
        const [text, setText] = useState(value ?? 0.00);     
       
        useEffect(() => {
          const handlerestar = () => {
              setDifT(difT);
          };
          handlerestar();
        });     

        useEffect(() => {
          setText(value);
        }, [value]);

        const handleInputChange = (e) => {
          console.log("entra 1");
          setText(e.target.value);
          row.original.quantity = e.target.value;
        }        
        const handleInputChange2 = (e) => {
          console.log("entra 2");
          setText(0);
        };
        const handleSaveClick = () => {   
          console.log("entra 3");  
           
          difT[row.index].quantity = parseInt(text);
          setDifT(difT);
          
          console.log(difT)
          
        };
          
        return(
          row.isSelected===true?
           
            <input type={"number"} 
              style={textStyle}
              value={row.original.quantity}
              onChange={handleInputChange}
              onClick={handleInputChange2}
              onBlur={handleSaveClick}
            />:
            <input type={"number"} 
              style={textStyle} 
              value={0.0}
              disabled
            />
          )    
        }    
     },
     {
      Header:"Agregar",
      Cell:
      ({ row, value}) => {       
        const [text, setText] = React.useState(value ?? "");
        const [text2, setText2] = React.useState(value ?? "");
        var rowDate = row.getToggleRowSelectedProps();

  if(rowDate.checked===true){          
          
  console.log("entra al checkbox");
  
  difT[row.index]={
       id_campo: row.index,
       promoPrice: row.original.cost,
       productName: row.original.product_name,
       productCode: row.original.code,
       quantity: row.original.quantity,
       select:"select"
  }
  }else{  
    difT[row.index]={
      id_campo: row.index,
      promoPrice:0.00,
      productName: row.original.product_name,
      productCode: row.original.code,
      quantity: 0.00,
      select:"noselect"
    }
    
  }

function folio() {
  
  //console.log(difT);
    if (row.isSelected === true) { 
      
      row.original.quantity = 0.00;
      
      if (difT[row.index]?.length!== 0) {
        difT[row.index] = {
            id_campo: null,
            promoPrice: 0,
            productName: row.original.product_name,
            productCode: row.original.code,
            quantity: 0,
         };
       }
       } else {
        //entra cuando se selecciona una casilla
              difT[row.index] = {
                id_campo: row.index,
                promoPrice: text2,
                productName: row.original.product_name,
                productCode: row.original.code,
                quantity: text,
              };
              //console.log("entra");
              setDifT(difT);
      }        
  }
          return (
            <div className="">
              {" "}
              <IndeterminateCheckbox
                className="NoInput"
                onChange={folio}
                onClick={folio}
                {...row.getToggleRowSelectedProps()}
                title="Seleccionar Producto"
                />
            </div>
            );
        },
      },    
    ],
    []
);

  return (
    <div>
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
      <div className="subtablepromotions">
        <div className="gridpromo1">
          <div className="promocampo1">
          <TextField
              label="Nombre de la Promoción"
              required
              disabled={!bandera}
              value={namepromotion}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setnamepromotion(event.target.value.toUpperCase())}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
          <div className="promocampo2">
          <TextField
              select
              required
              label="Tipo de Cliente"
              defaultValue={""}
              disabled={!bandera}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setclient(event.target.value)}  
            >
              <option key={0} value={0} selected>{"Selecciona uno"}</option>
              <option key={1} value={"PROPIOS"}>{"Cliente Propio"}</option>
              <option key={2} value={"EXTERNOS"}>{"Cliente Unilever"}</option>
              <option key={3} value={"TODOS"}>{"Todos"}</option>
          </TextField>
          </div>
        </div>
        <div className="gridpromo2">
          <div className="promocampo3">
          <TextField
              select
              required
              label="Cliente con Descuento"
              disabled={!bandera}
              defaultValue={""}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setclientedisc(event.target.value)}  
            >
              <option key={0} value={0} selected>{"Selecciona uno"}</option>
              <option key={1} value={"SI"}>{"Si"}</option>
              <option key={2} value={"NO"}>{"No"}</option>
              <option key={3} value={"TODOS"}>{"Todos"}</option>
          </TextField>
          </div>
          <div className="promocampo4">
          <TextField
              label="Cantidad Requerida"
              disabled={!bandera}
              type={"number"}
              required
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setcant(event.target.value)}
              InputLabelProps={{ shrink: true }}
            ></TextField> 
          </div>
          <div className="promocampo5">
          <label className="disponiblepromo"
              style={{"fontSize":"18px", "color":"black", "paddingLeft":"5%", "paddingTop":"30px", "paddingRight":"20px"}}
            >Disponible: </label>

            <Checkbox
            checked={enable}
            disabled={!bandera}
            onChange={handleChange}
            inputProps={{"aria-label": "primary checkbox"}}
            style={{"transform":"scale(1.7)"}}
            />      
          </div>
        </div>
       <div className="gridpromo3">
        
          <div className="promocampo6">
          <label className="Fechainicial">Fecha Inicial</label>
         
          <input
                  type="text"
                  className="fi" 
                  required
                  disabled={!bandera}
                  style={{"width":"100%"}}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                placeholder="Fecha inicial"
                onChange={(event) => setfi(event.target.value)}
            ></input>
          </div>
          <div className="promocampo7">
          <label className="Fechainicial">Fecha Final</label>
         
          <input
                  type="text"
                  required
                  style={{"width":"100%"}}
                  disabled={!bandera}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
            ></input>
          </div>
        </div> 

          <br></br>
          <br></br>
          <div style={bandera===true?{"displat":"block"}:{"display":"none"}}>
          <StickyTable>
            <TableContainer
              paginado={"Promociones"}
              consultas={"Promociones Consultadas"}
              columns={columns}
              data={products}
              style={{ overflowx: 10 }}
            />
          </StickyTable> 
          
          </div>
           <br/>

          <Box>
            {
              bandera===true?
              <Button
              style={{"backgroundColor":"green"}}
              variant="contained"
              sx={{ m: 1.5, width: "30ch" }}
              onClick={addpromotion}
            >
              Agregar Promoción
            </Button>:
            <Button
            style={{"backgroundColor":"green", "height":"35px"}}
            variant="contained"
            sx={{ m: 1.5, width: "30ch" }}
            onClick={addpromotion}
          ><Spinner/></Button>
          }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
              cancelar
            </Button>
          </Box>
      </div>
    </div>
  );
}

export default CreatePromotions;
