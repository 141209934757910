import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import add from "../../../../imgComponents/reconsultaTable.png";
import { put_taxes_configs } from "../../../../services/invoices/invoice";
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";

const ACTIONS_SPECIALS = (props) => {

    const setDefault = async()=> {
        
        props?.setmodalT(true);
        var data = {};
        try{
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                const d = await put_taxes_configs(props?.row.original.id, data);
                console.log(d);
                props?.alertas("Exito, Impuesto establecido como default", true);
                props?.setmodalT(false);
                props?.handleSubmit();
              }catch(err){
                console.log("error", err);
                catchErrorModul("Impuestos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
                props?.setmodalT(false);
                props?.alertas("Error.,"+ err?.response?.data?.errorMessage, false); 
              }
            }, 1000);
          }else{}
        }catch(err){

        }

    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm(
                        "¿Esta usted seguro de establecer como default este impuesto?",
                        "Establecer por default",
                        "Si",
                        "No"
                    );
                    if (result) {
                        setDefault();
                    }}
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={add}
                        title="Asignar por default Impuesto"
                        className=""
                    ></img>
                </ListItemIcon>
                Asignar por default Impuesto
            </MenuItem>
            
        </div >
    );
};

export default ACTIONS_SPECIALS;
