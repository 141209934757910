//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog } from "react-st-modal";
import { StickyTable } from "react-sticky-table";

//COMPONENTES
import TableContainer from "../../../main_components/Table/TableContainer";
import { SelectColumnFilter, MultiSelectColumnFilter } from "../../filters";
import ReenvioCorreo from "./report_email/send_invoice";
import SubTable from "./detail/detail_invoce";
import CANCEL_INVOICE from "../cancel_invoice/cancel_invoice";
import { base64toBlob, base64toBlobxml } from "../../../main_components/donwloadfuncion/download";
import { get_invoices } from "../../../services/invoices/invoice";
import { download_invoice } from "../../../services/invoices/invoice";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_status } from "../../../services/invoices/invoice";
import SEARCH_FILTERS from "../../../main_components/Table/filter_shear";
import FormControlLabel from "@mui/material/FormControlLabel";
//ESTILOS
import "../../estilo.css";
import "../../modal.css";
import "../../table.css";

//IMAGENES
import tache from "../../../imgComponents/tache.png";
import tacheCANCEL from "../../../imgComponents/cancelado.png";
import PDF from "../../../imgComponents/pdf.png";
import PDFCANCEL from "../../../imgComponents/pdfcancel.png";
import XML from "../../../imgComponents/xml.png";
import XMLCANCEL from "../../../imgComponents/xmlcancel.png";
import EMAIL from "../../../imgComponents/correoElectronico.png";
import { COLUMS_SHOW } from "../../../main_components/Table/ColumsShow";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { MaterialUISwitch } from "../../../main_components/material_switch/switch";
import { formattedCurrentDate, formattedPreviousDate } from "../../../main_components/date/day";
import "./invoice.css";
import { MAP_RELATION_INVOICE } from "../map_relation/map_relation";
import maprel from "../../../imgComponents/iconos/mapa_relaciones.png";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
//COMPONENTES


const Consult_invoice = () => {

  const [data, setData] = useState([]);
  const [ff, setff] = useState(formattedCurrentDate);
  const [fi, setfi] = useState(formattedPreviousDate);
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState(null);
  const [tipodoc, setTipoDoc] = useState("");
  const [reconsult, setreconsult] = useState(false);
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  var entrada = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [fullscreen_table, setfullscreen_table] = useState(true);

  const notificationSystemRef = useRef();
  const tableContainerRef = useRef(null);
  const toggleFullscreen = () => {
    const element = tableContainerRef.current;
    if (!document.fullscreenElement && element.requestFullscreen) {
      element.requestFullscreen().then(() => {
        element.classList.add('fullscreen');
        setfullscreen_table(false)
      });
    } else if (document.exitFullscreen) {
      document.exitFullscreen().then(() => {
        element.classList.remove('fullscreen');
        setfullscreen_table(true)

      });
    }
  };



  //variables para visualizar columnas
  console.log(localStorage.getItem("facturas_viewfecha"));
  const [viewfecha, setviewfecha] = useState(
    localStorage.getItem("facturas_viewfecha") === null
      ? true
      : localStorage.getItem("facturas_viewfecha") === "true"
        ? true
        : false
  );
  const [viewticket, setviewticket] = useState(
    localStorage.getItem("facturas_viewticket") === null
      ? true
      : localStorage.getItem("facturas_viewticket") === "true"
        ? true
        : false
  );
  const [viewpos, setviewpos] = useState(
    localStorage.getItem("facturas_viewpos") === null
      ? false
      : localStorage.getItem("facturas_viewpos") === "true"
        ? true
        : false
  );
  const [viewnote, setviewnote] = useState(
    localStorage.getItem("viewnote") === null
      ? true
      : localStorage.getItem("viewnote") === "true"
        ? true
        : false
  );
  const [viewstatus, setviewstatus] = useState(
    localStorage.getItem("viewstatus") === null
      ? false
      : localStorage.getItem("viewstatus") === "true"
        ? true
        : false
  );
  const [viewis_cancel, setviewis_cancel] = useState(
    localStorage.getItem("viewis_cancel") === null
      ? false
      : localStorage.getItem("viewis_cancel") === "true"
        ? true
        : false
  );
  const [viewuuid_sat, setviewuuid_sat] = useState(
    localStorage.getItem("viewuuid_sat") === null
      ? false
      : localStorage.getItem("viewuuid_sat") === "true"
        ? true
        : false
  );
  const [viewseriefolio, setviewseriefolio] = useState(
    localStorage.getItem("facturas_viewseriefolio") === null
      ? false
      : localStorage.getItem("facturas_viewseriefolio") === "true"
        ? true
        : false
  );
  const [viewserie, setviewserie] = useState(
    localStorage.getItem("facturas_viewserie") === null
      ? true
      : localStorage.getItem("facturas_viewserie") === "true"
        ? true
        : false
  );

  const [viewfolio, setviewfolio] = useState(
    localStorage.getItem("facturas_viewfolio") === null
      ? true
      : localStorage.getItem("facturas_viewfolio") === "true"
        ? true
        : false
  );
  const [viewcliente, setviewcliente] = useState(
    localStorage.getItem("facturas_viewcliente") === null
      ? true
      : localStorage.getItem("facturas_viewcliente") === "true"
        ? true
        : false
  );
  const [viewrfc, setviewrfc] = useState(localStorage.getItem("facturas_viewrfc") === null ? true : localStorage.getItem("facturas_viewrfc") === "true" ? true : false);
  const [viewsubtotal, setviewsubtotal] = useState(
    localStorage.getItem("facturas_viewsubtotal") === null
      ? true
      : localStorage.getItem("facturas_viewsubtotal") === "true"
        ? true
        : false
  );
  const [viewdescuento, setviewdescuento] = useState(
    localStorage.getItem("facturas_viewdescuento") === null
      ? true
      : localStorage.getItem("facturas_viewdescuento") === "true"
        ? true
        : false
  );

  const [viewimpuesto, setviewimpuesto] = useState(
    localStorage.getItem("facturas_viewimpuesto") === null
      ? true
      : localStorage.getItem("facturas_viewimpuesto") === "true"
        ? true
        : false
  );
  const [viewtotal, setviewtotal] = useState(
    localStorage.getItem("facturas_viewtotal") === null
      ? true
      : localStorage.getItem("facturas_viewtotal") === "true"
        ? true
        : false
  );
  const [viewmetodopago, setviewmetodopago] = useState(
    localStorage.getItem("facturas_viewmetodopago") === null
      ? true
      : localStorage.getItem("facturas_viewmetodopago") === "true"
        ? true
        : false
  );
  const [viewcomprobante, setviewcomprobante] = useState(
    localStorage.getItem("facturas_viewcomprobante") === null
      ? true
      : localStorage.getItem("facturas_viewcomprobante") === "true"
        ? true
        : false
  );
  const [viewestado, setviewestado] = useState(
    localStorage.getItem("facturas_viewestado") === null
      ? true
      : localStorage.getItem("facturas_viewestado") === "true"
        ? true
        : false
  );
  const [viewusuario, setviewusuario] = useState(
    localStorage.getItem("facturas_viewusuario") === null
      ? true
      : localStorage.getItem("facturas_viewusuario") === "true"
        ? true
        : false
  );

  useEffect(() => {
    localStorage.setItem("facturas_viewfecha", viewfecha);
    localStorage.setItem("facturas_viewticket", viewticket);
    localStorage.setItem("facturas_viewpos", viewpos);
    localStorage.setItem("facturas_viewseriefolio", viewseriefolio);
    localStorage.setItem("facturas_viewserie", viewserie);
    localStorage.setItem("facturas_viewfolio", viewfolio);
    localStorage.setItem("facturas_viewcliente", viewcliente);
    localStorage.setItem("facturas_viewrfc", viewrfc);
    localStorage.setItem("facturas_viewsubtotal", viewsubtotal);
    localStorage.setItem("facturas_viewdescuento", viewdescuento);
    localStorage.setItem("facturas_viewimpuesto", viewimpuesto);
    localStorage.setItem("facturas_viewtotal", viewtotal);
    localStorage.setItem("facturas_viewmetodopago", viewmetodopago);
    localStorage.setItem("facturas_viewcomprobante", viewcomprobante);
    localStorage.setItem("facturas_viewestado", viewestado);
    localStorage.setItem("facturas_viewusuario", viewusuario);
    localStorage.setItem("viewstatus", viewstatus);
    localStorage.setItem("viewis_cancel", viewis_cancel);
    localStorage.setItem("viewnote", viewnote);
    localStorage.setItem("viewuuid_sat", viewuuid_sat);
  }, [viewfecha, viewticket, viewpos, viewseriefolio, viewserie, viewfolio, viewcliente, viewrfc, viewsubtotal, viewdescuento, viewimpuesto, viewtotal, viewmetodopago, viewcomprobante, viewestado, viewusuario, viewstatus, viewis_cancel, viewnote, viewuuid_sat])


  const handleSubmit = async (event) => {
    event?.preventDefault();

    if (entrada === 0) {
      setmodalGeneral(true);
      setmenssage("Consultando Facturas");
    }
    setmodalT(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var dt = await get_invoices(fi, ff, rfc, sdn, tipodoc, cancel);
            setData(dt.data.slice().sort((a, b) => a.invoice_date < b.invoice_date));
            setmodalGeneral(false);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handle_status = async (id, uuid) => {
    setmenssage("Verificando estado de la factura");
    setmodalGeneral(true);
    setmodalT(true);
    setreconsult(true)
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_status(id, uuid);
            sessionStorage.setItem("faCancel", dt?.data?.cancellation_request_id);
            // alert("Estado actualizado");
            setreconsult(false)
            setmodalT(false);
            setmodalGeneral(false);
            handleSubmit();

          } catch (err) {
            alert(JSON.stringify(err.response.data));
            setreconsult(false)
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }
  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };


  const download = async (typedocumentexmlorpdf, id_invoce, fileName_document) => {
    setmenssage("Descargando " + typedocumentexmlorpdf);
    setmodalGeneral(true);
    setmodalT(true);

    try {
      const data = await download_invoice(
        typedocumentexmlorpdf,
        fileName_document,
        id_invoce
      );
      var dt = data.data.file;
      var blob;
      if (typedocumentexmlorpdf === "xml") {
        blob = base64toBlobxml(dt);
      } else {
        blob = base64toBlob(dt);
      }
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      let arr = fileName_document.split("/");
      console.log(typedocumentexmlorpdf);
      downloadLink.download = arr[4] + `.${typedocumentexmlorpdf}`;
      downloadLink.click();
      setmodalGeneral(false);
      setmodalT(false);
    } catch (err) {
      setmodalGeneral(false);
      setmodalT(false);
    }
  }



  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ".",
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("dataSuntablefiltro", row.original.uuid_sat);
            sessionStorage.setItem("dataSuntablefiltroID", row.original.id);
            console.log(row.original.folio);
          }

          return (
            <a {...row.getToggleRowExpandedProps()}>
              {row?.isExpanded ? (
                <span>🔼</span>
              ) : (
                <span onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
        maxWidth: 50,
        width: 50,
        className: "justify_data",
      },
      {
        Header: "Acciones",
        id: "Acciones",
        Cell: ({ valor, row }) => {
          return (
            <div>
              <div className="contanerActionImg">
                <div className="fat-1">
                  <img
                    alt=""
                    style={{ display: row.original.cancel === false ? "none" : "" }}
                    src={tacheCANCEL}
                    title="REVERIFICAR ESTADO DE LA FACTURA"
                    onClick={() => handle_status(row.original.id, row.original.uuid_sat)}
                  ></img>

                  <img
                    alt=""
                    src={tache}
                    style={{ display: row.original.cancel === false ? "" : "none" }}
                    className="cancelIMG"
                    title="Cancelar Factura"
                    onClick={async () => {
                      if (
                        sessionStorage.getItem("PERMISOCANCEL") ===
                        "ROLE_CANCEL_INVOICE"
                      ) {
                        sessionStorage.setItem("rfcBusqueda", row.original.rfc_business_partner);
                        sessionStorage.setItem("rowUuid", row.original.uuid_sat);
                        sessionStorage.setItem("id_cancelF", row.original.id);
                        handle_status(row.original.id, row.original.uuid_sat);

                        await CustomDialog(
                          <CANCEL_INVOICE
                            idCancel={row.original.id}
                            handleSubmit={handleSubmit}
                            serie={row.original.serie}
                            folio={row.original.folio}
                          ></CANCEL_INVOICE>,
                          {
                            className: "modalCancelR",
                            title: `Seleccione el motivo por el cual desea cancelar la factura ${row.original.serie}${row.original.folio}`,
                            showCloseIcon: true,
                          }
                        );
                      } else {
                        alert("No tienes permisos para cancelar");
                      }
                    }}
                  ></img>
                </div>
                <div className="fat-2">
                  <img
                    alt=""
                    src={XML}
                    title="Descargar XML"
                    className="cancelIMG2"
                    onClick={() =>
                      download("xml", row.original.id, row.original.filename)
                    }
                  ></img>
                </div>

                <div className="fat-3">
                  <img
                    alt=""
                    src={PDF}
                    title="Descargar PDF"
                    className="cancelIMG2"
                    onClick={() =>
                      download("pdf", row.original.id, row.original.filename)
                    }
                  ></img>
                </div>
                <div className="fat-7">
                  <img
                    alt=""
                    title="mapa de relaciones"
                    className="mapa_icono"
                    src={maprel}
                    onClick={async () =>
                      await CustomDialog(
                        <MAP_RELATION_INVOICE row={row} setmodalT={setmodalT}></MAP_RELATION_INVOICE>,
                        {
                          className: "modal-mapa-rel",
                          title: 'Mapa de relaciones',
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      )
                    }
                  >
                  </img>
                </div>

                <div className="fat-5">
                  <img
                    alt=""
                    src={XMLCANCEL}
                    style={{
                      display: row.original.cancel === false ? "none" : "",
                    }}
                    onClick={() =>
                      download(
                        "xml",
                        row.original.id,
                        row.original.filename_cancel
                      )
                    }
                    title="Descargar XML Cancel"
                  ></img>
                </div>

                <div className="fat-6">
                  <img
                    alt=""
                    src={PDFCANCEL}
                    style={{
                      display: row.original.cancel === false ? "none" : "",
                    }}
                    onClick={() =>
                      download(
                        "pdf",
                        row.original.id,
                        row.original.filename_cancel
                      )
                    }
                    title="Descargar PDF Cancel"
                  ></img>
                </div>
                <div className="fat-4">
                  <img
                    alt=""
                    src={EMAIL}
                    className="cancelIMG2"
                    title="ENVIAR CORREO"
                    onClick={async () => {
                      sessionStorage.setItem(
                        "FACTURAMANDAR",
                        row.original.uuid_sat
                      );

                      await CustomDialog(
                        <ReenvioCorreo
                          report={false}
                          id={row.original.id}
                          uuid={row.original.uuid_sat}
                        ></ReenvioCorreo>,
                        {
                          className: "custom-modal",
                          title:
                            'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO',
                          showCloseIcon: true,
                        }
                      );
                    }}
                  ></img>
                </div>
              </div>
            </div>
          );
        },
        maxWidth: 500,
        width: 80,
        className: "justify_data",

      },


      {
        Header: "Fecha",
        accessor: "invoice_date",
        show: viewfecha,
        id: "invoice_date",
        maxWidth: 500,
        width: 100,
        className: "justify_data",
      },

      {
        Header: "Serie",
        accessor: "serie",
        Filter: MultiSelectColumnFilter,
        filter: "includes",
        id: "serie",
        show: viewserie,
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "center_data"
      },
      {
        id: "cancel",
        Header: "Folio",
        accessor: "folio",
        show: viewfolio,
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Serie/Folio",
        accessor: (d) => {
          return d.serie + d.folio
        },
        show: viewseriefolio,
        id: "seriefolio",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"

      },
      {
        Header: "Cliente",
        accessor: "name_business_partner",
        show: viewcliente,
        id: "name_business_partner",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"

      },
      {
        Header: "RFC",
        accessor: "rfc_business_partner",
        show: viewrfc,
        id: "rfc_business_partner",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Subtotal",
        accessor: (d) => {
          return d.subtotal
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.subtotal)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.subtotal);
        },
        show: viewsubtotal,
        id: "subtotal",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Descuento",
        accessor: (d) => {
          return d.discount
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.discount)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.discount);
        },
        show: viewdescuento,
        id: "discount",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Impuesto",
        accessor: (d) => {
          return d.tax
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.tax)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.tax);
        },
        show: viewimpuesto,
        id: "tax",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
        },
        show: viewtotal,
        id: "total",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },

      {
        Header: "Metodo de pago",
        accessor: "payment_method",
        id: "payment",
        Filter: SelectColumnFilter,
        filter: "equals",
        show: viewmetodopago,
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Tipo de comprobante",
        accessor: "tipodecomprobante",
        Filter: MultiSelectColumnFilter,
        filter: "includes",
        show: viewcomprobante,
        id: "tipodecomprobante",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },

      {
        Filter: SelectColumnFilter,
        filter: "equals",
        Header: "Estado",
        accessor: (d) => {
          return d.cancel === true
            ? d.status === "Vigente"
              ? "VIGENTE"
              : "CANCELADO"
            : "VIGENTE";
        },
        show: viewestado,
        id: "status",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Usuario",
        accessor: "username",
        show: viewusuario,
        id: "username",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "Estado en SAT",
        accessor: "status",
        show: viewstatus,
        id: "status_f",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "¿Es cancelable?",
        accessor: "its_cancelable",
        show: viewis_cancel,
        id: "its_cancelable",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },

      {
        Header: "Nota",
        accessor: "note",
        show: viewnote,
        id: "note",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },
      {
        Header: "UUID_SAT",
        accessor: "uuid_sat",
        show: viewuuid_sat,
        id: "uuid_sat",
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        className: "justify_data"
      },

    ],
    [viewfecha, fullscreen_table, viewticket, viewpos, viewserie, viewfolio, viewseriefolio, viewcliente, viewrfc, viewsubtotal, viewdescuento, viewimpuesto, viewtotal, viewmetodopago, viewcomprobante, viewestado, viewusuario, viewis_cancel, viewstatus, viewnote, viewuuid_sat]
  );


  const datacolumns = [
    {
      view: viewfecha,
      setview: setviewfecha,
      label: "Fecha"
    },

    {
      view: viewserie,
      setview: setviewserie,
      label: "Serie"
    },
    {
      view: viewfolio,
      setview: setviewfolio,
      label: "Folio"
    },
    {
      view: viewseriefolio,
      setview: setviewseriefolio,
      label: "Serie/Folio"
    },
    {
      view: viewcliente,
      setview: setviewcliente,
      label: "Cliente"
    },
    {
      view: viewrfc,
      setview: setviewrfc,
      label: "RFC"
    },
    {
      view: viewsubtotal,
      setview: setviewsubtotal,
      label: "Subtotal"
    },
    {
      view: viewdescuento,
      setview: setviewdescuento,
      label: "Descuento"
    },
    {
      view: viewimpuesto,
      setview: setviewimpuesto,
      label: "Impuesto"
    },
    {
      view: viewtotal,
      setview: setviewtotal,
      label: "Total"
    },
    {
      view: viewmetodopago,
      setview: setviewmetodopago,
      label: "Metodo Pago"
    },
    {
      view: viewcomprobante,
      setview: setviewcomprobante,
      label: "Tipo de Comprobante"
    },
    {
      view: viewstatus,
      setview: setviewstatus,
      label: "Estado en SAT"
    },
    {
      view: viewis_cancel,
      setview: setviewis_cancel,
      label: "¿Es cancelable?"
    },
    {
      view: viewuuid_sat,
      setview: setviewuuid_sat,
      label: "UUID_SAT"
    },
    {
      view: viewusuario,
      setview: setviewusuario,
      label: "Usuario"
    },
    {
      view: viewnote,
      setview: setviewnote,
      label: "Nota"
    },

  ]


  const search_filters = [
    {
      slot: <input type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }} placeholder="Fecha inicial" onChange={(event) => setfi(event.target.value)}></input>,
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: <input type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }} placeholder="Fecha Final" onChange={(event) => setff(event.target.value)}></input>,
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: <select id="canceladas-si-no" onChange={(event) => setCancel(event.target.value)}>
        <option>Estado de la factura</option>
        <option value={true}>CANCELADO</option>
        <option value={false}>VIGENTE</option>
        <option value={""}>TODOS</option>
      </select>,
      fr: null,
      padding: 5,
    },
    {
      slot: <input type="text" placeholder="Busqueda por RFC" className="filtroRFC" onChange={(event) => setRFC(event.target.value)}></input>,
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: <input type="text" placeholder="Busqueda por socio de negocios" onChange={(event) => setSDN(event.target.value)}></input>,
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: <select onChange={(event) => setTipoDoc(event.target.value)}>
        <option>Tipo de documento</option>
        <option value={"FACTURA"}>FACTURA</option>
        <option value={"NOTA DE CREDITO"}>NOTA DE CRÉDITO</option>
        <option value={"TRASLADO"}>CARTA PORTE</option>
        <option value={"PAGO"}>PAGO</option>
        <option value={"null"}>TODOS</option>
      </select>,
      fr: null,
      display: null,
      padding: 5,

    },
    {
      slot: <button style={{ width: "100%" }} className="button" onClick={handleSubmit} type="button">Buscar</button>,
      fr: null,
      display: null,
      padding: null,
    },
    {
      slot: <FormControlLabel
        control={
          <MaterialUISwitch
            sx={{ m: 1 }}
            onChange={handlechecked}
            checked={filtersColumsView}
          />}
        label={filtersColumsView === true ? "Activar Filtros" : "Desactivar Filtros"}
      />,
      fr: null,
      padding: null,
      display: null,
    },

    {
      slot: <FullscreenIcon title="Pantalla completa" style={{ cursor: "pointer" }} onClick={toggleFullscreen}></FullscreenIcon>,
      fr: 0.2,
      padding: null,
      display: null,
    },


  ];
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setfullscreen_table(true);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <div ref={tableContainerRef}
    >
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR FACTURAS", path: null },
        ]
      }
        new_version={true}
        modul="CONSULTAR FACTURAS V2"
        path="/invoice_v2"
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <SEARCH_FILTERS search_filters={search_filters}></SEARCH_FILTERS>
      <COLUMS_SHOW filtersColumsView={filtersColumsView} datacolums={datacolumns} />
      <div>
        <div className="TableConsultarTodo">
          <TableContainer
            nametable={`Facturas del ${fi} al ${ff}`}
            zipfacturas={true}
            columns={columns}
            fullscreen_table={fullscreen_table}
            data={data}
            style={{ overflowx: 10 }}
            consultas={"Facturas"}
            exportar={true}
            Grantotal={true}
            hpdf={2}

            GrantotalCabeceras={
              ["Fecha",
                "Ticket",
                "Punto de Venta",
                "Serie",
                "Folio",
                "Cliente",
                "Serie/Folio",
                "RFC",
                "Metodo de pago",
                "Tipo de comprobante",
                "Estado",
                "Usuario",
                "Estado en SAT",
                "Nota",
                "UUID_SAT",
                "¿Es cancelable?"]
            }
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }} >
                  <SubTable
                    reconsult={reconsult}
                    data={data}
                    tipo={row}
                    columns={columns}
                    defaultPageSize={3}
                    showPagination={false}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>

    </div>

  );
};

export default Consult_invoice;

