import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import add from "../../../../imgComponents/add.png";
import delet from "../../../../imgComponents/borrar.png";
import edit from "../../../../imgComponents/save.png";
import { delete_concepts } from "../../../../services/invoices/invoice";
import Set_Tax_Concept from "../Set_Tax_Concept";
import SET_CONCEPT_V2 from "./set_concept_v2";
const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector = async () => {

        props?.setmodalT(true);
        try {
            var rf_token = await refresh_token();
            if (rf_token === true) {
                setTimeout(async () => {
                    try {
                        const d = await delete_concepts(props?.row.original.id)
                        console.log(d);
                        props?.alertas("Concepto eliminado con éxito", true);
                        props?.setmodalT(false);
                        props.handleSubmit();
                    } catch (err) {
                        console.log("error", err);
                        props?.setmodalT(false);
                        props?.alertas("Error.," + err.response.data.errorMessage, false);
                    }
                }, 1000);
            } else { }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div>
            <MenuItem
                key={1}
                onClick={async () => {
                    props.closeMenu();
                    const result = await CustomDialog(
                        <Set_Tax_Concept
                            base={props?.row.original.valor_unitario}
                            id_conceptADD={props?.row.original.id}
                            handleSubmit2={props?.handleSubmit}
                            setmodalT={props?.setmodalT}
                            setrec={props?.setrec}
                            alertas={props?.alertas}
                        ></Set_Tax_Concept>,
                        {
                            className: "modalTItle",
                            title: "Asignar impuesto al concepto",
                            showCloseIcon: true,
                            isCanClose: false,
                        }
                    );
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={add}
                        title="Agregar Impuesto al Concepto"
                        className=""
                    ></img>
                </ListItemIcon>
                Agregar Impuesto al Concepto
            </MenuItem>
            <MenuItem
                key={2}
                onClick={async () => {
                    props.closeMenu();
                    await CustomDialog(

                        <SET_CONCEPT_V2
                            setmodalT={props?.setmodalT}
                            handleSubmit={props?.handleSubmit}
                            alertas={props?.alertas}
                            Concepto={props?.row.original.concept}
                            Clave_prod_serv={props?.row.original.clave_prod_serv}
                            Clave_unidad={props?.row.original.clave_unidad}
                            Descripcion={props?.row.original.descripcion}
                            No_identificacion={props?.row.original.no_identificacion}
                            Objeto_imp={props?.row.original.objeto_imp}
                            Peso_en_kg={props?.row.original.peso_en_kg}
                            Valor_unitario={props?.row.original.valor_unitario}
                            Unidad={props?.row.original.unidad}
                            method={"UPDATE"}
                            id_concept={props?.row.original.id}

                        ></SET_CONCEPT_V2>,
                        {
                            className: "modalTItle",
                            title: "Editar Concepto",
                            showCloseIcon: true,
                            isCanClose: false,
                        }
                    );
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={edit}
                        title="Editar Concepto"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Concepto
            </MenuItem>
            <MenuItem
                key={3}
                onClick={async () => {
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el Concepto?', 'Eliminar Concepto', 'Si', 'No');
                    if (result) {
                        DeleteSector()
                    }
                }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={delet}
                        title="Eliminar Concepto"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Concepto
            </MenuItem>

        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
