import ConsultarFacturasProvedores  from '../../components_Expenses/supplier/consultarFacturasProvedores';
import { Component } from "react";

export class SetConsultarFacturasProveedores extends Component {

  
 

  render() {

   
    
      return (    
      
      <div className="tc">
      <ConsultarFacturasProvedores></ConsultarFacturasProvedores>

      </div>
          );
       }     
}

export default SetConsultarFacturasProveedores;


