//Paqueterias
import { useState, useEffect, useMemo,useRef } from "react";

//Componentes
import TableContainer from "../../../main_components/Table/TableContainer";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import NotificationSystem from 'react-notification-system';
import { get_price_list_products } from "../../../services/inventory/inventory";
import { ValidationTextField } from "../../../main_components/lable/labe";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import SAVE from "../../../imgComponents/save.png";
import DELETE from "../../../imgComponents/borrar.png";
import FILE_DATA from "../../../imgComponents/create.png";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import CREATE_UPDATE from "./tools/update_price";
import CREATE_UPDATE_FILE from "./tools/update_document";


//Estilos

const CONSULT_DETAIL_PRICE_LIST = (props) => {
  const [modalT, setmodalT] = useState(false);
  const [data, setData] = useState([]);
  const notificationSystemRef = useRef();

  const handle_detail_warehouse = async () => {
    setData([]);
    setmodalT(true);
    console.log("peticion aa")
    try {
      const isvaild = await refresh_token();
      if (isvaild) {
        setTimeout(async () => {
          try {
            const dt = await get_price_list_products(sessionStorage.getItem("price_list_products"));
            setData(dt.data);
            setmodalT(false);
          } catch (err) {
              setData([]);
              setmodalT(false);
          }
        }, 1000);
      }
    } catch (err) {
      console.log("entra el error");
      setData([]);
      setmodalT(false);
    }
  };

  const handle_alert = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };

  useEffect(() => {
    handle_detail_warehouse();
  }, []);

  const handle_reconsult = (result) => {
    console.log(result);
    if (result?.confirm) {
      handle_detail_warehouse();
      handle_alert(result.msj,result.confirm);

    } else if(result?.confirm === false){
      handle_alert(result.msj,result.confirm);
    }
  };
  const columns = useMemo(
    () => [
      {
        id: "Acciones",
        Header: () => {
          return (
            <div>
              <img
                alt=""
                title="Crear/Actualizar lista de precios por archivo"
                src={FILE_DATA}
                
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE_FILE
                      id={sessionStorage.getItem("price_list_products")}
                    ></CREATE_UPDATE_FILE>,
                    {
                      className: "modalTItle",
                      title: "Actualizar lista de precios por archivo",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handle_reconsult(result);
                  }
                }}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
        Cell: ({ valor, row }) => {
          return (
            <div>
              <img
                alt=""
                title="Actualizar"
                src={SAVE}
               
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE
                      data_price_list={data}
                      code={row.original.code}
                      method={"UPDATE"}
                      price={row.original.price}
                      id={sessionStorage.getItem("price_list_products")}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalTItle",
                      title: "Actualizar linea de productos",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handle_reconsult(result);
                  }
                }}
                className="centerText, taxes"
              ></img>

              <img
                alt=""
                title="Eliminar"
                src={DELETE}
            
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE
                      data_price_list={data}
                      code={row.original.code}
                      method={"DELETE"}
                      price={null}
                      id={sessionStorage.getItem("price_list_products")}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalTItle",
                      title: "Eliminar producto",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handle_reconsult(result);
                  }
                }}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Producto",
        accessor: "product_name",
      },
      {
        Header: "Linea",
        accessor: "brand",
      },
      {
        Header: "Presentación",
        accessor: "minimum_sales_unit",
      },
      {
        Header: "Piezas",
        accessor: "pieces",
      },
      {
        Header: "Precio",
        accessor: "price",
      },
    ],
    [data]
  );

  return (
    <div>
      <div>
        <MODAL_TABLE
          open={true}
          message={""}
          modalGeneral={false}
          modalT={modalT}
        ></MODAL_TABLE>
      </div>
      <BREADCRUMBS niveles={
            [
              {label: "LISTA DE PRECIOS", path:"/price_list"},
              {label: "PRODUCTOS", path:null},
            ]
          }
      ></BREADCRUMBS>
      <div>
        <div className="containerPricelist">
        
          <div className="expen-1 color-1">
            <button
              style={{ width: "100%", fontSize: "14px" }}
              title="Modificar toda la lista"
              className="button"
              onClick={()=>window.location="/update_price_list"}
            >
              Modificar toda la lista
            </button>
          </div>
          <div className="expen-2 color-1">
            <button
              style={{ width: "100%", fontSize: "14px" }}
              title="Agregar productos"
              className="button"
              onClick={()=>window.location="/add_product"}

            >
              Agregar productos a la lista
            </button>
          </div>
        </div>
        <div
          className="TablePriceListDetail"
          style={{ overflowX: "auto", overflowY: "auto" }}
        >
          <TableContainer
            exportEx={true}
            exportar={true}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
          />
        </div>
      </div>
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

    </div>
  );
};

export default CONSULT_DETAIL_PRICE_LIST;
