//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { StickyTable} from "react-sticky-table";
import { CustomDialog, useDialog } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "../SubTableContainer";
import ViewDetail_Detail_Bonus from "./ViewDetail_Detail_Bonus";
import {SelectColumnFilter,DefaultColumnFilter} from "../../components_cfdi/filters";
import { get_detail_bonus } from "../../services/reports/reports";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";
import "../sales/view_detail.css";
//IMAGENES
import Spinner from "../../imgComponents/S.gif";
import VER from "../../imgComponents/visualizar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_dashboard1_bonus2 } from "../../services/reports/dashboard";
import { ceil } from "lodash";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ViewDetail_Bonus({ datei,datef,route,id_detail,filtro,note,dev,bonus}) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [ReconsultaDta, setReconsultaDta] = useState(false);
  const [cantidad, setcantidad] = useState(0);

  const dialog = useDialog();

  /*
  const handleSubmit = async(event) => {
    event?.preventDefault();
    setReconsulta(true);
  
    try{
      var rf_token = await refresh_token();
      if(rf_token){
        setTimeout(async() => {
          try{
            const d = await get_dashboard1_bonus2(id_detail?.replaceAll(' ', '_')?.replaceAll("/","-"), datei, datef, route, filtro, note)
            const data = d.data;
            setData(data);
            const cargos = data.filter((item) => item.efect === "CARGO");
            const abonos = data.filter((item) => item.efect === "ABONO");
    
            const sumCargos = cargos
              .map((cargoSum) => parseFloat(cargoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
    
            const sumAbonos = abonos
              .map((abonoSum) => parseFloat(abonoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            console.log("==>cargos<==");
            console.log(sumCargos);
            console.log("==>abonos<==");
            console.log(sumAbonos);
            if (sumCargos - sumAbonos === 0) {
              setEquality("Los datos cuadran");
              console.log("Los datos cuadran");
              setSuccess_msg("");
              setError_msg("none");
            } else {
              setEquality("Los datos no cudran");
              console.log("Los datos no cudran");
              setError_msg("");
              setSuccess_msg("none");
            }
    
            setReconsulta(false);
          }catch(err){
            setData([]);
            setReconsulta(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
    */

const handle_detail_bonus = async() =>{
    var promises = [];
    setReconsulta(true);

    console.log(ceil(dev+bonus)/100)
    var page= Math.ceil((dev+bonus)/100);
    console.log(page);

    try {
      for (let i = 1; i <= page; i++) {
        promises.push(
          await get_detail_bonus(datei,datef,route,filtro,note,i,id_detail)
        );
        console.log(i+ "==="+page)
        if(i===page){
          setReconsulta(false);
        }
      }
   
    const results = await Promise.all(promises);
    console.log(results);
    const ndata = results.flatMap((result) => result?.data || []);
    var ndt=ndata?.map((d)=>{
      var json={
        id: d.id,
        code: d.code,
        name: d.name,
        address: d.address,
        frequency: d.frequency,
        visit_days: d.visit_days,
        date: d.date,
        customer_returns: d.customer_returns,
        customer_bonus: d.customer_bonus,
        route: d.route,
        detail: d?.details?.map(detail => `${detail?.ticket} / ${detail?.note}`).join(", "),
        total:d?.details?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => {return previous + current;}, 0)
      }
      return json;
    })
    console.log(ndt);
    setData(ndt);

    } catch (error) {
      
    }
  } 

  useEffect(() => {
    handle_detail_bonus();
  }, []);

  const columns = useMemo(
    () => [

      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {

          return (
            <div>

              <img
                alt=""
                title="Ver detalle"
                src={VER}
                className="centerText"
                onClick={async () => {
                  const result = await CustomDialog(
                    <ViewDetail_Detail_Bonus
                      id_detail={id_detail}
                      id_id_detail={row.original.id}
                      datei={datei}
                      datef={datef}
                      route={route}
                      filtro={filtro}
                      note={note}
                      address={row.original.address}
                      pointSale={row.original.name}
                    ></ViewDetail_Detail_Bonus>,
                    {
                      title: " ",
                      className: "saleDetailTikect",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Ruta",
        accessor: "route",
        Filter:SelectColumnFilter
      },
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Fecha",
        accessor: "date",
      },
      {
        Header: "Punto de Venta",
        accessor: "name",
      },
      {
        Header: "Devolución",
        accessor: "customer_returns",
      },
      {
        Header: "Bonificación",
        accessor: "customer_bonus",
      },
      {
        Header: "Frecuencia",
        accessor: "frequency",
      },
      {
        Header: "Día de Visita",
        accessor: "visit_days",
      },
      {
        Header: "Domicilio",
        accessor: "address",
      },   
      {
        Header: "Detalles",
        accessor:"detail"
      },
      {
        Header: "Total",
        accessor: "total"
      },
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  className=""
                  variant="h6"
                  component="h2"
                >
                  <img  alt="" src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className=""
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

        <div className="TableConsultarTodo2 TableBonus TableBonus2">
          
          <StickyTable>
            <SubTableContainer
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
            />
          </StickyTable>
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default ViewDetail_Bonus;
