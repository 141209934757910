//PAQUETERIAS
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

//COMPONENTES
import { update_price_list_products } from "../../../../services/inventory/inventory";
import { update_price_list } from "../../../../services/inventory/inventory";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";

//IMAGENES
//ESTILOS
import "./tools.css";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const CREATE_UPDATE_FILE = (props) => {
  const [modalT, setmodalT] = useState(false);
  const [price_list, setprice_list] = useState(props.price);
  const [price_list_file, setprice_list_file] = useState(props.price);
  const [duplicados, setduplicados] = useState(props.price);

  const dialog = useDialog();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      convertCsvToJson(file)
        .then((jsonData) => {
          console.log(jsonData);
          var nd = jsonData.map((d) => {
            return {
              code: d.Código?.replace(" ", "")?.replace("\xa0", ""),
              price: Number(d.Precio),
            };
          });
          console.log(nd);
          var ndd2 = nd.filter((d) => d.code !== "" && d.code !== "Código");
          console.log(nd);
          setprice_list_file(ndd2);
          setduplicados(validarDuplicados(ndd2));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  function convertCsvToJson(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const csvData = event.target.result;
        const lines = csvData.split("\n");
        const result = [];

        const headers = lines[0].replace("\r", "").replace('"', "").split(",");
        console.log(headers);

        for (let i = 0; i < lines.length; i++) {
          const obj = {};
          const currentLine = lines[i];
          console.log(currentLine);

          // Verificar si la línea contiene una combinación de comillas y comas
          if (currentLine.includes(',"')) {
            const startIndex = currentLine.indexOf(',"');
            const endIndex = currentLine.lastIndexOf('"');
            const value = currentLine.slice(startIndex + 2, endIndex);
            obj[headers[1]] = value;

            const otherValues =
              currentLine.slice(0, startIndex) +
              currentLine.slice(endIndex + 1);
            const otherElements = otherValues.split(",");
            console.log(otherElements);
            for (let j = 0; j < headers.length; j++) {
              if (j !== 1) {
                let value = otherElements[j]
                  ?.replace(/\r/g, "")
                  .replace("\r", "")
                  .replace('"', "")
                  .replace("\xa0", "");
                console.log(value);
                obj[headers[j]] =
                  value === undefined
                    ? otherElements[j - 1]
                        ?.replace(/\r/g, "")
                        .replace("\r", "")
                        .replace('"', "")
                        .replace("\xa0", "")
                    : value;
              }
            }
          } else {
            const currentLineElements = currentLine.split(",");
            console.log(currentLineElements);
            for (let j = 0; j < headers.length; j++) {
              let value = currentLineElements[j]
                ?.replace(/\r/g, "")
                .replace("\r", "")
                .replace('"', "");
              obj[headers[j]] =
                value === undefined
                  ? currentLineElements[j - 1]
                      ?.replace(/\r/g, "")
                      .replace("\r", "")
                      .replace('"', "")
                      .replace("\xa0", "")
                  : value;
            }
          }

          result.push(obj);
        }
        resolve(result);
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsText(file);
    });
  }

  function validarDuplicados(datos) {
    const codigos = datos.map((dato) => dato["code"]); // Obtiene un arreglo solo con los valores de "Código"

    const duplicados = codigos.filter(
      (codigo, index) => codigos.indexOf(codigo) !== index
    ); // Filtra los valores duplicados

    return duplicados;
  }

  const handle_create_products_line = async () => {
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            await update_price_list_products(price_list_file, props.id);
            setmodalT(false);
            dialog.close({
              confirm: true,
              msj: "Linea de productos creado correctamente",
            });
          } catch (err) {
            catchErrorModul("Linea de productos",JSON.stringify(price_list_file),JSON.stringify(err?.response));
            setmodalT(false);
            dialog.close({
              confirm: false,
              msj: JSON.stringify(err?.response?.data===undefined?"Error no contemplado comuníquese con soporte":err?.response?.data),
            });
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  return (
    <div>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>

      <form>
        <br></br>
        <div className="container-file">
          <input
            type="file"
            accept=".csv, .xlsx"
            onChange={(e) => handleFileChange(e)}
          />
        </div>
        <br></br>
        <div>
          {duplicados?.length > 0 ? (
            <p style={{ color: "red" }}>
              Los siguientes valores productos se repiten:{" "}
              {duplicados.join(", ")}
            </p>
          ) : (
            <p></p>
          )}
        </div>
        <Box>
          <ValidationTextField
            label="Escariba la palabra 'CONFIRMAR'"
            variant="outlined"
            style={{ display: price_list_file?.length > 0 ? "" : "none" }}
            placeholder="Confirmación"
            sx={{ m: 2, width: "55%" }}
            value={price_list}
            onChange={(e) => setprice_list(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box>
          <Button
            disabled={
              duplicados?.length === 0
                ? price_list === "CONFIRMAR"
                  ? false
                  : true
                : true
            }
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            onClick={handle_create_products_line}
            className="ButtonModal"
          >
            Enviar
          </Button>

          <Button
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            className="ButtonModal2"
            onClick={() => {
              dialog.close();
            }}
          >
            cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
};
export default CREATE_UPDATE_FILE;
