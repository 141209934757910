//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//import TableContainer from "./TableContainerReport";


//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import "./routes.css";
import TextField from "@mui/material/TextField";
import { Bar,XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Brush, ReferenceLine, Line, ComposedChart } from 'recharts';
import { Checkbox } from "@material-ui/core";
import DetailSales from "./DetaIlSales";
import { CustomDialog } from "react-st-modal";
import TableGoal from "./componentgoals/TableGoal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_goals, get_sales_by_route_all, get_sales_by_route_ind } from "../../services/reports/reports";
import { get_route_list } from "../../services/businesspartners/businesspartners";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";


const TableRouteSalesReport = () => { 

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [datagrap, setdatagrap]=useState([]);
  const [datatotales, setdatatotales]=useState([]);
  const [datameta, setdatameta]=useState([]);

  const [my, setmy]=useState(null);
  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);  

  const [nameroute, setnameroute]=useState("vacio");
  const [fi, setfi]=useState(null);
  const [ff, setff]=useState(null);
  const [routedata, setroutedata]=useState(null);
  const [totales, settotales]=useState(null);
  const [counttendencia, setcounttendencia]=useState(0);


  const [actualizacionmeta, setactualizacionmeta]=useState(false);
  const [banderafiltro, setbanderafiltro]=useState(true);
  const [nuevafecha, setnuevafecha]=useState(false);

useEffect(()=>{
    handleroute();
 },[]);


function alertas(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const handleSubmit = () => {
 
console.log(nameroute);
console.log(ff);

 if(fi===null || ff===null || fi==='' || ff===''){
  alertas("Falta asignar Rango de Fechas", false);
  return null
 }
 if(nameroute==="vacio"){
    setbanderafiltro(false);
    routeall();
  }else{
    setbanderafiltro(true);
    routeind();
  }
};
const routeind = async () => {

  setfiltro1(false);
  setfiltro2(false);
  setfiltro3(false);
  setfiltro4(false);
  setfiltro5(false);
  setfiltro6(false);
  setfiltro7(true);
  setfiltrometas(false);
  
  setmodalT(true);
  setopen(true);
  setData([]);
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      try{
        var d = await get_sales_by_route_ind(nameroute, fi, ff);
        handlefiltro1(d ===null ? [] : d.data.sales);
        handletotales(d === null ? [] : d.data.sales);
        settotales(d === null ? [] : d.data.total);
        setData(d === null ? [] : d.data.sales);  
        setopen(false);
      setmodalGeneral(false);
      setmodalT(false);  
      }catch(err){
        console.log(err);
        setData([]);
        setopen(false);
        setmodalGeneral(false);
        setmodalT(false);
      }
    }else{}   
    } catch (err) {
    console.log(err);
  }
}
const routeall= async ()=>{
  
  var ttt = await tendencia(fi);
  var tt2 = await countDaysWithoutSundays(fi, ff);
  
  const formattedDate = ff.replace(/-/g, '/');
  const date = new Date(formattedDate);
  var año = date.getFullYear();
  var mes = date.getMonth()+1;
  setmy(año+"-"+(mes<10?"0"+mes:mes));
 
  setfiltro1(false);
  setfiltro2(false);
  setfiltro3(false);
  setfiltro4(false);
  setfiltro5(false);
  setfiltro6(false);
  setfiltro7(true);

  if(actualizacionmeta===true){

  }else{
    setmodalT(true);  
    setfiltrometas(false);
  }    
  setopen(true);
  setData([]);  

  try {
    var d = await get_sales_by_route_all(fi, ff);
    const sortedRoutes = d === null ? [] :[...d.data.routes].sort((a, b) => parseInt(a.route) - parseInt(b.route));
    //consulta las metas
    var datam = await handlemetas(año, mes);
    console.log(datam);
    setData(d === null ? [] : sortedRoutes);
    analisismetas(d === null ? [] : datam, sortedRoutes, ttt, tt2);
    handletotales(d === null ? [] : sortedRoutes);

  } catch (error) {
    console.log(error);
    setData([]);
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);  
  } finally {
    setactualizacionmeta(false);
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);  
  }
}

const tendencia = (fi)=> {
  
  const formattedDate = fi.replace(/-/g, '/');
  const date = new Date(formattedDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  let count = 0;
  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = new Date(year, month, day);
    if (currentDate.getDay() !== 0) {
      count++;
    }
  }
  return count; 
  //setcounttendencia(count);
}
function countDaysWithoutSundays(startDateStr, endDateStr) {
  const fi = startDateStr.replace(/-/g, '/');
  const ff = endDateStr.replace(/-/g, '/');
  const startDate = new Date(fi);
  const endDate = new Date(ff);
  let numDays = 0;
  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    if (d.getDay() !== 0) { // 0 = Sunday
      numDays++;
    }
  }
  return numDays;
}

const handleroute = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setroutedata(d === null ? [] : d);  
        }catch(err){
          console.log(err);
          alertas("error al obtener data de rutas", false);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}
const handlemetas = async (year, month)=>{
  try {
    var d = await get_goals(year, month);
    return d===null?[]:d.data;          
  } catch (error) {
    console.log(error);
    return [];
  }
}


const [maxgrapmeta, setmaxgrapmeta]=useState(0.0);
const [maxgraptotal, setmaxgraptotal]=useState(0.0);
const [maxtendencia, setmaxtendencia]=useState(0.0);

const analisismetas=(datam, d, ttt, tt2)=>{
  const result = d.map(item => {
   const metaItem = datam.find(meta => meta.route === item.route);
    return metaItem ? { ...item, tendencia: (item.total/tt2)*(ttt), goal: metaItem.goal, porcentaje: (item.total / metaItem.goal)*100} : { ...item, tendencia: (item.total/tt2)*(ttt)};
  });
  console.log(result);
  setdatagrap(result);
  const filtro1 = result.filter(item => !isNaN(item.goal));
  setmaxgrapmeta(Math.max(...filtro1.map((item) => item.goal)));  
  const filtro2 = result.filter(item => !isNaN(item.total));
  setmaxgraptotal(Math.max(...filtro2.map((item) => item.total)));
  const filtro3 = result.filter(item=> !isNaN(item.tendencia));
  setmaxtendencia(Math.max(...filtro3.map((item)=>item.tendencia)));
}



const [tabla, settabla]=useState(false);
const [grafica, setgrafica]=useState(true);

const handletabla = ()=>{
  settabla(true);
  setgrafica(false);
}
const handlegrafica = () =>{
  settabla(false);
  setgrafica(true);
}

const crudmetas=()=>{

  const result = CustomDialog(               
    <div>
      <TableGoal my={my} setdatameta={setdatameta} setfiltrometas={setfiltrometas} setactualizacionmeta={setactualizacionmeta}></TableGoal>
    </div>,
  {
    className: "modalTItle",
    title:"Metas",
    showCloseIcon: true,
    isCanClose:false,
  })
}


const handlefiltrometas= async ()=>{

  if(actualizacionmeta===true){
    routeall(); 
    setmodalT(true);
    setfiltrometas(!filtrometas);
    setTimeout(() => {
      setmodalT(false);       
    }, 2000);

  }else if(nuevafecha===true){

    const partes = my.split("-"); // Divide la cadena en dos partes: año y mes
    var year = parseInt(partes[0]); // Convierte el año en un número entero
    var month = parseInt(partes[1]); // Convierte el mes en un número entero
    var datam = await handlemetas(year, month);
    var ttt = await tendencia(fi);
    var tt2 = await countDaysWithoutSundays(fi, ff);  
    analisismetas(datam, data, ttt, tt2); 
    setmodalT(true);
    setTimeout(() => {
      setnuevafecha(false);
      setfiltrometas(true);
      setmodalT(false);    
    }, 2000);
  }else {
    console.log("entra opcion else");
    setfiltrometas(!filtrometas);
  }
}

function handleBarClick(data){
 if(banderafiltro===true)
 {
   const result = CustomDialog(               
     <div>
       <DetailSales data={data} 
         filtro1={filtro1}
         filtro2={filtro2}
         filtro3={filtro3}
         filtro4={filtro4}
         filtro5={filtro5}
         filtro7={filtro7}
       ></DetailSales>
     </div>,
     {
       className: "modalpuntosventa",
       title:"Detalles de Venta",
       showCloseIcon: true,
       isCanClose:false,
     }
   )
 }
}

const [filtro1, setfiltro1]=useState(false);
const [filtro2, setfiltro2]=useState(false);
const [filtro3, setfiltro3]=useState(false);
const [filtro4, setfiltro4]=useState(false);
const [filtro5, setfiltro5]=useState(false);
const [filtro6, setfiltro6]=useState(false);
const [filtro7, setfiltro7]=useState(true);
const [filtrometas, setfiltrometas]=useState(false);
const [filtrotendencia, setfiltrotendencia]=useState(false);


const handletotales=(datanew)=>{
  
  //console.log(datanew);

  const result = datanew.reduce((acc, item) =>{
    acc.discount +=item.discount;
    acc.tax += item.tax;
    acc.subtotal += item.subtotal;
    acc.sale_total += item.sale_total;
    acc.cost += item.cost;
    acc.conversion += item.conversion;
    acc.delivery_commission += item.delivery_commission;
    acc.profit += item.profit;
    acc.bonus_total +=item.bonus_total;
    acc.returns_total += item.returns_total;
    acc.total +=item.total;
    return acc;
  }, {
    discount:0, 
    tax:0, 
    subtotal:0,
    sale_total:0,
    cost:0,
    conversion:0,
    delivery_commission:0,
    profit:0,
    returns_total:0,
    bonus_total:0,
    total:0
  });
  setdatatotales(result);
}
const handlefiltro1=(datanew)=>{
  var newArray = [];    
    const groupedByDate = datanew.reduce((acc, obj) => {
      const date = obj.date.split(" ")[0];
      if (!acc[date]) {
        acc[date] = {
          date: date,
          discount:0, 
          tax:0, 
          subtotal:0,
          sale_total:0,
          cost:0,
          conversion:0,
          delivery_commission:0,
          profit:0,
          bonus_total:0,
          returns_total:0,
          total:0,
          tax_returns:0,
          socios:[],
        }
      }
      acc[date].discount += obj.discount;
      acc[date].tax += obj.tax;
      acc[date].subtotal += obj.subtotal;
      acc[date].sale_total += obj.sale_total;
      acc[date].cost += obj.cost;
      acc[date].conversion += obj.conversion;
      acc[date].delivery_commission += obj.delivery_commission;
      acc[date].profit += obj.profit;
      acc[date].returns_total += obj.returns_total;
      acc[date].bonus_total += obj.bonus_total;
      acc[date].total+= obj.total;
      acc[date].socios.push({
        name_partner: obj.name_partner,
        code_partner: obj.code_partner,
        discount: obj.discount,
        tax: obj.tax,
        subtotal: obj.subtotal,
        sale_total: obj.sale_total,
        returns_total: obj.returns_total,
        total: obj.total,
      }) 
      return acc;
    }, {});
    
    for (let date in groupedByDate) {
    newArray.push({
      date: groupedByDate[date].date,
      discount: (groupedByDate[date].discount).toFixed(2),
      tax: (groupedByDate[date].tax).toFixed(2),
      subtotal: (groupedByDate[date].subtotal).toFixed(2),
      sale_total: (groupedByDate[date].sale_total).toFixed(2),
      cost: (groupedByDate[date].cost).toFixed(2),
      conversion: (groupedByDate[date].conversion).toFixed(2),
      delivery_commission: (groupedByDate[date].delivery_commission).toFixed(2),
      profit: (groupedByDate[date].profit).toFixed(2),
      bonus_total: (groupedByDate[date].bonus_total).toFixed(2),
      returns_total: (groupedByDate[date].returns_total).toFixed(2),
      total: (groupedByDate[date].total).toFixed(2),
      socios: (groupedByDate[date].socios)
    });
  }
  setdatagrap(newArray);
  console.log(newArray);
}

const columns = useMemo(
  () => [
    {
      Header: "Ticket",
      accessor:"ticket",
    },
    {
      Header:"Socio de Negocios",
      accessor:"name_partner"
    },
    {
      Header:"Punto de Venta",
      accessor: "name_pos"
    },
    {
      Header:"Descuentos",
      accessor: (d) => {
        return d.discount
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.discount)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.discount);
      },          
    },
    {
      Header:"Impuestos",
      accessor: (d) => {
        return d.tax
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.tax)
          : 
          Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.tax);
      },     
    },
    {
      Header:"Subtotal",
      accessor: (d) => {
        return d.subtotal
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.subtotal)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.subtotal);
      },   
    },
    {
      Header:"Total Venta",
      accessor: (d) => {
        return d.sale_total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.sale_total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.sale_total);
      },   
    },
    {
      Header:"Costo",
      accessor: (d) => {
        return d.cost
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.cost)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.cost);
      },   
    },
    {
      Header:"Conversión",
      accessor:"conversion"
    },
    {
      Header:"Comisión",
      accessor: (d) => {
        return d.delivery_commission
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.delivery_commission)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.delivery_commission);
      },   
    },
    {
      Header:"Total Bonificaciones",
      accessor:(d)=>{
      return d.bonus_total
        ? Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.bonus_total)
        : Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.bonus_total);
      },  
    },
    {
      Header:"Total Devoluciones",
      accessor:(d)=>{
      return d.returns_total
        ? Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.returns_total)
        : Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.returns_total);
      },  
    },
    {
      Header:"Total",
      accessor: (d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
      },   
    }
    ],
    []
);
const columns2 = useMemo(
  () => [
    {
      Header:"Ruta",
      accessor:"route"
    },
    {
      Header:"Total Venta",
      accessor:(d)=>{
      return d.sale_total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.sale_total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.sale_total);
      },  
    },
    
    {
      Header:"Total Bonificaciones",
      accessor:(d)=>{
      return d.bonus_total
        ? Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.bonus_total)
        : Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.bonus_total);
      },  
    },
    {
      Header:"Total Devoluciones",
      accessor:(d)=>{
      return d.returns_total
        ? Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.returns_total)
        : Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.returns_total);
      },  
    },
    {
      Header:"Total",
      accessor:(d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
      },          
    }
    ],
    []
);


return (
    <>
<div className="TableReportSaleRoute">
  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    <>
    <BREADCRUMBS niveles={
      [
        {label:"REPORTE VENTAS POR RUTA", path: null}, 
      ]
    }
    ></BREADCRUMBS>

    <div className="gridfiltros">
      <div className="fil1">
        <input
          type="text"
          className="fi"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha inicial"
        onChange={(event) => setfi(event.target.value)}
        ></input>
      </div>
      <div className="fil2">
        <input
          type="text"
          className="ff"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Final"
          onChange={(event) => setff(event.target.value)}
        ></input>
      </div>
      <div className="fil3">
      <TextField
          select
          required
          label="Ruta"
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setnameroute(event.target.value)}
      >
          <option key={0} value={"vacio"} selected>
              {"TODAS LAS RUTAS"}
          </option>
          {
            routedata?.data?.parametersList?.map((item)=>(
              <option key={item.idRoute} value={item.route}>
                {item.route}
              </option>
            ))
          }
      </TextField>
      </div>
      <div className="fil4">
        <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
            Buscar
        </button>
        <button className="button" id="buttonpackageb" onClick={handletabla} type="button">
            Tabla
        </button>
        <button className="button" id="buttonpackageb" onClick={handlegrafica} type="button">
            Gráfica
        </button>
        <button className="button" id="buttonpackageb" onClick={crudmetas} type="button">
            Metas
        </button>
      </div>
    </div>
      <div  className={banderafiltro===true?"tableg":"tableg2"} style={tabla===true?{"display":"block"}:{"display":"none"}}>            
        <StickyTable>
            <TableContainer
              paginado={"Ventas"}
              consultas={"Ventas Consultadas"}
              exportar={true}
              columns={banderafiltro===true?columns:columns2}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
              banderafiltro={banderafiltro}
              Grantotal={true}
              GrantotalCabeceras={["Socio de Negocios", "Punto de Venta"]}
            />
          </StickyTable>
      </div>
      <div className="graphict" style={grafica===true?{"display":"block"}:{"display":"none"}}>

      <div className="filtrosgraphic">
      <div className="fg1" style={banderafiltro===true?{"display":"block"}:{"display":"none"}}>
          <label className="labelcheck">Descuentos
          </label>
            <Checkbox
              checked={filtro1}
              onChange={(event)=>setfiltro1(!filtro1)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
            <p>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.discount>0? (datatotales?.discount)?.toFixed(2):0.0))
              }
            </p>
        </div>
        <div className="fg2" style={banderafiltro===true?{"display":"block"}:{"display":"none"}}>
          <label className="labelcheck">Impuestos 
          </label>
            <Checkbox
              checked={filtro2}
              onChange={(event)=>setfiltro2(!filtro2)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>
            {Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format((datatotales?.tax>0 ? (datatotales?.tax)?.toFixed(2):0.0))
            }
          </p>  
        </div>
        <div className="fg3" style={banderafiltro===true?{"display":"block"}:{"display":"none"}}>
          <label className="labelcheck">Subtotal  
          </label>
            <Checkbox
              checked={filtro3}
              onChange={(event)=>setfiltro3(!filtro3)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>
            {Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.subtotal>0? (datatotales?.subtotal)?.toFixed(2):0.0))
            }
          </p>
        </div>
        <div className={banderafiltro===true?"fg4":"fg1"}>
          <label className="labelcheck">Total Ventas  
          </label>
            <Checkbox
              checked={filtro4}
              onChange={(event)=>setfiltro4(!filtro4)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>
            {Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.sale_total>0? (datatotales?.sale_total)?.toFixed(2):0.0))
            }
          </p>
        </div>
        <div className="fg2" style={banderafiltro===false?{"display":"block"}:{"display":"none"}}>
          <label className="labelcheck">
            Total Bonificaciones  
          </label>
            <Checkbox
              checked={filtro6}
              onChange={(event)=>setfiltro6(!filtro6)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format(datatotales?.bonus_total>0 ? (datatotales?.bonus_total)?.toFixed(2):0.0)  
            }
          </p>
        </div>
        <div className={banderafiltro===true?"fg5":"fg3"}>
          <label className="labelcheck">Total Devoluciones
          </label>
            <Checkbox
              checked={filtro5}
              onChange={(event)=>setfiltro5(!filtro5)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.returns_total>0 ? (datatotales?.returns_total)?.toFixed(2):0.0))  
            }
          </p>
        </div>
        
        <div className={banderafiltro===true?"fg6":"fg4"}>
          <label className="labelcheck">Gran Total 
          </label>
            <Checkbox
              checked={filtro7}
              onChange={()=>setfiltro7(!filtro7)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>
            {
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format((datatotales?.total>0 ? (datatotales?.total)?.toFixed(2) : 0.0))
            }
          </p>
        </div>
        {
        banderafiltro===false?
        <div className="fg5">
          <label className="labelcheck">Metas 
          </label>
            <Checkbox
              checked={filtrometas}
              onChange={handlefiltrometas}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
            <div className="check-input-meta">
            < input 
              type="month" 
              value={my} 
              onChange={(e)=>{
                setmy(e.target.value);
                setfiltrometas(false);
                setnuevafecha(true);
              }} 
            />  
          </div>    
        </div>:<></>
        }
        {
          banderafiltro===false?
          <div className="fg6">
          <label className="labelcheck">Tendencia 
          </label>
            <Checkbox
              checked={filtrotendencia}
              onChange={()=>setfiltrotendencia(!filtrotendencia)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
        </div>:<></>
        }
        
        <div className="fg8">
        </div>
      </div>

      <ResponsiveContainer width="95%" maxHeight={450} minHeight={500}>
        <ComposedChart
        data={datagrap}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 10
        }}
      > 
        <CartesianGrid strokeDasharray="3 3" />
        {
          banderafiltro===true?
          <>
            <XAxis dataKey="date"> 
              <Label value="date" offset={-10} position="insideBottom"/>
            </XAxis>
            <YAxis dataKey="total" label={{value:"Total $", angle:90}} 
              tickFormatter={(value) => `$${value.toLocaleString()}`}
              domain={[0, (Math.max(...datagrap?.map(item=>(item.total))))]}     
            />
            <Brush dataKey="date" height={30} stroke="#8884d8" />  
            <Tooltip
             formatter={(value) => `$${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}`}
            />
          </>
          :
          <>
          <XAxis dataKey="route"> 
            <Label value="route" offset={-10} position="insideBottom"/>
          </XAxis>
            <YAxis dataKey="total" label={{value:"Total $", angle:90}} 
             tickFormatter={(value) => `$${value.toLocaleString()}`}
             domain={
              filtrometas===true && filtrotendencia===true?(maxgrapmeta>maxtendencia)?[0,maxgrapmeta]:[0,maxtendencia]:
              filtrometas===true?[0,maxgrapmeta]:filtrotendencia===true?[0,maxtendencia]:[0,maxgraptotal]
            }
            />
            <Brush dataKey="route" height={30} stroke="#8884d8" />  
            <Tooltip
             // formatter={(value) => `$${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}`}
             formatter={(value, name, props) => {
              if (name === 'Meta' && props.payload.porcentaje !== undefined) {
                const formattedValue = value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 }); // formatear el valor a formato monetario
                const porcentaje = props.payload.porcentaje.toFixed(2); // obtener el valor de porcentaje y formatearlo a 2 decimales
                return `$${formattedValue} - Porcentaje Avance: ${porcentaje}%`; // retornar el texto personalizado
              }
              return  `$${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}` // utilizar el formatter por defecto para las demás barras
            }}
            />
          </>
          
        }
       
        <Legend verticalAlign="top" height={50}/>
        <ReferenceLine y={0} stroke="#000" />         
        {
          filtro1===true?<Bar dataKey="discount" stackId="a" name="Descuentos" fill="#00CFC3" onClick={handleBarClick}/>:""
        }
        {
          filtro2===true?<Bar dataKey="tax" stackId="b" name="Impuestos" fill="red" onClick={handleBarClick}/>:""
        }
        {
          filtro3===true?<Bar dataKey="subtotal" stackId="e" name="Subtotal" fill="#7121AA" onClick={handleBarClick}/>:""
        } 
        {
          filtro4===true?<Bar dataKey="sale_total" stackId="g" name="Total Ventas" fill="#9C4701" onClick={handleBarClick}/>:"" 
        }
        {
          filtro5===true?<Bar dataKey="returns_total" stackId="h" name="Total Devoluciones" fill="#00CFC3" onClick={handleBarClick}/>:"" 
        }
        {
          filtro6===true?<Bar dataKey="bonus_total" stackId="d" name="Total Bonificaciones" fill="#20CC10" onClick={handleBarClick}/>:""
        }
        {
          filtro7===true?<Bar dataKey="total" stackId="f" name="Total" fill="#3f87a6" onClick={handleBarClick}/>:"" 
        }
        {
          filtrometas===true? <Line type="monotone" name="Meta" dataKey="goal" stroke="red" strokeWidth={3}/>:<></>
        } 
        {
          filtrotendencia===true?<Line type="monotone" name="Tendencia" dataKey="tendencia" stroke="purple" strokeWidth={3}/>:<></>
        }
      </ComposedChart>
      </ResponsiveContainer>
      </div>
      </>           
    </div>
   </div>
    </>
  );
}

export default TableRouteSalesReport;
