//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "./SubTableContainer";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//ESTILOS
import "../../../components_cfdi/estilo.css";
import "../../../components_cfdi/modal.css";
import "../../../components_cfdi/table.css";

//IMAGENES
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { delete_taxes_sub_config, get_taxes_sub_config } from "../../../services/invoices/invoice";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import DELETE_CONF from "./tools/delete_conf";
import { update_null_identifiers } from "../../../main_components/methods_v2/auxiliar_funtion";

function SubTable_v2 (props) {

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

  const handleSubmit = async(event) => {    
    try{
      setIsLoading(true);
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_taxes_sub_config(props?.id);
            const data = d===null?[]:d.data;
            var ndt = update_null_identifiers(data, update_identifiers);
            setIsLoading(false);
            setData(ndt);
          }catch(err){
            setIsLoading(false);
            setData([]);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };
  

  useEffect(() => {
    handleSubmit();
  }, []);

  useEffect(()=>{
    if(props.rec===true){
      handleSubmit();
      props.setrec(false);
    }
  }, [props?.rec])


  const columns = useMemo(
    () => [
      {
        id :"default",
        header: "Default",
        accessorKey: "default",
        accessorFn: (d) => {
          return d.default==true?"Si":"No"
        },
      },
      {
        id :"rate",
        header: "Tarifa",
        accessorKey: "rate",
      },
      {
        id: "type",
        header: "Tipo",
        accessorKey: "type",
      },
      {
        id: "c_tipo_factor",
        header: "Tipo factor",
        accessorKey: "c_tipo_factor",
      },

    ],
    []
  );

  const update_identifiers =
    [
      { default: 'string' },
      { rate: 'string' },
      { type: 'String' },
      { c_tipo_factor: 'string' },
    ];



  return (

    <div>
      <div>
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={false}
      ></MODAL_TABLE>
       </div>

        <div className="new_table_v2">
        <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
             <DELETE_CONF
               closeMenu={closeMenu}
               setmodalT={props?.setmodalT}
               row={row}
               id = {props?.id}
               handleSubmit={handleSubmit}
               alertas={props?.alertas}
            />
           ]}
         renderToolbarInternalActions={({ table }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Consultar configuración del impuesto"}
             update_identifiers={update_identifiers}
           />
          )
          }
      />
                
        </div>
    </div>

  );
};

export default SubTable_v2;
