import {useMemo} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { StickyTable} from "react-sticky-table";
import TableContainer from "../../main_components/Table/TableContainer";

function DetailSales(props) {

  console.log(props.data);
  const dialog = useDialog();
  //const sortedRoutes = [...props.data.socios].sort((a, b) => parseInt(a.code_partner) - parseInt(b.code_partner));
  const groupedRoutes = Object.values([...props.data.socios].reduce((acc, socio) => {
    const key = socio.code_partner + socio.name_partner;
    if (!acc[key]) {
      acc[key] = {
        ...socio,
        discount: socio.discount,
        tax: socio.tax,
        subtotal: socio.subtotal,
        sale_total: socio.sale_total,
        returns_total: socio.returns_total,
        total: socio.total
      };
    } else {
      acc[key].discount += socio.discount;
      acc[key].tax += socio.tax;
      acc[key].subtotal += socio.subtotal;
      acc[key].sale_total += socio.sale_total;
      acc[key].returns_total += socio.returns_total;
      acc[key].total += socio.total;
    }
    return acc;
  }, {})).sort((a, b) => parseInt(a.code_partner) - parseInt(b.code_partner));



const columns = useMemo(
  () => [   
    {
      Header:"Código",
      accessor:"code_partner"
    },
    {
      Header:"Socio de Negocios",
      accessor: "name_partner"
    },
    {
      Header:"Descuentos",
      accessor:(d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.discount)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.discount);
      }, 
      show: props.filtro1, 
      id:"discount"        
    },
    {
      Header:"Impuestos",
      accessor:(d) => {
        return d.tax
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.tax)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.tax);
      }, 
      show: props.filtro2, 
      id:"tax"        
    },
    {
      Header:"Subtotal",
      accessor:(d) => {
        return d.subtotal
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.subtotal)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.subtotal);
      }, 
      show: props.filtro3, 
      id:"subtotal"        
    },
    {
      Header:"Total Ventas",
      accessor:(d) => {
        return d.sale_total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.sale_total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.sale_total);
      }, 
      show: props.filtro4, 
      id:"sale_total"        
    },
    {
      Header:"Devoluciones",
      accessor:(d) => {
        return d.returns_total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.returns_total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.returns_total);
      }, 
      show: props.filtro5, 
      id:"returns_total"        
    },
    {
      Header:"Total",
      accessor:(d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
      }, 
      show: props.filtro7,
      id:"total"         
    }
    ],
    [
      props.filtro1,
      props.filtro2,
      props.filtro3,
      props.filtro4,
      props.filtro5,
      props.filtro7,  
    ]
);


return (
  <div>
  <div className="addrulesSE">
                
    <h1 className="titledetailsgrafic">Detalles {props.data.date}</h1>

  <div className="detalles_grafic">
      <StickyTable>
        <TableContainer
          paginado={"Socios de Negocios"}
          consultas={"Socios de Negocios Consultadas"}
          exportar={true}
          columns={columns}
          data={groupedRoutes}
          style={{ overflowx: 10 }}
          banderafiltro={null}
          filtro1={props.filtro1}
          filtro2={props.filtro2}
          filtro3={props.filtro3}
          filtro4={props.filtro4}
          filtro5={props.filtro5}
          filtro7={props.filtro7}
          
        />
      </StickyTable> 
</div>
  <Box>    
    <Button
      style={{"background-color":"red", "color":"white", "height":"40px"}}
      variant="contained"
      sx={{ m: 1.5, width: "20ch" }}
        onClick={() => {
          dialog.close();
        } 
      }
    >
      cerrar
    </Button>
  </Box>
</div>
</div>
  );
}
export default DetailSales;
