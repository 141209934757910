import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import Set_configure from "../Set_Configure_Tax";
import add from "../../../../imgComponents/add.png";
import conf from "../../../../imgComponents/lupa.png";
import delet from "../../../../imgComponents/borrar.png";
import { delete_taxes } from "../../../../services/invoices/invoice";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{

        props?.setmodalT(true);
        try{
            var rf_token = await refresh_token();
            if(rf_token===true){
            setTimeout(async() => {
                try{
                const d = await delete_taxes(props?.row.original.id);
                console.log(d);
                props?.alertas("Impuesto eliminado correctamente", true);
                props?.setmodalT(false);
                props.handleSubmit();
            }catch(err){
                console.log("error", err);
                props?.setmodalT(false);
                props?.alertas("Error.,"+ err.response.data.errorMessage, false); 
                }
            }, 1000);
            }else{}
        }catch(err){
            console.log(err);
        }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ ()=>{
                    window.location="/consult_configure_taxes_v2"
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={conf}
                        title="Consultar Configuraciones"
                        className=""
                    ></img>
                </ListItemIcon>
                Consultar Configuraciones
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async() =>{
                    props.closeMenu();
                    await CustomDialog(
                        <Set_configure 
                            id_tax={props?.row.original.id} 
                            setmodalT={props?.setmodalT}
                            alertas={props?.alertas}
                            setrec={props?.setrec}
                        ></Set_configure>,
                      {
                        className: "modalTItle",
                        title: "Seleccione la configuracion del impuesto",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={add}
                        title="Asignar Configuración"
                        className=""
                    ></img>
                </ListItemIcon>
                Asignar Configuración
            </MenuItem>
            <MenuItem
                key={3}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el Impuesto?','Eliminar Impuesto','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={delet}
                        title="Eliminar Impuesto"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Impuesto
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
