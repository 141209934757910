import { useEffect, useState } from "react";
import { get_cabinets_details } from "../../../services/assets/assets";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";

export const DetailsFrezz = (props) => {

const [comentary, setcomentary]=useState(null);

useEffect(() => {
    handledetails();
}, [])

function comentarios(com){
    const newText=com.replace(/\|\|/g,"\n");
    const newText2=newText.replace(/\|/g,"\n");
    const newText3 = newText2.replace("Importación manual.", "Importación manual.\n");
    const parrafos = newText3.split("\n\n");
    parrafos.reverse();
    const invertedtext = parrafos.join("\n\n");
    setcomentary(invertedtext?.trim()) 
 }

const handledetails = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        var d = await get_cabinets_details(props.row.original.id);
        comentarios(d===null?[]:d.data.cabinet.system_commentary)              
      }, 1000);
    }else{} 
  } catch (error) {
    console.error(error);
  }
}

return (
    <>
        <div className="detailsfrez">
            <div className="grid-details-freez">
                <p className="det-frez-1 t">Número económico:</p>
                <p className="det-frez-1a d">{props.row.original.economic_number}</p> 
                <p className="det-frez-2 t">Número de activo:</p>
                <p className="det-frez-2a d">{props.row.original.fixed_asset}</p> 
                <p className="det-frez-3 t">Número de serie:</p>
                <p className="det-frez-3a d">{props.row.original.serial_number}</p>
                <p className="det-frez-4 t">Modelo:</p>
                <p className="det-frez-4a d">{props.row.original.model}</p>
                <p className="det-frez-5 t">Marca:</p>
                <p className="det-frez-5a d">{props.row.original.brand}</p>
            </div>
        </div>
        <h1 className="bitac-frez">Bitácora</h1>
        <textarea className="textareapuntosventa" value={comentary}>
        </textarea>
    </>
)

}
