//PAQUETERIAS
import React, {useEffect,useState} from "react";
import {  styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

//COMPONENTES
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { create_products_line } from "../../../services/inventory/inventory";
import { update_products_line } from "../../../services/inventory/inventory";
import { get_businesspartners_supplier } from "../../../services/businesspartners/businesspartners";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";

//IMAGENES
//ESTILOS

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const enable_data = [
  { value: true, label: "SI" },
  { value: false, label: "NO" },
];

const CREATE_UPDATE = (props) => {
  const [modalT, setmodalT] = useState(false);
  const [supplier, setsupplier] = useState([]);
  const [enable, setenable] = useState(props.enable);
  const [product_line, setproduct_line] = useState(props.name_line);
  const [code, setcode] = useState(props.code);
  const [desription, setdesription] = useState(props.description);
  const [supplier_data, setsupplier_data] = useState(props.id_supp);

  const dialog = useDialog();

  const hanlde_enable_data = (event) => {
    setenable(event.target.value);
  };
  const handleOnSelect = (item) => {
    setsupplier_data(item.id);
  };

  var data = {
    name_line: product_line,
    code: code,
    description: desription,
    enable: props.method === "CREATE" ? null : enable,
    id_business_partner: supplier_data,
  };
  console.log(data);

  const handle_create_products_line = async () => {
    setmodalT(true);
    var data = {
      name_line: product_line,
      code: code,
      description: desription,
      enable: props.method === "CREATE" ? null : enable,
      id_business_partner: supplier_data,
    };
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            await create_products_line(data);
            setmodalT(false);
            dialog.close({confirm:true,msj:"Linea de productos creado correctamente"});
          } catch (err) {
            catchErrorModul("Linea de productos",JSON.stringify(data),JSON.stringify(err?.response));
            setmodalT(false);
            dialog.close(JSON.stringify(err.response.data));
          }
        }, 1000);
      }else{}
    }catch(err){ 
      console.log(err);
    }    
  };

  const handle_update_products_line = async () => {
    setmodalT(true);
    var data = {
      name_line: product_line,
      code: code,
      description: desription,
      id_business_partner: supplier_data,
      enable: enable,
    };

    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            await update_products_line(data, props.id);
            setmodalT(false);
            dialog.close({confirm:true,msj:"Linea de productos creado correctamente"});
          } catch (err) {
            catchErrorModul("Linea de productos",JSON.stringify(data),JSON.stringify(err?.response));
            setmodalT(false);
            dialog.close({confirm:true,msj:"Linea de productos actualizada correctamente"});
            dialog.close(JSON.stringify(err.response.data));
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handle_get_set_businesspartners_supplier = async () => {
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt = await get_businesspartners_supplier();
            setsupplier(dt.data.business_partner);
          } catch (err) {}
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    handle_get_set_businesspartners_supplier();
  }, []);
  return (
    <div>
            <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>

      <form>
        <div className="containerSupp">
          <div className="fact-6 color-1">
            <div className="fact6-reactSearchautocomplete">
              <ReactSearchAutocomplete
                items={supplier}
                fuseOptions={{ keys: ["rfc", "business_partner"] }}
                resultStringKeyName="business_partner"
                onSelect={handleOnSelect}
                showIcon={true}
                placeholder={
                  props.name_supplier !== null
                    ? props.name_supplier
                    : "Buscar Proveedor..."
                }
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 10,
                }}
              />
            </div>
          </div>
        </div>

        <Box>
          <ValidationTextField
            label="Linea de Producto"
            variant="outlined"
            placeholder="Linea de Producto"
            sx={{ m: 2, width: "80%" }}
            value={product_line}
            onChange={(e) => setproduct_line(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box>
          <ValidationTextField
            label="Código"
            variant="outlined"
            placeholder="Código"
            sx={{ m: 2, width: "80%" }}
            value={code}
            onChange={(e) => setcode(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box>
          <ValidationTextField
            label="Descripción"
            variant="outlined"
            placeholder="Descripción"
            sx={{ m: 2, width: "80%" }}
            value={desription}
            onChange={(e) => setdesription(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box>
          <TextField
            style={{ display: props.method === "UPDATE" ? "" : "none" }}
            select
            label="Enable"
            value={enable}
            sx={{ m: 1.5, width: "80%" }}
            onChange={hanlde_enable_data}
            size="small"
            name="Enable"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            {enable_data.map((option) => (
              <option value={option.value} selected={option}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>

        <br></br>

        <Box>
          <Button
            variant="contained"
            style={{ display: props.method === "CREATE" ? "" : "none" }}
            sx={{ m: 1.5, width: "20ch" }}
            onClick={handle_create_products_line}
            className="ButtonModal"
          >
            Crear
          </Button>
          <Button
            style={{ display: props.method === "UPDATE" ? "" : "none" }}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            onClick={handle_update_products_line}
            className="ButtonModal"
          >
            Actualizar
          </Button>

          <Button
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            className="ButtonModal2"
            onClick={() => {
              dialog.close();
            }}
          >
            cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
};
export default CREATE_UPDATE;
