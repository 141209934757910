import { useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { post_models, put_models } from "../../../services/assets/assets";


function OPERATIONS_MODEL(props) {

const dialog = useDialog();
const[nameModel, setnameModel] = useState(props.operation==="EDIT"?props.row.original.name:null);
const[vnameModel, setvnameModel]=useState(false);
const [bandera, setbandera]=useState(true);
  
//ACTUALIZAR UN MODELO
const updatemodel = async () => {

if(nameModel===null || nameModel===""){
  props.alertas("Parámetros faltantes", false);
  nameModel===null || nameModel===""?setvnameModel(true):setvnameModel(false);
}else{

props.reconsultar(true);
setbandera(false);
  var data = {
    "name":  nameModel,//String = Nombre de la marca
  }
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const dt = await put_models(props.id, props.row.original.id, data);
            console.log(dt);
            props.alertas("Modelo actualizado con éxito", true);
            props.reconsultar(false);
            dialog.close();
            props.handlemodelos();
          }catch(err){
            console.log(err);
            catchErrorModul("Modelos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));  
            setbandera(true);
            props.reconsultar(false);
            props.alertas("No se pudo actualizar el Modelo, vuelva a intertarlo", false);
          }
          
        }, 1000);
      }else{}
    } catch (err) {  
        console.log(err);
    };
  }
}

const createmodel = async () =>{

  if(nameModel===null || nameModel===""){
    props.alertas("Parámetros faltantes", false);
    nameModel===null || nameModel===""?setvnameModel(true):setvnameModel(false);
  }else{
  
  props.reconsultar(true);
  setbandera(false);
    var data = {
      "name":  nameModel,//String = Nombre de la marca
    }
  
      try {
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const dt = await post_models(props.id, data);
              console.log(dt);
              props.alertas("Modelo creado con éxito", true);
              props.reconsultar(false);
              dialog.close();
              props.handlemodelos();  
            }catch(err){
              console.log(err);
              catchErrorModul("Modelos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
              setbandera(true);
              props.reconsultar(false);
              props.alertas("No se pudo crear el Modelo, vuelva a intertarlo", false);
            } 
          }, 1000);   
        }else{}
       } catch (err) {  
       console.log(err);
      };
    }
  } 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <div className="addpointsalecss">
          <div className="gridmarca1">
            <div className="marca1">
            <TextField
              label="Nombre del Modelo"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={nameModel}
              style={
                vnameModel===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvnameModel(false)}
              onBlur={
                nameModel==="" || nameModel===null?()=>setvnameModel(true):()=>setvnameModel(false)
              }
              onChange={(event) => setnameModel(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
            </div>
          </div>
             
            
          
          <br></br>
          <br></br>
          <Box>
            {
              bandera===true?
              
            <Button
            variant="contained"
            style={{"backgroundColor":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={()=>{props.operation==="EDIT"?updatemodel():createmodel()}}
          >
            {props.operation==="EDIT"?"GUARDAR CAMBIOS":"CREAR MODELO"}
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"backgroundColor":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default OPERATIONS_MODEL;
